import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface BulkUploadStagingDetailsSchemaContract {
  bulk_uploads: {
    id: number;
    created_at: string;
    updated_at: string;
    file_name: null | string;
    error_file_name: null | string;
    type: number;
    status: number | null;
    by_people_id: number | null;
    organization_id: number | null;
    number_of_rows_with_error: null | number;
    total_number_of_rows: null | number;
    file_summary: null | {
      [k: string]: unknown;
    };
    input_config: null | {
      payrollMonth?: string | null;
      effectiveMonth?: string | null;
      [k: string]: unknown;
    };
    validation_started_at: null | string;
    validation_finished_at: null | string;
    execution_started_at: null | string;
    execution_finished_at: null | string;
    errors: string[] | null;
    uploaded_file_url: null | string;
    error_file_url: null | string;
    [k: string]: unknown;
  };
  bulk_uploads_staging: {
    current_page: number;
    data: {
      id?: number;
      created_at?: string;
      updated_at?: string;
      organization_id?: number;
      people_id?: number;
      bulk_uploads_id?: number;
      status?: number;
      file_row?: {
        oldCtc?: string | null;
        newCtc?: string | null;
        arrearMonth?: string | null;
        arrearApplicable?: boolean | null;
        arrearAmount?: number | null;
        arrearsBreakdown?:
          | {
              name?: string | null;
              amount?: number | null;
              [k: string]: unknown;
            }[]
          | null;
        amount?: string | null;
        type?: string;
        label?: string | null;
        lopDays?: string | null;
        commonProperties?: {
          employeeName?: string | null;
          email?: string | null;
          employeeId?: string | null;
          error?: string | null | unknown[];
          rowNumber?: number;
          peopleId?: number;
          [k: string]: unknown;
        };
        [k: string]: unknown;
      };
      bulk_uploads_type?: number;
      [k: string]: unknown;
    }[];
    first_page_url: string | null;
    from: number | null;
    last_page: number | null;
    last_page_url: string | null;
    links: {
      url?: string | null;
      label?: string | null;
      active?: boolean;
      [k: string]: unknown;
    }[];
    next_page_url: string | null;
    path: string;
    per_page: number | null;
    prev_page_url: string | null;
    to: number | null;
    total: number;
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    bulk_uploads: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        created_at: { type: ['string'], format: 'date-time' },
        updated_at: { type: ['string'], format: 'date-time' },
        file_name: { type: ['null', 'string'] },
        error_file_name: { type: ['null', 'string'] },
        type: { type: 'number' },
        status: { type: ['number', 'null'] },
        by_people_id: { type: ['number', 'null'] },
        organization_id: { type: ['number', 'null'] },
        number_of_rows_with_error: { type: ['null', 'number'] },
        total_number_of_rows: { type: ['null', 'number'] },
        file_summary: { type: ['null', 'object'] },
        input_config: {
          type: ['null', 'object'],
          properties: {
            payrollMonth: { type: ['string', 'null'], format: 'date' },
            effectiveMonth: { type: ['string', 'null'], format: 'date' },
          },
        },
        validation_started_at: { type: ['null', 'string'], format: 'date-time' },
        validation_finished_at: { type: ['null', 'string'], format: 'date-time' },
        execution_started_at: { type: ['null', 'string'], format: 'date-time' },
        execution_finished_at: { type: ['null', 'string'], format: 'date-time' },
        errors: { type: ['array', 'null'], items: { type: 'string' } },
        uploaded_file_url: { type: ['null', 'string'] },
        error_file_url: { type: ['null', 'string'] },
      },
      required: [
        'id',
        'created_at',
        'updated_at',
        'file_name',
        'error_file_name',
        'type',
        'status',
        'by_people_id',
        'organization_id',
        'number_of_rows_with_error',
        'total_number_of_rows',
        'file_summary',
        'input_config',
        'validation_started_at',
        'validation_finished_at',
        'execution_started_at',
        'execution_finished_at',
        'errors',
        'uploaded_file_url',
        'error_file_url',
      ],
    },
    bulk_uploads_staging: {
      type: 'object',
      properties: {
        current_page: { type: 'number' },
        data: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: { type: 'number' },
              created_at: { type: 'string', format: 'date-time' },
              updated_at: { type: 'string', format: 'date-time' },
              organization_id: { type: 'number' },
              people_id: { type: 'number' },
              bulk_uploads_id: { type: 'number' },
              status: { type: 'number' },
              file_row: {
                type: 'object',
                properties: {
                  oldCtc: { type: ['string', 'null'] },
                  newCtc: { type: ['string', 'null'] },
                  arrearMonth: { type: ['string', 'null'] },
                  arrearApplicable: { type: ['boolean', 'null'] },
                  arrearAmount: { type: ['number', 'null'] },
                  arrearsBreakdown: {
                    type: ['array', 'null'],
                    items: {
                      type: 'object',
                      properties: {
                        name: { type: ['string', 'null'] },
                        amount: { type: ['number', 'null'] },
                      },
                    },
                  },
                  amount: { type: ['string', 'null'] },
                  type: { type: 'string' },
                  label: { type: ['string', 'null'] },
                  lopDays: { type: ['string', 'null'] },
                  commonProperties: {
                    type: 'object',
                    properties: {
                      employeeName: { type: ['string', 'null'] },
                      email: { type: ['string', 'null'] },
                      employeeId: { type: ['string', 'null'] },
                      error: { type: ['string', 'null', 'array'] },
                      rowNumber: { type: 'number' },
                      peopleId: { type: 'number' },
                    },
                  },
                },
              },
              bulk_uploads_type: { type: 'number' },
            },
          },
        },
        first_page_url: { type: ['string', 'null'] },
        from: { type: ['number', 'null'] },
        last_page: { type: ['number', 'null'] },
        last_page_url: { type: ['string', 'null'] },
        links: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              url: { type: ['string', 'null'] },
              label: { type: ['string', 'null'] },
              active: { type: 'boolean' },
            },
          },
        },
        next_page_url: { type: ['string', 'null'] },
        path: { type: 'string' },
        per_page: { type: ['number', 'null'] },
        prev_page_url: { type: ['string', 'null'] },
        to: { type: ['number', 'null'] },
        total: { type: 'number' },
      },
      required: [
        'current_page',
        'data',
        'first_page_url',
        'from',
        'last_page',
        'last_page_url',
        'links',
        'next_page_url',
        'path',
        'per_page',
        'prev_page_url',
        'to',
        'total',
      ],
    },
  },
  required: ['bulk_uploads', 'bulk_uploads_staging'],
};
