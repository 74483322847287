import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import { setGenericAppError } from '../../reducers/genericError';
import SidebarApiSchema, { SidebarApiSchemaContract } from '../../schemas/SidebarApiSchema';
import {
  sidebarFetchFailed,
  sidebarFetchSucceeded,
  ActionTypes,
  IFetchSidebar,
  sidebarFetching,
} from '../../reducers/sidebar';

function* fetchSidebarSaga(): Generator<StrictEffect, void, SidebarApiSchemaContract> {
  try {
    yield put(sidebarFetching());
    const sidebar = yield AppSaga.getApi({ url: `/sidebar`, responseSchema: SidebarApiSchema });
    if (Object.keys(sidebar).length === 0) {
      // The sidebar response will be empty for unauthorised users
      return;
    }
    yield put(sidebarFetchSucceeded(sidebar));
  } catch (e) {
    yield put(sidebarFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_SIDEBAR, fetchSidebarSaga);
}
