import { all, fork } from 'redux-saga/effects';
import loggedInUserSaga from './loggedInUserSaga';
import InsuranceSaga from './InsuranceSaga';
import InsuranceEstimationSaga from './InsuranceEstimationSaga';
import InsuranceEmployeesSaga from './InsuranceEmployeesSaga';
import InsuranceEmployeeSaga from './InsuranceEmployeeSaga';
import InsuranceEndorsementSaga from './InsuranceEndorsement';
import sidebarSaga from './sidebarSaga';
import notificationSaga from './notificationsSaga';

import routerSaga from './routerSaga';
import notificationsSaga from './notificationsSaga';

export default function* () {
  yield all(
    [
      loggedInUserSaga,
      InsuranceSaga,
      routerSaga,
      InsuranceEstimationSaga,
      InsuranceEmployeesSaga,
      InsuranceEmployeeSaga,
      InsuranceEndorsementSaga,
      sidebarSaga,
      notificationsSaga,
    ].map((saga) => fork(saga)),
  );
}
