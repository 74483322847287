import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetWorkflowRequestsContract {
  current_page: number;
  data: {
    id: number;
    people_id: number | null;
    organization_id: number;
    type: number;
    old_data: unknown;
    new_data: unknown;
    created_at: number;
    status: number;
    updated_at: number;
    updated_by: number | null;
    deleted_at: number | null;
    pending_on_level: number | null;
    source: unknown;
    for_people_id: number | null;
    payload?: unknown;
    [k: string]: unknown;
  }[];
  from: number | null;
  last_page: number;
  path: string;
  per_page: number;
  to: number | null;
  total: number;
  people_metadata: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      people_id: number | null;
      employee_type: string;
      employee_id: string | null;
      name: string;
      id_prefix: string | null;
      [k: string]: unknown;
    };
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    current_page: { type: 'number' },
    data: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'number' },
          people_id: { type: ['number', 'null'] },
          organization_id: { type: 'number' },
          type: { type: 'number' },
          old_data: {},
          new_data: {},
          created_at: { type: 'number' },
          status: { type: 'number' },
          updated_at: { type: 'number' },
          updated_by: { type: ['number', 'null'] },
          deleted_at: { type: ['number', 'null'] },
          pending_on_level: { type: ['number', 'null'] },
          source: {},
          for_people_id: { type: ['number', 'null'] },
          payload: {},
        },
        required: [
          'id',
          'people_id',
          'organization_id',
          'type',
          'old_data',
          'new_data',
          'created_at',
          'status',
          'updated_at',
          'updated_by',
          'deleted_at',
          'pending_on_level',
          'source',
          'for_people_id',
        ],
      },
    },
    from: { type: ['number', 'null'] },
    last_page: { type: 'number' },
    path: { type: 'string' },
    per_page: { type: 'number' },
    to: { type: ['number', 'null'] },
    total: { type: 'number' },
    people_metadata: {
      type: 'object',
      patternProperties: {
        '.*': {
          type: 'object',
          properties: {
            people_id: { type: ['number', 'null'] },
            employee_type: { type: 'string' },
            employee_id: { type: ['string', 'null'] },
            name: { type: 'string' },
            id_prefix: { type: ['string', 'null'] },
          },
          required: ['people_id', 'employee_type', 'employee_id', 'name', 'id_prefix'],
        },
      },
    },
  },
  required: [
    'current_page',
    'data',
    'from',
    'last_page',
    'path',
    'per_page',
    'to',
    'total',
    'people_metadata',
  ],
};
