import { getApi, postApi } from 'utils/Api';
import RxMerchants, { RxMerchantsContract } from 'schemas/RxMerchants';
import BankingAccountsSchema, {
  BankingAccountsSchemaContract,
} from 'schemas/BankingAccountsSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import RxIntegrationSchema, { RxIntegrationSchemaContract } from 'schemas/RxIntegrationSchema';
import RxIntegrationLoginSchema, {
  RxIntegrationLoginSchemaContract,
} from 'schemas/RxIntegrationLoginSchema';

type ActivateIntegrationContact = {
  banking_account_id: string;
};

type InitiateLoginContract = {};

export default {
  fetchDetails: () => {
    return getApi<RxIntegrationSchemaContract>({
      url: '/rx-integration',
      responseSchema: RxIntegrationSchema,
    });
  },
  getMerchantDetails: () => {
    return getApi<RxMerchantsContract>({
      url: '/rx-merchant-details',
      responseSchema: RxMerchants,
    });
  },
  fetchBankingAccounts: () => {
    return getApi<BankingAccountsSchemaContract>({
      url: `/rx-merchant-details/banking-accounts`,
      responseSchema: BankingAccountsSchema,
    });
  },
  activateIntegration: (bankingAccountId: string) => {
    return postApi<SuccessResponseSchemaContract, ActivateIntegrationContact>({
      url: '/rx-integration/activate',
      requestData: {
        banking_account_id: bankingAccountId,
      },
      responseSchema: SuccessResponseSchema,
    });
  },
  initiateLogin: () => {
    return postApi<RxIntegrationLoginSchemaContract, InitiateLoginContract>({
      url: '/rx-integration/initiate-login',
      responseSchema: RxIntegrationLoginSchema,
    });
  },
};
