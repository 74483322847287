import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface BasicUserLoanDetailsSchemaContract {
  existing_loan_count: number;
  last_payroll_month: string | null;
  employee_name: string | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    existing_loan_count: { type: ['number'] },
    last_payroll_month: { type: ['string', 'null'] },
    employee_name: { type: ['string', 'null'] },
  },
  required: ['existing_loan_count', 'last_payroll_month', 'employee_name'],
};
