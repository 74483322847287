import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetLOPAmountSchemaContract {
  lop_amount: number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: { lop_amount: { type: 'integer' } },
  required: ['lop_amount'],
};
