import { put, StrictEffect, takeLatest } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import { setGenericAppError } from '../../reducers/genericError';
import {
  loggedInUserFetchFailed,
  loggedInUserFetchSucceded,
  ActionTypes,
} from '../../reducers/loggedInUser';
import GetMeApiSchema, { GetMeApiSchemaContract } from '../../schemas/GetMeApiSchema';
import { identifySegment } from 'utils/segment';
import { setUserContext as setSentryUserContext } from 'utils/sentry';
import { identifyHotjar } from 'utils/Hotjar';
import userRoles from 'constants/userRoles';

const identifyUserWrapper = () => {
  let isIdentified = false;

  return ({
    userId,
    orgId,
    email = '',
    name = '',
    phone = '',
    employees = '',
    title = '',
    source = '',
    userRoleId = null
  }: {
    userId: string;
    orgId: string;
    email?: string;
    name?: string;
    phone?: string;
    employees?: string;
    title?: string;
    source?: string;
    userRoleId?: number | null
  }) => {
    if (!isIdentified) {
      identifySegment({ userId, orgId, email, phone, name, employees, title, source });
      identifyHotjar(userId, {
        is_admin: userRoleId ? userRoles.ADMIN === userRoleId : false,
        org_id: orgId
      });
      isIdentified = true;
    }
  };
};

const identifyUser = identifyUserWrapper();

function* fetchLoggedInUser(): Generator<StrictEffect, void, GetMeApiSchemaContract> {
  try {
    const loggedInUser = yield AppSaga.getApi({ url: '/me', responseSchema: GetMeApiSchema });
    if (Object.keys(loggedInUser).length === 0) {
      // The loggedIn response will be empty for unauthorised users
      return;
    }
    const identifyObj = {
      userId: loggedInUser.currentOrganization?.employeeDetails?.peopleId.toString() || '',
      orgId: loggedInUser.currentOrganization?.employeeDetails?.organizationId.toString() || '',
      name: loggedInUser.name,
      email: loggedInUser.email as string || '',
      title: loggedInUser.signupTitle as string || '',
      employees: loggedInUser.currentOrganization?.signupNumEmployees as string || '',
      phone: loggedInUser.signupPhoneNumber as string || '',
      source: loggedInUser.currentOrganization?.onboardingConfig?.signupSource as string || '',
      userRoleId: loggedInUser.currentOrganization?.employeeDetails?.userRoleId as number || null
    };
    identifyUser(identifyObj);

    // add user info to sentry
    setSentryUserContext(loggedInUser.id, loggedInUser.currentOrganization?.id);
    yield put(loggedInUserFetchSucceded(loggedInUser));
  } catch (e) {
    yield put(loggedInUserFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_LOGGED_IN_USER, fetchLoggedInUser);
}
