import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface LoanRecoveryPreviewResponseSchemaContract {
  people_id: number;
  organization_id: number;
  loan_id: number;
  data: {
    payroll_month: string;
    emi_amount: number;
    status: number;
    principal_emi: number;
    interest_emi: number;
    remaining_principal: number | null;
    remaining_interest: number | null;
    remaining_total_amount: number | null;
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    people_id: { type: ['number'] },
    organization_id: { type: ['number'] },
    loan_id: { type: ['number'] },
    data: {
      type: ['array'],
      items: {
        type: ['object'],
        properties: {
          payroll_month: { type: ['string'], format: 'date' },
          emi_amount: { type: ['number'] },
          status: { type: ['number'] },
          principal_emi: { type: ['number'] },
          interest_emi: { type: ['number'] },
          remaining_principal: { type: ['number', 'null'] },
          remaining_interest: { type: ['number', 'null'] },
          remaining_total_amount: { type: ['number', 'null'] },
        },
        required: [
          'payroll_month',
          'emi_amount',
          'status',
          'principal_emi',
          'interest_emi',
          'remaining_principal',
          'remaining_interest',
          'remaining_total_amount',
        ],
      },
    },
  },
  required: ['people_id', 'organization_id', 'loan_id', 'data'],
};
