import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface FetchUserDataSchemaContract {
  id: number;
  peopleId: number;
  organizationId: number;
  hireDate: string | null;
  employeeType: string | null;
  userRoleId: number | null;
  title: string | null;
  isActive: boolean | null;
  deactivateDate: string | null;
  permissions?: string[];
  reporteesCount: number;
  name: string;
  signupTitle?: string | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    id: { type: 'integer' },
    peopleId: { type: 'integer' },
    organizationId: { type: 'integer' },
    hireDate: { type: ['string', 'null'] },
    employeeType: { type: ['string', 'null'] },
    userRoleId: { type: ['integer', 'null'] },
    title: { type: ['string', 'null'] },
    isActive: { type: ['boolean', 'null'] },
    deactivateDate: { type: ['string', 'null'] },
    permissions: { type: 'array', items: { type: 'string' } },
    reporteesCount: { type: 'integer' },
    name: { type: 'string' },
    signupTitle: { type: ['string', 'null'] },
  },
  required: [
    'id',
    'peopleId',
    'organizationId',
    'hireDate',
    'employeeType',
    'userRoleId',
    'title',
    'isActive',
    'deactivateDate',
    'reporteesCount',
    'name',
  ],
};
