import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetInsuranceEstimateContract {
  totalEmployees: number;
  totalEligibleEmployees: number;
  totalEligibleDependents: number;
  totalMissingEmployeeDetails: number;
  totalEsiEmployees: number;
  currentOrganizationBalance: number;
  minimumEmployeesRequired: number;
  maxChildrenAllowed: number;
  areParentsAllowed: boolean;
  isSpousedAllowed: boolean;
  policyRestrictionsForPurchase: {
    hasError: boolean;
    issues: string[];
    [k: string]: unknown;
  };
  policyRestrictionsForEstimate: {
    hasError: boolean;
    issues: string[];
    [k: string]: unknown;
  };
  roughEstimate: {
    gst: number;
    amount: number;
    totalAmount: number;
    roughEstimatedForEmployeeIds: unknown[];
    totalEmployeesHavingMissingDetails: number;
    totalParentsEstimatedForMissingEmployees: number;
    totalChildrenEstimatedForMissingEmployees: number;
    totalSpousesEstimatedForMissingEmployees: number;
    [k: string]: unknown;
  };
  exactEstimate: {
    gst: number;
    amount: number;
    totalAmount: number;
    estimatedForEmployeeIds: unknown[];
    estimatedForDependentIds: unknown[];
    totalParents: number;
    premiumBreakup: {
      newEmployees: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` "^[0-9]+$".
         */
        [k: string]: number;
      };
      newDependents: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` "^[0-9]+$".
         */
        [k: string]: number;
      };
      newEmployeesWithDependents: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` "^[0-9]+$".
         */
        [k: string]: number;
      };
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  description: '',
  type: 'object',
  properties: {
    totalEmployees: { type: 'number' },
    totalEligibleEmployees: { type: 'number' },
    totalEligibleDependents: { type: 'number' },
    totalMissingEmployeeDetails: { type: 'number' },
    totalEsiEmployees: { type: 'number' },
    currentOrganizationBalance: { type: 'number' },
    minimumEmployeesRequired: { type: 'number' },
    maxChildrenAllowed: { type: 'number' },
    areParentsAllowed: { type: 'boolean' },
    isSpousedAllowed: { type: 'boolean' },
    policyRestrictionsForPurchase: {
      type: 'object',
      properties: {
        hasError: { type: 'boolean' },
        issues: { type: 'array', items: { type: 'string' } },
      },
      required: ['hasError', 'issues'],
    },
    policyRestrictionsForEstimate: {
      type: 'object',
      properties: {
        hasError: { type: 'boolean' },
        issues: { type: 'array', items: { type: 'string' } },
      },
      required: ['hasError', 'issues'],
    },
    roughEstimate: {
      description: '',
      type: 'object',
      properties: {
        gst: { type: 'number' },
        amount: { type: 'number' },
        totalAmount: { type: 'number' },
        roughEstimatedForEmployeeIds: { type: 'array' },
        totalEmployeesHavingMissingDetails: { type: 'number' },
        totalParentsEstimatedForMissingEmployees: { type: 'number' },
        totalChildrenEstimatedForMissingEmployees: { type: 'number' },
        totalSpousesEstimatedForMissingEmployees: { type: 'number' },
      },
      required: [
        'gst',
        'amount',
        'totalAmount',
        'roughEstimatedForEmployeeIds',
        'totalEmployeesHavingMissingDetails',
        'totalParentsEstimatedForMissingEmployees',
        'totalChildrenEstimatedForMissingEmployees',
        'totalSpousesEstimatedForMissingEmployees',
      ],
    },
    exactEstimate: {
      description: '',
      type: 'object',
      properties: {
        gst: { type: 'number' },
        amount: { type: 'number' },
        totalAmount: { type: 'number' },
        estimatedForEmployeeIds: { type: 'array' },
        estimatedForDependentIds: { type: 'array' },
        totalParents: { type: 'number' },
        premiumBreakup: {
          type: 'object',
          properties: {
            newEmployees: {
              type: 'object',
              patternProperties: { '^[0-9]+$': { type: 'integer' } },
            },
            newDependents: {
              type: 'object',
              patternProperties: { '^[0-9]+$': { type: 'integer' } },
            },
            newEmployeesWithDependents: {
              type: 'object',
              patternProperties: { '^[0-9]+$': { type: 'integer' } },
            },
          },
          required: ['newEmployees', 'newDependents', 'newEmployeesWithDependents'],
        },
      },
      required: [
        'gst',
        'amount',
        'totalAmount',
        'estimatedForEmployeeIds',
        'estimatedForDependentIds',
        'totalParents',
        'premiumBreakup',
      ],
    },
  },
  required: [
    'totalEmployees',
    'totalEligibleEmployees',
    'totalEligibleDependents',
    'totalMissingEmployeeDetails',
    'currentOrganizationBalance',
    'minimumEmployeesRequired',
    'maxChildrenAllowed',
    'areParentsAllowed',
    'isSpousedAllowed',
    'roughEstimate',
    'exactEstimate',
    'totalEsiEmployees',
    'policyRestrictionsForPurchase',
    'policyRestrictionsForEstimate',
  ],
};
