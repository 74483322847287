import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { ToastItemProps } from '../types';
import { closeToastViaEvent } from 'utils/ToastEvents';
import cx from 'classnames';
import styles from './styles.module.scss';

const classTypeMap = {
  success: styles['success'],
  danger: styles['danger'],
};

const ToastItem = ({
  id,
  children,
  timeout,
  type = 'success',
}: PropsWithChildren<ToastItemProps>) => {
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (timeout) {
      timer.current = setTimeout(() => {
        closeToastViaEvent(id);
      }, timeout);
    }

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  return <div className={cx(styles['ToastItem'], classTypeMap[type])}>{children}</div>;
};

export default ToastItem;
