import { put, StrictEffect, takeLatest } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import { setGenericAppError } from '../../reducers/genericError';
import NotificationAPISchema, {
  NotificationApiSchemaContract,
} from '../../schemas/NotificationApiSchema';
import {
  notificationFetchFailed,
  notificationFetchSucceeded,
  ActionTypes,
  notificationFetching,
  IMarkNotificationsAsRead,
  INotification,
} from '../../reducers/notifications';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';

function* fetchNotificationSaga(): Generator<StrictEffect, void, NotificationApiSchemaContract> {
  try {
    yield put(notificationFetching());
    const notifications = yield AppSaga.getApi({
      url: `/notifications/getNotifications`,
      responseSchema: NotificationAPISchema,
    });
    yield put(notificationFetchSucceeded(notifications));
  } catch (e) {
    yield put(notificationFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

function* markNotificationsReadSaga(
  action: IMarkNotificationsAsRead,
): Generator<StrictEffect, void, SuccessResponseSchemaContract | INotification> {
  const notificationIds = action.payload.notificationIds;

  yield AppSaga.postApi({
    url: `/notifications/markNotificationsRead`,
    responseSchema: SuccessResponseSchema,
    requestData: {
      notificationIds: notificationIds,
    },
  });
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_NOTIFICATIONS, fetchNotificationSaga);
  yield takeLatest(ActionTypes.MARK_NOTIFICATIONS_READ, markNotificationsReadSaga);
}
