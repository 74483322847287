import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetUserOrgFlexiPartnerDetailsSchemaContract {
  name: null | string;
  dateOfBirth: null | string;
  email: null | string;
  phoneNumber: null | string;
  pan: null | string;
  totalAmountAllocated: number;
  expensesDeclaredMonthly: number;
  annualSavings: number;
  orgOptedOn: null | string;
  optedOn: null | string;
  isApproved: boolean;
  isNewTaxRegimeSelected: boolean;
  partnerId: number;
  isAlreadyEnabledInOtherOrganization: boolean;
  deliveryType?: 1 | 2;
  walletBudgets: {
    wallet: {
      id: number;
      name: string;
      partner_id: number;
      partner_details: null | {
        walletId: string;
        productCode: string;
        basicPartnerDetails: null | {
          [k: string]: unknown;
        };
        [k: string]: unknown;
      };
      is_approved: boolean;
      compliance: {
        type: string;
        caseWiseRules:
          | []
          | [
              {
                min: number;
                max: number;
                case: string;
                [k: string]: unknown;
              },
            ];
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
    id: number;
    amount: number;
    currentCompliance: {
      case: null | string;
      min: null | number;
      max: null | number;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  }[];
  isCardDetailsSent: boolean;
  cardDeliveryDetails: {
    address: null | string;
    city: null | string;
    state: null | string;
    pinCode: null | string;
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    name: { type: ['null', 'string'] },
    dateOfBirth: { type: ['null', 'string'] },
    email: { type: ['null', 'string'] },
    phoneNumber: { type: ['null', 'string'] },
    pan: { type: ['null', 'string'] },
    totalAmountAllocated: { type: 'number' },
    expensesDeclaredMonthly: { type: 'number' },
    annualSavings: { type: 'number' },
    orgOptedOn: { type: ['null', 'string'], format: 'date' },
    optedOn: { type: ['null', 'string'], format: 'date' },
    isApproved: { type: 'boolean' },
    isNewTaxRegimeSelected: { type: 'boolean' },
    partnerId: { type: 'integer' },
    isAlreadyEnabledInOtherOrganization: { type: 'boolean' },
    deliveryType: { type: 'integer', enum: [1, 2] },
    walletBudgets: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          wallet: {
            type: 'object',
            properties: {
              id: { type: 'integer' },
              name: { type: 'string' },
              partner_id: { type: 'integer' },
              partner_details: {
                type: ['null', 'object'],
                properties: {
                  walletId: { type: 'string' },
                  productCode: { type: 'string' },
                  basicPartnerDetails: { type: ['null', 'object'] },
                },
                required: ['walletId', 'productCode', 'basicPartnerDetails'],
              },
              is_approved: { type: 'boolean' },
              compliance: {
                type: 'object',
                properties: {
                  type: { type: 'string' },
                  caseWiseRules: {
                    type: 'array',
                    items: [
                      {
                        type: 'object',
                        properties: {
                          min: { type: 'integer' },
                          max: { type: 'integer' },
                          case: { type: 'string' },
                        },
                        required: ['min', 'max', 'case'],
                      },
                    ],
                  },
                },
                required: ['type', 'caseWiseRules'],
              },
            },
            required: ['id', 'name', 'partner_id', 'partner_details', 'is_approved', 'compliance'],
          },
          id: { type: 'integer' },
          amount: { type: 'number' },
          currentCompliance: {
            type: 'object',
            properties: {
              case: { type: ['null', 'string'] },
              min: { type: ['null', 'integer'] },
              max: { type: ['null', 'integer'] },
            },
            required: ['case', 'min', 'max'],
          },
        },
        required: ['wallet', 'id', 'amount', 'currentCompliance'],
      },
    },
    isCardDetailsSent: { type: 'boolean' },
    cardDeliveryDetails: {
      type: 'object',
      properties: {
        address: { type: ['null', 'string'] },
        city: { type: ['null', 'string'] },
        state: { type: ['null', 'string'] },
        pinCode: { type: ['null', 'string'] },
      },
      required: ['address', 'city', 'state', 'pinCode'],
    },
  },
  required: [
    'name',
    'pan',
    'dateOfBirth',
    'email',
    'phoneNumber',
    'totalAmountAllocated',
    'expensesDeclaredMonthly',
    'annualSavings',
    'orgOptedOn',
    'isCardDetailsSent',
    'optedOn',
    'isApproved',
    'partnerId',
    'isAlreadyEnabledInOtherOrganization',
    'walletBudgets',
    'cardDeliveryDetails',
    'isNewTaxRegimeSelected',
  ],
};
