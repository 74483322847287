import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface FnfUpdateResponseSchemaContract {
  success: boolean;
  message_type?: string;
  payslip_link?: string;
  runpayroll_link?: string;
  payroll_month: string;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    success: { type: ['boolean'] },
    message_type: { type: ['string'] },
    payslip_link: { type: ['string'] },
    runpayroll_link: { type: ['string'] },
    payroll_month: { type: ['string'], format: 'date' },
  },
  required: ['success', 'payroll_month'],
};
