import { getApi, postApi } from 'utils/Api';
import EsicRegistrationSchema, {
  EsicRegistrationSchemaContract,
} from '../schemas/EsicRegistrationSchema';
import SuccessResponseSchema from 'schemas/SuccessResponseSchema';

export default {
  fetchData: (page: number, rowLimit: number, searchFilter: string, statusFilter: string) => {
    const queryParams = `?page=${page}&page_size=${rowLimit}&search=${searchFilter}&status=${statusFilter}`;
    return getApi<EsicRegistrationSchemaContract>({
      url: `/reports/esic-dashboard${queryParams}`,
      responseSchema: EsicRegistrationSchema,
    });
  },

  retryRegistration: (userId: number) => {
    return postApi<EsicRegistrationSchemaContract, {}>({
      url: `/reports/esic-dashboard/retry?userId=${userId}`,
      responseSchema: SuccessResponseSchema,
    });
  },
};
