import GetOrgFlexiPartnerDetailsSchema, { GetOrgFlexiPartnerDetailsSchemaContract } from 'schemas/GetOrgFlexiPartnerDetailsSchema';
import { UpdateOrgFlexiPartnerDetailsSchemaContract } from 'schemas/UpdateOrgFlexiPartnerDetailsSchema';
import {getApi, postApi, putApi} from 'utils/Api';
import GetUserOrgFlexiPartnerDetailsSchema, {GetUserOrgFlexiPartnerDetailsSchemaContract} from "../schemas/GetUserOrgFlexiPartnerDetailsSchema";
import SuccessResponseSchema, {SuccessResponseSchemaContract} from "../schemas/SuccessResponseSchema";
import { UpdateUserOrgFlexiPartnerDetailsSchemaContract } from '../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';
import { UpdateOrgFlexiPartnerKYCDocumentsSchemaContract } from '../schemas/UpdateOrgFlexiPartnerKYCDocumentsSchema';

export type  FlexiWalletBudgetCompliance = UpdateUserOrgFlexiPartnerDetailsSchemaContract['walletBudgets'][0]['compliance']
export type FlexiWalletBudget = UpdateUserOrgFlexiPartnerDetailsSchemaContract['walletBudgets'][0]

export const fetchEmployeeFlexiPartnerDetails = (userOrgId: number) => {
  return getApi<GetUserOrgFlexiPartnerDetailsSchemaContract>({
      url:`/user-org/${userOrgId}/flexi-partner-details`,
      responseSchema: GetUserOrgFlexiPartnerDetailsSchema
  })
}

export const syncEmployeeFlexiPartnerDetails = (peopleOrgId: number, requestData: UpdateUserOrgFlexiPartnerDetailsSchemaContract) => {
    return postApi<SuccessResponseSchemaContract,UpdateUserOrgFlexiPartnerDetailsSchemaContract>({
        url:`/user-org/${peopleOrgId}/flexi-partner-details/sync`,
        responseSchema: SuccessResponseSchema,
        requestData
    })
}

export const fetchOrgFlexiPartnerDetails = (orgId: number) => {
    return getApi<GetOrgFlexiPartnerDetailsSchemaContract>({ 
        url:`/organization/${orgId}/flexi-benefits`,
        responseSchema: GetOrgFlexiPartnerDetailsSchema
    })
}

export const updateOrgFlexiPartnerDetails = (orgId: number, requestData: UpdateOrgFlexiPartnerDetailsSchemaContract) => {
    return putApi<SuccessResponseSchemaContract,UpdateOrgFlexiPartnerDetailsSchemaContract>({
        url:`/organization/${orgId}/flexi-benefits`,
        responseSchema: SuccessResponseSchema,
        requestData
    })
}

export const updateOrgFlexiPartnerKYC = (orgId: number, requestData: FormData) => {
    return postApi<SuccessResponseSchemaContract,FormData>({
        url:`/organization/${orgId}/flexi-benefits-kyc`,
        responseSchema: SuccessResponseSchema,
        requestData
    },'multipart')
}