import { getApi, postApi } from 'utils/Api';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import Allowance from 'utils/Allowance';
import { CredentialsType } from 'components/CompanyOnboarding/Compliance/PtSetup/types';
import GetAddressFromGstinSchema, {
  GetAddressFromGstinSchemaContract,
} from 'schemas/GetAddressFromGstinSchema';
import GetCompanyNameFromPanSchema, {
  GetCompanyNameFromPanSchemaContract,
} from 'schemas/GetCompanyNameFromPanSchema';
import VerifyCompanyDetailsWithGstinSchema, {
  VerifyCompanyDetailsWithGstinContract,
} from 'schemas/VerifyCompanyDetailsWithGstin';
import GetDetailsByPincodeSchema, {
  GetDetailsByPincodeSchemaContract,
} from 'schemas/GetDetailsByPincodeSchema';

export type AddressUpdateRequest = {
  addressLineOne: string;
  addressLineTwo?: string;
  state: number;
  pincode: string;
};

export type CompanyDetailsUpdateRequest = {
  companyName: string;
  brandName: string;
  addressLineOne: string;
  addressLineTwo: string;
  isAddressConfirmedFromGstin: boolean | null;
  isNameConfirmedFromPan: boolean | null;
  state: number;
  pincode: string;
  orgType: number;
  gstin: string;
  pan: string;
};

export type TaxInfoRequest = {
  pan: string;
  tan: string;
  gstin: string;
};

export type PayrollInfoRequest = {
  isPayrollEnabled: boolean;
  payrollDate: number;
  isAutoPayrollEnabled: boolean;
  isAdvanceSalaryEnabled: boolean;
};

export type SalaryStructureRequest = {
  shouldFlexibleBenefitsBeTaxableByDefault: boolean;
  shouldUseDefaultSalaryStructure: boolean;
  customBasic: number;
  customLta: number;
  customHra: number;
  customDa: number;
  customSpecialAllowance: number;
  customNpsLimit: number;
  customAllowances: Allowance[];
};

export type TDSUpdateRequest = {
  isSalaryTDSEnabled: boolean;
  isNonSalaryTDSEnabled: boolean;
  itPortalUsername?: string;
  itPortalPassword?: string;
  tracesUsername?: string;
  tracesPassword?: string;
  shouldAutoFile24Q: boolean;
  shouldAutoFile26Q: boolean;
  start24QFrom: string | null;
  start26QFrom: string | null;
  tan?: string | null;
};

export type ITCredentialsUpdateRequest = {
  itPortalUsername: string;
  itPortalPassword: string;
};

export type PTUpdateRequest = {
  isPTEnabled: boolean;
  isPTPaymentEnabled: boolean;
  credentials?: CredentialsType[] | null;
};

export type PFUpdateRequest = {
  isPFEnabled: boolean;
  isPFPaymentEnabled: boolean;
  shouldIncludeEmployerContributionInCtc: boolean;
  shouldIncludeAdminCharges: boolean;
  shouldUseOnlyBasicForPf: boolean;
  shouldUsePfLimit: boolean;
  pfUsername?: string;
  pfPassword?: string;
};

export type ESICUpdateRequest = {
  isESICEnabled: boolean;
  isESICPaymentEnabled: boolean;
  shouldIncludeEmployerContributionInCtc: boolean;
  shouldIncludeAdditions: boolean;
  esicUsername?: string;
  esicPassword?: string;
};

export type InviteCARequest = {
  name: string;
  email: string;
  phone: string;
};

export interface VerifyCompanyDetailsWithPanRequest {
  signatoryPan: string;
  signatoryName: string;
}

export interface VerifyCompanyDetailsWithGstinRequest extends VerifyCompanyDetailsWithPanRequest {
  gstin: string;
}

export interface VerifyCompanyDetailsWithCinRequest extends VerifyCompanyDetailsWithPanRequest {
  cin: string;
}

export interface VerifyCompanyDetailsWithLlpinRequest extends VerifyCompanyDetailsWithPanRequest {
  llpin: string;
}

const companyOnboardingApis = {
  getAddressFromGstin: (gstin: string) => {
    return getApi<GetAddressFromGstinSchemaContract>({
      url: `/organization/get-gst-details/${gstin}`,
      responseSchema: GetAddressFromGstinSchema,
    });
  },
  getCompanyNameFromPan: (pan: string) => {
    return getApi<GetCompanyNameFromPanSchemaContract>({
      url: `/organization/get-pan-details/${pan}`,
      responseSchema: GetCompanyNameFromPanSchema,
    });
  },
  updateCompanyAddress: ({
    addressLineOne,
    addressLineTwo,
    state,
    pincode,
  }: AddressUpdateRequest) => {
    return postApi<
      SuccessResponseSchemaContract,
      {
        company_name?: string;
        brand_name?: string;
        address_line_one: string;
        address_line_two?: string;
        state: number;
        pincode: string;
      }
    >({
      url: '/organization/update-company-address',
      responseSchema: SuccessResponseSchema,
      requestData: {
        address_line_one: addressLineOne,
        address_line_two: addressLineTwo,
        state,
        pincode,
      },
    });
  },
  updateCompanyDetails: ({
    companyName,
    brandName = '',
    addressLineOne,
    addressLineTwo = '',
    state,
    pincode,
    orgType,
    pan,
    gstin,
    isAddressConfirmedFromGstin,
    isNameConfirmedFromPan,
  }: CompanyDetailsUpdateRequest) => {
    return postApi<
      SuccessResponseSchemaContract,
      {
        company_name: string;
        brand_name: string;
        address_line_one: string;
        address_line_two: string;
        is_address_confirmed_from_gstin: boolean | null;
        is_name_confirmed_from_pan: boolean | null;
        state: number;
        type: number;
        pincode: string;
        pan: string;
        gstin: string;
      }
    >({
      url: '/organization/update-company-details',
      responseSchema: SuccessResponseSchema,
      requestData: {
        company_name: companyName,
        brand_name: brandName,
        address_line_one: addressLineOne,
        address_line_two: addressLineTwo,
        is_address_confirmed_from_gstin: isAddressConfirmedFromGstin,
        is_name_confirmed_from_pan: isNameConfirmedFromPan,
        state,
        type: orgType,
        pincode,
        pan,
        gstin,
      },
    });
  },
  updateTaxInfo: ({ pan, tan, gstin = '' }: TaxInfoRequest) => {
    return postApi<
      SuccessResponseSchemaContract,
      {
        pan: string;
        tan: string;
        gstin: string;
      }
    >({
      url: '/organization/update-tax-info',
      responseSchema: SuccessResponseSchema,
      requestData: {
        pan,
        tan,
        gstin,
      },
    });
  },
  updatePayrollInfo: ({
    isPayrollEnabled,
    payrollDate,
    isAutoPayrollEnabled,
    isAdvanceSalaryEnabled,
  }: PayrollInfoRequest) => {
    return postApi<
      SuccessResponseSchemaContract,
      {
        is_payroll_enabled: boolean;
        payroll_date: number;
        is_auto_payroll_enabled: boolean;
        is_advance_salary_enabled: boolean;
      }
    >({
      url: '/organization/update-payroll-info',
      responseSchema: SuccessResponseSchema,
      requestData: {
        is_payroll_enabled: isPayrollEnabled,
        payroll_date: payrollDate,
        is_auto_payroll_enabled: isAutoPayrollEnabled,
        is_advance_salary_enabled: isAdvanceSalaryEnabled,
      },
    });
  },
  updateSalaryStructure: ({
    customBasic,
    customHra,
    customLta,
    customDa,
    customSpecialAllowance,
    customNpsLimit,
    customAllowances,
    shouldFlexibleBenefitsBeTaxableByDefault,
    shouldUseDefaultSalaryStructure,
  }: SalaryStructureRequest) => {
    return postApi<
      SuccessResponseSchemaContract,
      {
        custom_basic: number;
        custom_hra: number;
        custom_lta: number;
        custom_da: number;
        custom_special_allowance: number;
        custom_nps_limit: number;
        custom_allowances: Allowance[];
        should_flexible_benefit_taxable_by_default: boolean;
        use_default_structure: boolean;
      }
    >({
      url: '/organization/update-salary-structure',
      responseSchema: SuccessResponseSchema,
      requestData: {
        custom_basic: customBasic,
        custom_hra: customHra,
        custom_lta: customLta,
        custom_da: customDa,
        custom_special_allowance: customSpecialAllowance,
        custom_nps_limit: customNpsLimit,
        custom_allowances: customAllowances,
        should_flexible_benefit_taxable_by_default: shouldFlexibleBenefitsBeTaxableByDefault,
        use_default_structure: shouldUseDefaultSalaryStructure,
      },
    });
  },
  updateTDSSetup: ({
    isSalaryTDSEnabled,
    isNonSalaryTDSEnabled,
    itPortalUsername,
    itPortalPassword,
    tracesUsername,
    tracesPassword,
    shouldAutoFile24Q,
    shouldAutoFile26Q,
    start24QFrom,
    start26QFrom,
    tan,
  }: TDSUpdateRequest) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/tds-setup',
      responseSchema: SuccessResponseSchema,
      requestData: {
        enable_salary_tds_payments: isSalaryTDSEnabled,
        enable_non_salary_tds_payments: isNonSalaryTDSEnabled,
        it_portal_username: itPortalUsername,
        it_portal_password: itPortalPassword,
        traces_username: tracesUsername,
        traces_password: tracesPassword,
        enable_24q: shouldAutoFile24Q,
        enable_26q: shouldAutoFile26Q,
        start_24q_from: start24QFrom,
        start_26q_from: start26QFrom,
        tan,
      },
    });
  },
  updatePTSetup: ({ isPTEnabled, isPTPaymentEnabled, credentials }: PTUpdateRequest) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/pt-setup',
      responseSchema: SuccessResponseSchema,
      requestData: {
        enable_pt: isPTEnabled,
        enable_pt_payments: isPTPaymentEnabled,
        credentials: credentials,
      },
    });
  },
  updatePFSetup: ({
    isPFEnabled,
    isPFPaymentEnabled,
    shouldIncludeEmployerContributionInCtc,
    shouldIncludeAdminCharges,
    shouldUseOnlyBasicForPf,
    shouldUsePfLimit,
    pfUsername,
    pfPassword,
  }: PFUpdateRequest) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/pf-setup',
      responseSchema: SuccessResponseSchema,
      requestData: {
        enable_pf: isPFEnabled,
        enable_pf_payments: isPFPaymentEnabled,
        include_employer_contribution_ctc: shouldIncludeEmployerContributionInCtc,
        include_admin_edli_charges_ctc: shouldIncludeAdminCharges,
        use_only_basic_pf_calculation: shouldUseOnlyBasicForPf,
        use_pf_limit_15k: shouldUsePfLimit,
        pf_username: pfUsername,
        pf_password: pfPassword,
      },
    });
  },
  updateESICSetup: ({
    isESICEnabled,
    isESICPaymentEnabled,
    shouldIncludeEmployerContributionInCtc,
    shouldIncludeAdditions,
    esicUsername,
    esicPassword,
  }: ESICUpdateRequest) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/esi-setup',
      responseSchema: SuccessResponseSchema,
      requestData: {
        enable_esi: isESICEnabled,
        enable_esi_payments: isESICPaymentEnabled,
        include_employer_contribution_ctc: shouldIncludeEmployerContributionInCtc,
        include_payroll_additions_one_time_payment_esi_wages: shouldIncludeAdditions,
        esi_username: esicUsername,
        esi_password: esicPassword,
      },
    });
  },
  inviteCA: ({ name, email, phone }: InviteCARequest) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/invite-ca',
      responseSchema: SuccessResponseSchema,
      requestData: {
        name,
        email,
        phone,
      },
    });
  },
  updateSalaryRegisterPreference: (columns: string[]) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/update-salary-register-pref',
      responseSchema: SuccessResponseSchema,
      requestData: {
        columns: columns,
      },
    });
  },
  enable2FASetting: () => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/two-factor/enable',
      responseSchema: SuccessResponseSchema,
      requestData: {
      },
    });
  },
  disable2FASetting: (otp: string) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/organization/two-factor/disable',
      responseSchema: SuccessResponseSchema,
      requestData: {
        otp: otp
      },
    });
  },
  verifyCompanyDetailsWithGstin: (requestData: VerifyCompanyDetailsWithGstinRequest) => {
    return postApi<VerifyCompanyDetailsWithGstinContract, any>({
      url: '/company-verification/verify-with-gstin',
      responseSchema: VerifyCompanyDetailsWithGstinSchema,
      requestData,
    });
  },
  verifyCompanyDetailsWithCin: (requestData: VerifyCompanyDetailsWithCinRequest) => {
    return postApi<SuccessResponseSchemaContract, any>({
      url: '/company-verification/verify-with-cin',
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },
  verifyCompanyDetailsWithLlpin: (requestData: VerifyCompanyDetailsWithLlpinRequest) => {
    return postApi<SuccessResponseSchemaContract, any>({
      url: '/company-verification/verify-with-llpin',
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },
  verifyCompanyDetailsWithPan: (requestData: VerifyCompanyDetailsWithPanRequest) => {
    return postApi<SuccessResponseSchemaContract, any>({
      url: '/company-verification/verify-with-pan',
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },
  getDetailsByPincode: (pincode: string) => {
    return getApi<GetDetailsByPincodeSchemaContract>({
      url: `/get-pincode-details/${pincode}`,
      responseSchema: GetDetailsByPincodeSchema,
    });
  },
};

export default companyOnboardingApis;
