import { postApi, getApi } from '../utils/Api';
import SuccessResponseSchema, { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';

export interface VerifyRequest {
    otp: string;
}

export interface OTPUserData {
    email: string;
    is_authenticator_enabled: boolean;
}

export default {
    resendOtp: (action: string) => {
        return postApi<SuccessResponseSchemaContract, {}>({
            url: `/2fa-otp/resend`,
            responseSchema: SuccessResponseSchema,
            requestData: {
                action: action,
            },
        })
    },
    verifyOtp: (otp: string) => {
        return postApi<SuccessResponseSchemaContract, VerifyRequest>({
            url: `/2fa-otp/verify`,
            responseSchema: SuccessResponseSchema,
            requestData: {
              otp: otp,
            },
          });
    },
    getUserData: () => {
        return getApi<OTPUserData>({
            url: `/2fa-otp/data`,
            responseSchema: {}
        })
    },
    resetMethod: () => {
        return getApi<SuccessResponseSchemaContract>({
            url: `/2fa-otp/reset-method`,
            responseSchema: SuccessResponseSchema
        })
    },
}
