type STATUS_POLL_STATUS_PROCESSING = 'processing';
type STATUS_POLL_STATUS_SUCCESS = 'success';
type STATUS_STATUS_FAILURE = 'failure';
type STATUS_POLL_STATUS_CANCELLED = 'cancelled';

export const PollStatuses = {
  POLL_STATUS_PROCESSING: 'processing' as STATUS_POLL_STATUS_PROCESSING,
  POLL_STATUS_SUCCESS: 'success' as STATUS_POLL_STATUS_SUCCESS,
  POLL_STATUS_FAILURE: 'failure;' as STATUS_STATUS_FAILURE,
  POLL_STATUS_CANCELLED: 'cancelled;' as STATUS_POLL_STATUS_CANCELLED,
};

type LongPollType<ResultType> = {
  tries: number;
  pollInterval: number;
  pollFunction: () => Promise<{
    status: STATUS_POLL_STATUS_PROCESSING | STATUS_POLL_STATUS_SUCCESS | STATUS_STATUS_FAILURE | STATUS_POLL_STATUS_CANCELLED;
    data?: ResultType;
  }>;
  successCallback: (data: any) => void;
  failureCallback: (error: string) => void;
  cancelCallback?: (data: any) => void;
};

class LongPoll<ResultType> {
  pollFunction: LongPollType<ResultType>['pollFunction'];
  count: number;
  tries: number;
  successCallback: LongPollType<ResultType>['successCallback'];
  failureCallback: LongPollType<ResultType>['failureCallback'];
  cancelCallback?: LongPollType<ResultType>['cancelCallback'];
  pollInterval: LongPollType<ResultType>['pollInterval'];
  status:
    | STATUS_POLL_STATUS_PROCESSING
    | STATUS_POLL_STATUS_SUCCESS
    | STATUS_STATUS_FAILURE
    | STATUS_POLL_STATUS_CANCELLED
    | null = null;
  result: ResultType | null = null;
  error: string | null = null;

  constructor({
    pollFunction,
    successCallback,
    failureCallback,
    cancelCallback,
    tries,
    pollInterval,
  }: LongPollType<ResultType>) {
    this.pollFunction = pollFunction;
    this.count = 0;
    this.tries = tries;
    this.successCallback = successCallback;
    this.failureCallback = failureCallback;
    this.cancelCallback = cancelCallback;
    this.pollInterval = pollInterval;
  }

  async poll() {
    try {
      this.status = PollStatuses.POLL_STATUS_PROCESSING;
      let result = await this.pollFunction();
      this.count += 1;

      if (result.status === PollStatuses.POLL_STATUS_SUCCESS) {
        this.status = PollStatuses.POLL_STATUS_SUCCESS;
        this.result = result.data || null;
        this.successCallback(this.result);
      }
      else if (result.status === PollStatuses.POLL_STATUS_CANCELLED) {
        this.status = PollStatuses.POLL_STATUS_CANCELLED;
        this.cancelCallback && this.cancelCallback(result.data || null);
      }
      else if (this.count < this.tries) {
        await new Promise((resolve) => setTimeout(resolve, this.pollInterval));
        await this.poll();
      }

    } catch (e) {
      console.error(e);
      this.status = PollStatuses.POLL_STATUS_FAILURE;
      let error = null;
      if (e instanceof Error) {
        error = e.message;
      } else {
        error = String(e);
      }
      this.error = error;
      this.failureCallback(this.error);
    }
  }

  start() {
    if (this.status === PollStatuses.POLL_STATUS_SUCCESS) {
      this.successCallback(this.result);
    } else if (this.status === PollStatuses.POLL_STATUS_FAILURE) {
      if (this.error) {
        this.failureCallback(this.error);
      } else {
        this.failureCallback('Something went wrong, please contact suppport.');
      }
    }
    else if (this.status === PollStatuses.POLL_STATUS_CANCELLED) {
      this.cancelCallback && this.cancelCallback(this.result);
    } else {
      this.poll();
    }
  }
}

export default LongPoll;
