import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetPartnerDetailsSchemaContract {
  partnerId: number;
  isOrgIntegratedToPartner: boolean;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: { partnerId: { type: 'number' }, isOrgIntegratedToPartner: { type: 'boolean' } },
  required: ['partnerId', 'isOrgIntegratedToPartner'],
};
