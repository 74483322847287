export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCaseToWords(str: string) {
  return str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
}

export function replaceHTMLSpecialChars(str: string) {
  return str.replace("&#039;",'\'');
}