import { GetOrgEmployeeForInsuranceSchemaContract } from '../../schemas/GetOrgEmployeeForInsuranceSchema';
import { UpdateOrgEmployeeForInsuranceSchemaContract } from '../../schemas/UpdateOrgEmployeeForInsuranceSchema';
import { IAppErrorDetails } from '../../utils/AppError';

export interface IInsuranceEmployee {
  hasError: boolean;
  data: GetOrgEmployeeForInsuranceSchemaContract | null;
  errorData: IAppErrorDetails | null;
  isSynced: boolean;
  isFetching: boolean;
}

export enum ActionTypes {
  INSURANCE_EMPLOYEE_FETCH_SUCCEEDED = 'INSURANCE_EMPLOYEE_FETCH_SUCCEEDED',
  INSURANCE_EMPLOYEE_FETCH_FAILED = 'INSURANCE_EMPLOYEE_FETCH_FAILED',
  FETCH_INSURANCE_EMPLOYEE = 'FETCH_INSURANCE_EMPLOYEE',
  INSURANCE_EMPLOYEE_FETCHING = 'INSURANCE_EMPLOYEE_FETCHING',
  UPDATE_INSURANCE_EMPLOYEE = 'UPDATE_INSURANCE_EMPLOYEE',
  ADD_INSURANCE_EMPLOYEE_DEPENDENT = 'ADD_INSURANCE_EMPLOYEE_DEPENDENT',
  UPDATE_INSURANCE_EMPLOYEE_DEPENDENT = 'UPDATE_INSURANCE_EMPLOYEE_DEPENDENT',
  DELETE_INSURANCE_EMPLOYEE_DEPENDENT = 'DELETE_INSURANCE_EMPLOYEE_DEPENDENT',
  SUBMIT_INSURANCE_EMPLOYEE = 'SUBMIT_INSURANCE_EMPLOYEE',
  SEND_REMINDERS = 'SEND_REMINDERS',
}

const initialState: IInsuranceEmployee = {
  hasError: false,
  data: null,
  errorData: null,
  isSynced: false,
  isFetching: false,
};

type Actions =
  | IInsuranceEmployeeFetchSucceeded
  | IInsuranceEmployeeFetchFailed
  | IInsuranceEmployeeFetching;

export function insuranceEmployee(state = initialState, action: Actions): IInsuranceEmployee {
  switch (action.type) {
    case ActionTypes.INSURANCE_EMPLOYEE_FETCHING:
      return { ...state, data: null, isFetching: true, isSynced: false };
    case ActionTypes.INSURANCE_EMPLOYEE_FETCH_SUCCEEDED:
      return {
        ...state,
        data: action.payload.employeeDetails,
        hasError: false,
        errorData: null,
        isFetching: false,
        isSynced: action.payload.isSynced,
      };
    case ActionTypes.INSURANCE_EMPLOYEE_FETCH_FAILED:
      return {
        ...state,
        hasError: true,
        errorData: action.payload,
        isFetching: false,
        isSynced: true,
      };
    default:
      return state;
  }
}

export interface IInsuranceEmployeeFetchSucceeded {
  type: typeof ActionTypes.INSURANCE_EMPLOYEE_FETCH_SUCCEEDED;
  payload: {
    isSynced: boolean;
    employeeDetails: GetOrgEmployeeForInsuranceSchemaContract;
  };
}

export const insuranceEmployeeFetchSucceeded = (
  fetchedData: GetOrgEmployeeForInsuranceSchemaContract,
  isSynced: boolean,
): IInsuranceEmployeeFetchSucceeded => ({
  type: ActionTypes.INSURANCE_EMPLOYEE_FETCH_SUCCEEDED,
  payload: {
    isSynced,
    employeeDetails: fetchedData,
  },
});

export interface IInsuranceEmployeeFetchFailed {
  type: typeof ActionTypes.INSURANCE_EMPLOYEE_FETCH_FAILED;
  payload: IAppErrorDetails;
}

export const insuranceEmployeeFetchFailed = (
  payload: IAppErrorDetails,
): IInsuranceEmployeeFetchFailed => ({
  type: ActionTypes.INSURANCE_EMPLOYEE_FETCH_FAILED,
  payload,
});

/** Mark insurance in fetching state */

export interface IFetchInsuranceEmployee {
  type: typeof ActionTypes.FETCH_INSURANCE_EMPLOYEE;
  payload: {
    employeeId: number;
  };
}

export const fetchInsuranceEmployee = (employeeId: number): IFetchInsuranceEmployee => ({
  type: ActionTypes.FETCH_INSURANCE_EMPLOYEE,
  payload: { employeeId },
});

export interface IInsuranceEmployeeFetching {
  type: typeof ActionTypes.INSURANCE_EMPLOYEE_FETCHING;
}

export const insuranceEmployeeFetching = (): IInsuranceEmployeeFetching => ({
  type: ActionTypes.INSURANCE_EMPLOYEE_FETCHING,
});

export interface IUpdateInsuranceEmployee {
  type: ActionTypes.UPDATE_INSURANCE_EMPLOYEE;
  payload: {
    employeeId: number;
    payload: Pick<
      UpdateOrgEmployeeForInsuranceSchemaContract,
      'gender' | 'dob' | 'name' | 'detailsConfirmedAt' | 'phoneNumber' | 'hireDate'
    >;
  };
}

export const updateInsuranceEmployee = (
  employeeId: number,
  payload: IUpdateInsuranceEmployee['payload']['payload'],
): IUpdateInsuranceEmployee => ({
  type: ActionTypes.UPDATE_INSURANCE_EMPLOYEE,
  payload: {
    employeeId,
    payload,
  },
});

export interface IUpdateInsuranceEmployeeDependent {
  type: ActionTypes.UPDATE_INSURANCE_EMPLOYEE_DEPENDENT;
  payload: {
    dependentId: number;
    employeeId: number;
    payload: Partial<GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]> &
      Pick<GetOrgEmployeeForInsuranceSchemaContract['dependents'][0], 'id'>;
  };
}

export const updateInsuranceEmployeeDependent = (
  employeeId: number,
  dependentId: number,
  payload: IUpdateInsuranceEmployeeDependent['payload']['payload'],
): IUpdateInsuranceEmployeeDependent => ({
  type: ActionTypes.UPDATE_INSURANCE_EMPLOYEE_DEPENDENT,
  payload: {
    employeeId,
    dependentId,
    payload,
  },
});

export interface IAddInsuranceEmployeeDependent {
  type: ActionTypes.ADD_INSURANCE_EMPLOYEE_DEPENDENT;
  payload: {
    employeeId: number;
    payload: Partial<GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]> &
      Pick<GetOrgEmployeeForInsuranceSchemaContract['dependents'][0], 'relation'>;
  };
}

export const addInsuranceEmployeeDependent = (
  employeeId: number,
  payload: IAddInsuranceEmployeeDependent['payload']['payload'],
): IAddInsuranceEmployeeDependent => ({
  type: ActionTypes.ADD_INSURANCE_EMPLOYEE_DEPENDENT,
  payload: {
    employeeId,
    payload,
  },
});

export interface IDeleteInsuranceEmployeeDependent {
  type: ActionTypes.DELETE_INSURANCE_EMPLOYEE_DEPENDENT;
  payload: {
    employeeId: number;
    dependentId: number;
  };
}

export const deleteInsuranceEmployeeDependent = (
  employeeId: number,
  dependentId: number,
): IDeleteInsuranceEmployeeDependent => ({
  type: ActionTypes.DELETE_INSURANCE_EMPLOYEE_DEPENDENT,
  payload: {
    employeeId,
    dependentId,
  },
});

export interface ISubmitInsuranceEmployee {
  type: ActionTypes.SUBMIT_INSURANCE_EMPLOYEE;
  payload: {
    employeeId: number;
    isAdmin: boolean;
    onSuccess: Function;
  };
}

export const submitInsuranceEmployee = (
  employeeId: number,
  isAdmin: boolean,
  onSuccess: Function,
): ISubmitInsuranceEmployee => ({
  type: ActionTypes.SUBMIT_INSURANCE_EMPLOYEE,
  payload: {
    employeeId,
    isAdmin,
    onSuccess,
  },
});

export interface ISendInsuranceReminder {
  type: ActionTypes.SEND_REMINDERS;
  payload: {
    insuranceId: number;
  };
}

export const sendReminder = (insuranceId: number): ISendInsuranceReminder => ({
  type: ActionTypes.SEND_REMINDERS,
  payload: {
    insuranceId,
  },
});
