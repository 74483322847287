import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface RunPayrollSchemaContract {
  payrollData: unknown[];
  payrollIds?: {
    id: number;
    isCancelled: boolean;
    isBlocked: boolean;
    isFinal: boolean;
    [k: string]: unknown;
  }[];
  page?: number;
  limit?: number;
  numTotal: number;
  numSkipped: number;
  numPaid: number;
  numFinalized: number;
  numFinalizedExcludingNewJoiners: number;
  numNetPayOnHold?: number;
  numGrossPayOnHold?: number;
  payrollMonth: string;
  isPayrollFinalized: boolean;
  isFirstPayroll: boolean;
  isExecutionRequested: boolean;
  isLocalOrRXBalanceSufficient: boolean;
  hasAdhocPayments: boolean;
  isPreviousMonthNotProcessed: boolean;
  hasOtherCharges: boolean;
  totalGross: number;
  opfinCharges: number;
  orgBalance: number;
  requiredAmount: {
    components?: {
      'total-flexi-partner-wallets'?: number;
      'total-salary'?: number;
      'total-tds'?: number;
      'total-pf'?: number;
      'total-pf-admin'?: number;
      'total-esi'?: number;
      'total-pt'?: number;
      'tds-interest'?: number;
      'net-pay-on-hold'?: number;
      [k: string]: unknown;
    };
    total?: number;
    'opfin-charges'?: {
      total?: number;
      gst?: number;
      xpayrollCharges?: number;
      xpayrollGst?: number;
      numberOfPayrolls?: number;
      flexiPartnerCardChargesWithoutGst?: number;
      flexiPlatformChargesWithoutGst?: number;
      flexiPartnerCardChargesGst?: number;
      flexiPartnerPlatformChargesGst?: number;
      peopleOrgIdsForFlexiPartnerCardCharges?: unknown[];
      peopleOrgIdsForFlexiPartnerPlatformCharges?: unknown[];
      [k: string]: unknown;
    };
    [k: string]: unknown;
  } | null;
  flexiPartnerName?: string;
  hasTDSReturnsBeenProcessed?: boolean;
  links?: {
    orgKyc?: string;
    addEmployee?: string;
    people?: string;
    moneyTransfer?: string;
    adhocPayments?: string;
    [k: string]: unknown;
  };
  isPayrollBeingFinalized: boolean;
  isBulkAdditionDeductionInProgress: boolean;
  hasFinalizePayrollErrors: boolean;
  isChangeRequestPendingOnFinalize: boolean;
  numPendingEditRequest: number;
  numberOfEmployeesHavingBonus: number;
  numPendingSalaryRevisionRequests: number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    payrollData: { type: ['array'] },
    payrollIds: {
      type: ['array'],
      items: {
        type: ['object'],
        properties: {
          id: { type: 'number' },
          isCancelled: { type: 'boolean' },
          isBlocked: { type: 'boolean' },
          isFinal: { type: 'boolean' },
        },
        required: ['id', 'isCancelled', 'isBlocked', 'isFinal'],
      },
    },
    page: { type: 'number' },
    limit: { type: 'number' },
    numTotal: { type: 'number' },
    numSkipped: { type: 'number' },
    numPaid: { type: 'number' },
    numFinalized: { type: 'number' },
    numFinalizedExcludingNewJoiners: { type: 'number' },
    numNetPayOnHold: { type: 'number' },
    numGrossPayOnHold: { type: 'number' },
    payrollMonth: { type: 'string' },
    isPayrollFinalized: { type: 'boolean' },
    isFirstPayroll: { type: 'boolean' },
    isExecutionRequested: { type: 'boolean' },
    isLocalOrRXBalanceSufficient: { type: 'boolean' },
    hasAdhocPayments: { type: 'boolean' },
    isPreviousMonthNotProcessed: { type: 'boolean' },
    hasOtherCharges: { type: 'boolean' },
    totalGross: { type: 'number' },
    opfinCharges: { type: 'number' },
    orgBalance: { type: 'number' },
    requiredAmount: {
      type: ['object', 'null'],
      properties: {
        components: {
          type: 'object',
          properties: {
            'total-flexi-partner-wallets': { type: 'number' },
            'total-salary': { type: 'number' },
            'total-tds': { type: 'number' },
            'total-pf': { type: 'number' },
            'total-pf-admin': { type: 'number' },
            'total-esi': { type: 'number' },
            'total-pt': { type: 'number' },
            'tds-interest': { type: 'number' },
            'net-pay-on-hold': { type: 'number' },
          },
        },
        total: { type: 'number' },
        'opfin-charges': {
          type: 'object',
          properties: {
            total: { type: 'number' },
            gst: { type: 'number' },
            xpayrollCharges: { type: 'number' },
            xpayrollGst: { type: 'number' },
            numberOfPayrolls: { type: 'number' },
            flexiPartnerCardChargesWithoutGst: { type: 'number' },
            flexiPlatformChargesWithoutGst: { type: 'number' },
            flexiPartnerCardChargesGst: { type: 'number' },
            flexiPartnerPlatformChargesGst: { type: 'number' },
            peopleOrgIdsForFlexiPartnerCardCharges: { type: 'array' },
            peopleOrgIdsForFlexiPartnerPlatformCharges: { type: 'array' },
          },
        },
      },
    },
    flexiPartnerName: { type: 'string' },
    hasTDSReturnsBeenProcessed: { type: 'boolean' },
    links: {
      type: ['object'],
      properties: {
        orgKyc: { type: 'string' },
        addEmployee: { type: 'string' },
        people: { type: 'string' },
        moneyTransfer: { type: 'string' },
        adhocPayments: { type: 'string' },
      },
    },
    isPayrollBeingFinalized: { type: 'boolean' },
    isBulkAdditionDeductionInProgress: { type: 'boolean' },
    hasFinalizePayrollErrors: { type: 'boolean' },
    isChangeRequestPendingOnFinalize: { type: 'boolean' },
    numPendingEditRequest: { type: 'number' },
    numberOfEmployeesHavingBonus: { type: 'number' },
    numPendingSalaryRevisionRequests: { type: 'number' },
  },
  required: [
    'payrollData',
    'numTotal',
    'numSkipped',
    'numPaid',
    'numFinalized',
    'numFinalizedExcludingNewJoiners',
    'payrollMonth',
    'isPayrollFinalized',
    'isFirstPayroll',
    'isExecutionRequested',
    'isLocalOrRXBalanceSufficient',
    'hasAdhocPayments',
    'isPreviousMonthNotProcessed',
    'hasOtherCharges',
    'totalGross',
    'opfinCharges',
    'orgBalance',
    'requiredAmount',
    'isPayrollBeingFinalized',
    'isBulkAdditionDeductionInProgress',
    'hasFinalizePayrollErrors',
    'isChangeRequestPendingOnFinalize',
    'numPendingEditRequest',
    'numberOfEmployeesHavingBonus',
    'numPendingSalaryRevisionRequests',
  ],
};
