import { IInsuranceOrganizationRenewal } from "components/insurance/InsuranceAdmin/InsuranceOrganizationAndEmployeeRenewal";
import GetInsurancePricingData, { GetInsurancePricingDataContract } from "schemas/GetInsurancePricingData";
import { getApi } from "utils/Api";


const insurance = {
  fetchInsuranceRenewalData: (
    id: number,
    currentPage: string = '?page=1',
    page: number = 10,
    search: string = '',
  ) => {
    return getApi<IInsuranceOrganizationRenewal>({
      url: `/insurance/organization/${id}/insuranceOrganizationAndEmployeeRenewal${currentPage}&search=${search}&per_page=${page}`,
      responseSchema: {},
    });
  },
  generateInsuranceRenewalVendorEstimateData: (id: number) => {
    return getApi<any>({
      url: `/insurance/organization/${id}/generateRenewalEndorsementVendorEstimate`,
      responseSchema: {},
    });
  },
  purchaseInsuranceRenewalEndorsement: (id: number) => {
    return getApi<any>({
      url: `/insurance/organization/${id}/purchaseRenewalEndorsement`,
      responseSchema: {},
    });
  },
  getInsurancePricingData: () =>
    getApi<GetInsurancePricingDataContract>({
      url: '/insurance-pricing-data',
	  responseSchema: GetInsurancePricingData,
    }),
};

export default insurance;
