import { ECustomErrors } from '../enums';
import { TErrorType } from '../types';

export interface IAppErrorDetails {
  type: TErrorType;
  messageForUser: string;
  internalMessage: string;
  requestId: string;
  otherDetails?: any;
  code: string;
  errors?: { [k: string]: string[] };
}

export class AppError extends Error {
  protected details: IAppErrorDetails;

  constructor(details: Partial<IAppErrorDetails>) {
    super(details.messageForUser ? details.messageForUser : 'Oops something went wrong!');
    const defaultDetails: IAppErrorDetails = {
      internalMessage: 'Some unknown error occured!',
      messageForUser: this.message || 'Something went wrong, please contact support.',
      requestId: 'unknown',
      type: ECustomErrors.UNKNOWN,
      otherDetails: {},
      code: 'UNKNOWN',
    };
    this.details = Object.assign(defaultDetails, details);
  }

  getDetails(): IAppErrorDetails {
    return this.details;
  }
}

export function getAppErrorDetails(e: any): IAppErrorDetails {
  if (e instanceof AppError) {
    return e.getDetails();
  }

  if (e instanceof Error) {
    return {
      internalMessage: e.message,
      messageForUser: 'Something went wrong',
      requestId: '',
      type: ECustomErrors.UNKNOWN,
      code: 'UNKNOWN',
    };
  }

  return {
    internalMessage: e,
    messageForUser: 'Something went wrong',
    requestId: '',
    type: ECustomErrors.UNKNOWN,
    code: 'UNKNOWN',
  };
}

export function getAppError(e: any) {
  return new AppError(getAppErrorDetails(e));
}
