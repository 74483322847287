import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface FreshTeamsSettingsContract {
  enabled: boolean;
  domain: string | null;
  is_user_data_sync_enabled: boolean;
  last_fetched_at: string | null;
  user_data_sync_status:
    | 'STATUS_SYNC_IN_PROGRESS'
    | 'STATUS_SYNC_FAILURE'
    | 'STATUS_SYNC_SUCCESSFULL'
    | 'STATUS_SYNC_INITIATED'
    | null;
  active_integration: {
    type: number;
    name: string;
    [k: string]: unknown;
  } | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    enabled: { type: 'boolean' },
    domain: { type: ['string', 'null'] },
    is_user_data_sync_enabled: { type: ['boolean'] },
    last_fetched_at: { type: ['string', 'null'], format: 'date-time' },
    user_data_sync_status: {
      type: ['string', 'null'],
      enum: [
        'STATUS_SYNC_IN_PROGRESS',
        'STATUS_SYNC_FAILURE',
        'STATUS_SYNC_SUCCESSFULL',
        'STATUS_SYNC_INITIATED',
        null,
      ],
    },
    active_integration: {
      type: ['object', 'null'],
      properties: { type: { type: 'integer' }, name: { type: 'string' } },
      required: ['type', 'name'],
    },
  },
  required: [
    'enabled',
    'domain',
    'is_user_data_sync_enabled',
    'last_fetched_at',
    'user_data_sync_status',
    'active_integration',
  ],
};
