import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetFnfDismissalDateSchemaContract {
  resignation_date: string | null;
  deactivate_date: string | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    resignation_date: { type: ['string', 'null'], format: 'date' },
    deactivate_date: { type: ['string', 'null'], format: 'date' },
  },
  required: ['resignation_date', 'deactivate_date'],
};
