import { put, StrictEffect, takeLatest } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import { setGenericAppError } from '../../reducers/genericError';
import GetInsuranceApiSchema, {
  GetInsuranceApiSchemaContract,
} from '../../schemas/GetInsuranceApiSchema';
import {
  insuranceFetchFailed,
  insuranceFetchSucceeded,
  ActionTypes,
  IFetchInsurance,
  insuranceFetching,
  IStartInsurance,
  IInsurance,
  IUpdateInsurance,
  IUpdateInsuranceAndRedirect,
  IPurchaseInsurance,
  insurancePurchaseFailed,
  IInsuranceReset,
  resetInsuranceToDefault,
  IInsuranceResetToDefault,
} from '../../reducers/insurance';
import { selectState } from '../sagaUtils';
import { IInsuranceEstimate } from '../../reducers/insuranceEstimate';
import { routePaths } from 'components/Routes/data';
import { fetchSidebar } from '../../reducers/sidebar';

export function* fetchInsuranceSaga(
  action: IFetchInsurance,
): Generator<StrictEffect, void, GetInsuranceApiSchemaContract> {
  try {
    if (action.payload.organizationId === null) {
      return;
    }
    yield put(insuranceFetching());

    const insurance = yield AppSaga.getApi({
      url: `/organization/${action.payload.organizationId}/insurance`,
      responseSchema: GetInsuranceApiSchema,
    });
    yield put(insuranceFetchSucceeded(insurance));
  } catch (e) {
    yield put(insuranceFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

function* startInsurance(
  action: IStartInsurance,
): Generator<StrictEffect, void, GetInsuranceApiSchemaContract | IInsurance> {
  try {
    const currentInsurance = <IInsurance>(yield selectState((state) => state.insurance));

    if (!currentInsurance.data || currentInsurance.data.status === 'NO_ACTIVE_INSURANCE') {
      const insurance = <GetInsuranceApiSchemaContract>(yield AppSaga.postApi({
        url: `/organization/${action.payload.organizationId}/insurance`,
        responseSchema: GetInsuranceApiSchema,
        requestData: {},
      }));
      yield put(insuranceFetchSucceeded(insurance));
    }

    action.payload.history.push(routePaths.insurance.admin.plan);
  } catch (e) {
    yield put(insuranceFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

function* updateInsurance(
  action: IUpdateInsurance,
): Generator<StrictEffect, void, GetInsuranceApiSchemaContract | IInsurance> {
  try {
    const currentInsurance = <IInsurance>(yield selectState((state) => state.insurance));

    if (currentInsurance.data) {
      yield put(insuranceFetchSucceeded({ ...currentInsurance.data, ...action.payload.payload }));
    }

    yield put(insuranceFetching());

    const insurance = <GetInsuranceApiSchemaContract>(yield AppSaga.patchApi({
      url: `/insurance/${action.payload.insuranceId}`,
      responseSchema: GetInsuranceApiSchema,
      requestData: action.payload.payload,
    }));

    if (action.payload.payload.status === 'DATA_COLLECTION_IN_PROGRESS') {
      yield put(fetchSidebar());
    }

    yield put(insuranceFetchSucceeded(insurance));
  } catch (e) {
    yield put(insuranceFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

function* updateInsuranceAndRedirect(
  action: IUpdateInsuranceAndRedirect,
): Generator<StrictEffect, void, GetInsuranceApiSchemaContract | IInsurance> {
  try {
    yield* updateInsurance({
      type: ActionTypes.INSURANCE_UPDATE,
      payload: { payload: action.payload.payload, insuranceId: action.payload.insuranceId },
    });

    action.payload.history.push(action.payload.route);
  } catch (e) {
    yield put(insuranceFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

function* purchaseInsurance(
  action: IPurchaseInsurance,
): Generator<StrictEffect, void, GetInsuranceApiSchemaContract | IInsurance> {
  try {
    const insuranceEstimate = <IInsuranceEstimate>(
      yield selectState((state) => state.insuranceEstimate)
    );

    if (
      !insuranceEstimate.data ||
      insuranceEstimate.data.totalMissingEmployeeDetails > 0 ||
      insuranceEstimate.data.exactEstimate.estimatedForEmployeeIds.length !==
        insuranceEstimate.data.totalEligibleEmployees
    ) {
      console.log('There are still some missing employee details');
      return;
    }

    yield put(insuranceFetching());

    const insurance = <GetInsuranceApiSchemaContract>(yield AppSaga.postApi({
      url: `/insurance/${action.payload.insuranceId}/purchase`,
      responseSchema: GetInsuranceApiSchema,
      requestData: {
        totalAmount: insuranceEstimate.data.exactEstimate.totalAmount,
      },
    }));

    yield put(insuranceFetchSucceeded(insurance));
  } catch (e) {
    yield put(insurancePurchaseFailed(getAppErrorDetails(e)));
  }
}

function* insuranceReset(
  action: IInsuranceReset,
): Generator<StrictEffect, void, IInsuranceResetToDefault> {
  try {
    yield put(resetInsuranceToDefault());
  } catch (e) {
    yield put(insurancePurchaseFailed(getAppErrorDetails(e)));
  }
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_INSURANCE, fetchInsuranceSaga);
  yield takeLatest(ActionTypes.INSURANCE_START, startInsurance);
  yield takeLatest(ActionTypes.INSURANCE_UPDATE, updateInsurance);
  yield takeLatest(ActionTypes.INSURANCE_UPDATE_AND_REDIRECT, updateInsuranceAndRedirect);
  yield takeLatest(ActionTypes.PURCHASE_INSURANCE, purchaseInsurance);
  yield takeLatest(ActionTypes.INSURANCE_RESET, insuranceReset);
}
