import React, { ReactNode } from 'react';
import ModalContext from './context';
import Modal from './components/Modal';
import { ModalProviderStateType, ModalProviderProps } from './types';

import styles from './ModalProvider.module.scss';

class ModalProvider extends React.Component<ModalProviderProps, ModalProviderStateType> {
  constructor(props: ModalProviderProps) {
    super(props);
    this.state = {
      modals: [],
      openModal: this.openModal,
      closeModal: this.closeModal,
    };
  }

  closeModal = () => {
    this.setState((prevState) => ({
      modals: prevState.modals.slice(0, -1),
    }));
  };

  openModal = (component: ReactNode, shouldCloseOnOutsideClick = false) => {
    const id = Date.now();
    this.setState((prevState) => ({
      modals: [...prevState.modals, { id, component, shouldCloseOnOutsideClick }],
    }));
  };

  render() {
    const { children } = this.props;

    return (
      <ModalContext.Provider value={this.state}>
        {this.state.modals.length > 0 && (
          <>
            {this.state.modals.map((modal) => {
              return (
                <div key={modal.id} className={styles.ModalParent}>
                  <Modal key={modal.id} shouldCloseOnOutsideClick={modal.shouldCloseOnOutsideClick}>
                    {modal.component}
                  </Modal>
                </div>
              );
            })}
          </>
        )}
        {children}
      </ModalContext.Provider>
    );
  }
}

export default ModalProvider;
