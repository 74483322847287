import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type VarianceReportSchemaContract = {
  payroll_month: string;
  data: {
    label?: string;
    value?: number;
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}[];

export default <JSONSchema4>{
  type: 'array',
  items: {
    type: 'object',
    properties: {
      payroll_month: { type: 'string' },
      data: {
        type: ['array'],
        items: {
          type: ['object'],
          properties: { label: { type: 'string' }, value: { type: 'number' } },
        },
      },
    },
    required: ['payroll_month', 'data'],
  },
};
