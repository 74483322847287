import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface LoanCalculatorResponseSchemaContract {
  principal: number;
  tenure: number;
  perk_rate: number | null;
  interest_rate: number | null;
  total_interest: number;
  total_amount: number;
  amount_paid: number;
  remaining_amount_to_be_paid: number;
  duration_completed: number;
  remaining_duration: number;
  monthly_total_emi: number;
  monthly_interest_emi: number;
  monthly_principal_emi: number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    principal: { type: ['number'] },
    tenure: { type: ['integer'] },
    perk_rate: { type: ['number', 'null'], maximum: 100, minimum: 0 },
    interest_rate: { type: ['number', 'null'], maximum: 100, minimum: 0 },
    total_interest: { type: ['number'] },
    total_amount: { type: ['number'] },
    amount_paid: { type: ['number'] },
    remaining_amount_to_be_paid: { type: ['number'] },
    duration_completed: { type: ['integer'] },
    remaining_duration: { type: ['integer'] },
    monthly_total_emi: { type: ['number'] },
    monthly_interest_emi: { type: ['number'] },
    monthly_principal_emi: { type: ['number'] },
  },
  required: [
    'principal',
    'tenure',
    'perk_rate',
    'interest_rate',
    'total_interest',
    'total_amount',
    'amount_paid',
    'remaining_amount_to_be_paid',
    'duration_completed',
    'remaining_duration',
    'monthly_total_emi',
    'monthly_interest_emi',
    'monthly_principal_emi',
  ],
};
