import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import { loggedInUser } from './loggedInUser/reducers';
import { genericError } from './genericError';
import { insurance } from './insurance';
import { insuranceEstimate } from './insuranceEstimate';
import { insuranceEmployees } from './insuranceEmployees';
import { insuranceEmployee } from './insuranceEmployee';
import { insuranceEndorsements } from './insuranceEndorsements';
import { sidebar } from './sidebar';
import { notification } from './notifications';

const sagaMiddleware = createSagaMiddleware();

// import { createBrowserHistory } from 'history'

// export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  // router: connectRouter(history),
  loggedInUser,
  genericError,
  insurance,
  insuranceEstimate,
  insuranceEmployees,
  insuranceEmployee,
  insuranceEndorsements,
  sidebar,
  notification,
});

export type ReduxState = ReturnType<typeof rootReducer>;

const composeEnhancers =
  ((window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      // routerMiddleware(history)
    ),
  ),
);

sagaMiddleware.run(rootSaga);
