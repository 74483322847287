import { getApi, patchApi } from 'utils/Api';
import FetchUserDataSchema, { FetchUserDataSchemaContract } from '../schemas/FetchUserDataSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';

type PeopleSearchRequest = {
  orgId: number;
  search: string;
  onlyActive?: boolean;
  type?: string;
  userRoleBlacklist?: number[];
};

export type PeopleSearchSuccessResponseSchemaContract = {
  label: string;
  key: number;
  title: null | string;
  role: null | string;
}[];

const PeopleSearchSuccessResponseSchema = {
  type: 'array',
  items: {
    type: ['object'],
    properties: {
      key: { type: 'number' },
      label: { type: 'string' },
      title: { type: ['null', 'string'] },
      role: { type: ['null', 'string'] },
    },
    required: ['key', 'label'],
  },
};

export type SaveUserUiPreferenceRequestData = {
  [key: string]: any;
};

const people = {
  search: (requestData: PeopleSearchRequest) => {
    const queryParams = new URLSearchParams();

    requestData.search && queryParams.append('search', requestData.search);
    requestData.onlyActive && queryParams.append('onlyActive', requestData.onlyActive.toString());
    requestData.type && queryParams.append('type', requestData.type);
    
    requestData.userRoleBlacklist &&  requestData.userRoleBlacklist.map((param : number) => {
      queryParams.append('userRoleBlacklist[]',param.toString());
    });
// filter out these User Roles

    return getApi<PeopleSearchSuccessResponseSchemaContract>({
      url: `/${requestData.orgId}/getAutocompleteList?${queryParams.toString()}`,
      responseSchema: PeopleSearchSuccessResponseSchema,
    });
  },

  fetchUserData: (peopleId: number) => {
    return getApi<FetchUserDataSchemaContract>({
      url: `/user/${peopleId}`,
      responseSchema: FetchUserDataSchema,
    });
  },
  saveUserUiPreference: (requestData: SaveUserUiPreferenceRequestData) =>
    patchApi<SuccessResponseSchemaContract, any>({
      url: 'ui-user-preferences',
      responseSchema: SuccessResponseSchema,
      requestData,
    }),
};

export default people;
