import { useHistory } from 'react-router-dom';
import { GetAllEndorsementsContract } from '../../schemas/GetAllEndorsements';
import { GetOrgEmployeesForInsuranceSchemaContract } from '../../schemas/GetOrgEmployeesForInsuranceSchema';
import { PurchaseEndorsementsSchemaContract } from '../../schemas/PurchaseEndorsementsSchema';
import { IAppErrorDetails } from '../../utils/AppError';
import { isEqual } from '../../utils/Comparator';
import shallowCompareObjects from '../../utils/shallowCompareObjects';

export interface IInsuranceEndorsements {
  hasError: boolean;
  data: GetAllEndorsementsContract;
  errorData: IAppErrorDetails | null;
  isFetching: boolean;
  fetchedFor: IInsuranceEndorsementFetchOptions;
  isNeverFetched: boolean;
  isPurchased: boolean;
  fetchingFresh: boolean;
  purchaseErrorData: IAppErrorDetails | null;
  fetchingSilently: boolean;
}
export type InsuranceEndorsementEmployeeObject = GetAllEndorsementsContract['employees'][number];
export type InsuranceEndorsementDependentObject =
  InsuranceEndorsementEmployeeObject['dependents'][number];
export interface IInsuranceEndorsementFetchOptions {
  includes?: PurchaseEndorsementsSchemaContract['includes'];
  filters?: Array<'MISSING_DETAILS' | 'DATA_COLLECTED'>;
  limit?: number;
  offset?: number;
  fetchFresh: boolean;
  search?: string | null;
  insuranceId: number | null;
}

export enum ActionTypes {
  INSURANCE_ENDORSEMENTS_FETCH_SUCCEEDED = 'INSURANCE_ENDORSEMENTS_FETCH_SUCCEEDED',
  INSURANCE_ENDORSEMENTS_FETCH_FAILED = 'INSURANCE_ENDORSEMENTS_FETCH_FAILED',
  FETCH_INSURANCE_ENDORSEMENTS = 'FETCH_INSURANCE_ENDORSEMENTS',
  FETCH_NEXT_INSURANCE_ENDORSEMENTS = 'FETCH_NEXT_INSURANCE_ENDORSEMENTS',
  INSURANCE_ENDORSEMENTS_FETCHING = 'INSURANCE_ENDORSEMENTS_FETCHING',
  PURCHASING_ENDORSEMENTS = 'PURCHASING_ENDORSEMENTS',
  PURCHASE_ENDORSEMENTS = 'PURCHASE_ENDORSEMENTS',
  PURCHASE_ENDORSEMENTS_SUCCEEDED = 'PURCHASE_ENDORSEMENTS_SUCCEEDED',
  PURCHASE_ENDORSEMENTS_FAILED = 'PURCHASE_ENDORSEMENTS_FAILED',
}

const initialState: IInsuranceEndorsements = {
  hasError: false,
  data: {
    summary: {
      premiumBreakup: {
        newEmployees: {},
        newDependents: {},
        newEmployeesWithDependents: {},
      },
      dependentIdsWhoseDetailsAreUpdated: [],
      dismissedDependentIds: [],
      dismissedEmployeeIds: [],
      employeeIdsWhoseDetailsAreUpdated: [],
      newDependentIdsForExistingEmployeeIds: [],
      newDependentsForNewEmployeeIds: [],
      newEmployeeIdsWithDependents: [],
      employeeIdsWhoseDetailsAreMissing: [],
      totalMissingEmployeeDetails: 0,
      amount: 0,
      gst: 0,
      totalAmount: 0,
    },
    policyRestrictionsForEndorsements: {
      hasError: false,
      issues: [],
    },
    currentOrganizationBalance: 0,
    total: 0,
    employees: [],
  },
  fetchedFor: {
    includes: ['INCLUDE_NEW_EMPLOYEE_ADDITIONS'],
    filters: [],
    limit: 10,
    offset: 0,
    search: null,
    fetchFresh: true,
    insuranceId: null,
  },
  isNeverFetched: true,
  isPurchased: false,
  errorData: null,
  isFetching: false,
  fetchingFresh: false,
  purchaseErrorData: null,
  fetchingSilently: false,
};

type Actions =
  | IInsuranceEndorsementsFetchSucceeded
  | IInsuranceEndorsementsFetchFailed
  | IInsuranceEndorsementsFetching
  | IPurchaseInsuranceEndorsementSucceeded
  | IPurchaseInsuranceEndorsementFailed
  | IPurchasingInsuranceEndorsement;

export function insuranceEndorsements(
  state = initialState,
  action: Actions,
): IInsuranceEndorsements {
  switch (action.type) {
    case ActionTypes.INSURANCE_ENDORSEMENTS_FETCHING:
      let fetchingSilently = false;
      if (!state.isFetching && shallowCompareObjects(state.fetchedFor, action.payload)) {
        fetchingSilently = true;
      }
      return {
        ...state,
        fetchedFor: action.payload,
        isFetching: true,
        isNeverFetched: false,
        fetchingFresh: action.payload.fetchFresh === false ? false : true,
        fetchingSilently,
      };
    case ActionTypes.INSURANCE_ENDORSEMENTS_FETCH_SUCCEEDED:
      if (!action.payload.isFresh) {
        return {
          ...state,
          data: {
            policyRestrictionsForEndorsements:
              action.payload.fetchedData.policyRestrictionsForEndorsements,
            total: action.payload.fetchedData.total,
            summary: action.payload.fetchedData.summary,
            currentOrganizationBalance: action.payload.fetchedData.currentOrganizationBalance,
            employees: [...state.data.employees, ...action.payload.fetchedData.employees],
          },
          hasError: false,
          errorData: null,
          isFetching: false,
          isNeverFetched: false,
          fetchingSilently: false,
        };
      } else {
        return {
          ...state,
          data: {
            policyRestrictionsForEndorsements:
              action.payload.fetchedData.policyRestrictionsForEndorsements,
            total: action.payload.fetchedData.total,
            summary: action.payload.fetchedData.summary,
            currentOrganizationBalance: action.payload.fetchedData.currentOrganizationBalance,
            employees: action.payload.fetchedData.employees,
          },
          hasError: false,
          errorData: null,
          isFetching: false,
          isNeverFetched: false,
          fetchingSilently: false,
        };
      }
    case ActionTypes.INSURANCE_ENDORSEMENTS_FETCH_FAILED:
      return {
        ...state,
        hasError: true,
        errorData: action.payload,
        isFetching: false,
        isNeverFetched: false,
        fetchingSilently: false,
      };
    case ActionTypes.PURCHASE_ENDORSEMENTS_SUCCEEDED:
      return { ...state, isPurchased: true, purchaseErrorData: null };
    case ActionTypes.PURCHASING_ENDORSEMENTS:
      return { ...state, isPurchased: false, purchaseErrorData: null };
    case ActionTypes.PURCHASE_ENDORSEMENTS_FAILED:
      return { ...state, purchaseErrorData: action.payload };
    default:
      return state;
  }
}

/** Mark fetch succeeded */
export interface IInsuranceEndorsementsFetchSucceeded {
  type: typeof ActionTypes.INSURANCE_ENDORSEMENTS_FETCH_SUCCEEDED;
  payload: {
    fetchedData: GetAllEndorsementsContract;
    isFresh: boolean;
  };
}

export const insuranceEndorsementsFetchSucceeded = (
  fetchedData: GetAllEndorsementsContract,
  isFresh: boolean,
): IInsuranceEndorsementsFetchSucceeded => ({
  type: ActionTypes.INSURANCE_ENDORSEMENTS_FETCH_SUCCEEDED,
  payload: {
    fetchedData,
    isFresh,
  },
});

/** Mark the fetch as failed */
export interface IInsuranceEndorsementsFetchFailed {
  type: typeof ActionTypes.INSURANCE_ENDORSEMENTS_FETCH_FAILED;
  payload: IAppErrorDetails;
}

export const insuranceEndorsementsFetchFailed = (
  payload: IAppErrorDetails,
): IInsuranceEndorsementsFetchFailed => ({
  type: ActionTypes.INSURANCE_ENDORSEMENTS_FETCH_FAILED,
  payload,
});

/** Saga event to fetch the endorsement */
export interface IFetchInsuranceEndorsements {
  type: typeof ActionTypes.FETCH_INSURANCE_ENDORSEMENTS;
  payload: IInsuranceEndorsementFetchOptions;
}

export const fetchInsuranceEndorsements = (
  fetchOptions: IInsuranceEndorsementFetchOptions,
): IFetchInsuranceEndorsements => ({
  type: ActionTypes.FETCH_INSURANCE_ENDORSEMENTS,
  payload: fetchOptions,
});

/** when next page is planned to fetch */
export interface IFetchNextInsuranceEndorsements {
  type: typeof ActionTypes.FETCH_NEXT_INSURANCE_ENDORSEMENTS;
}

export const fetchNextInsuranceEndorsements = (): IFetchNextInsuranceEndorsements => ({
  type: ActionTypes.FETCH_NEXT_INSURANCE_ENDORSEMENTS,
});

/** Update the status that endorsement fetch has started */
export interface IInsuranceEndorsementsFetching {
  type: typeof ActionTypes.INSURANCE_ENDORSEMENTS_FETCHING;
  payload: IInsuranceEndorsementFetchOptions;
}

export const insuranceEndorsementsFetching = (
  fetchOptions: IInsuranceEndorsementFetchOptions,
): IInsuranceEndorsementsFetching => ({
  type: ActionTypes.INSURANCE_ENDORSEMENTS_FETCHING,
  payload: fetchOptions,
});

/** purchase endorsement */

export interface IPurchaseInsuranceEndorsements {
  type: typeof ActionTypes.PURCHASE_ENDORSEMENTS;
}

export const purchaseEndorsement = (): IPurchaseInsuranceEndorsements => ({
  type: ActionTypes.PURCHASE_ENDORSEMENTS,
});

/** purchasing endorsements */

export interface IPurchasingInsuranceEndorsement {
  type: typeof ActionTypes.PURCHASING_ENDORSEMENTS;
}

export const purchasingEndorsements = (): IPurchasingInsuranceEndorsement => ({
  type: ActionTypes.PURCHASING_ENDORSEMENTS,
});

/** purchase endorsement */

export interface IPurchaseInsuranceEndorsementSucceeded {
  type: typeof ActionTypes.PURCHASE_ENDORSEMENTS_SUCCEEDED;
}

export const purchaseEndorsementSucceeded = (): IPurchaseInsuranceEndorsementSucceeded => ({
  type: ActionTypes.PURCHASE_ENDORSEMENTS_SUCCEEDED,
});

/** purchase endorsement */

export interface IPurchaseInsuranceEndorsementFailed {
  type: typeof ActionTypes.PURCHASE_ENDORSEMENTS_FAILED;
  payload: IAppErrorDetails;
}

export const purchaseEndorsementFailed = (
  errorPayload: IAppErrorDetails,
): IPurchaseInsuranceEndorsementFailed => ({
  type: ActionTypes.PURCHASE_ENDORSEMENTS_FAILED,
  payload: errorPayload,
});
