import { postApi, getApi } from 'utils/Api';
import SlackUserActivationSchema, {
  SlackUserActivationSchemaContract,
} from 'schemas/SlackUserActivationSchema';
import SlackUserDeactivationSchema, {
  SlackUserDeactivationSchemaContract,
} from 'schemas/SlackUserDeactivationSchema';
import SlackOauthMapping, { SlackOauthMappingContract } from 'schemas/SlackOauthMapping';
import SlackOauthUninstallSchema, {
  SlackOauthUninstallSchemaContract,
} from 'schemas/SlackOauthUninstallSchema';
import SlackUserActivationValidationSchema, {
  SlackUserActivationValidationSchemaContract,
} from 'schemas/SlackUserActivationValidationSchema';

type ActivationRequest = {
  token: string;
};

type DeactivationRequest = {};

type AuthorizationRequest = {
  code: string;
  state_param: string;
};

type UninstallRequest = {};

type ActivationValidationRequest = {
  token: string;
};

export default {
  fetchActivationStatus: () => {
    return getApi<SlackUserActivationSchemaContract>({
      url: '/slack-user',
      responseSchema: SlackUserActivationSchema,
    });
  },
  activate: (token: string) => {
    return postApi<SlackUserActivationSchemaContract, ActivationRequest>({
      url: '/slack-user/activate',
      responseSchema: SlackUserActivationSchema,
      requestData: {
        token,
      },
    });
  },
  deactivate: () => {
    return postApi<SlackUserDeactivationSchemaContract, DeactivationRequest>({
      url: '/slack-user/deactivate',
      responseSchema: SlackUserDeactivationSchema,
    });
  },
  getOauthMapping: () => {
    return getApi<SlackOauthMappingContract>({
      url: '/slack-settings/oauth-mapping',
      responseSchema: SlackOauthMapping,
    });
  },
  authorize: ({ code, state }: { code: string; state: string }) => {
    return postApi<SlackOauthMappingContract, AuthorizationRequest>({
      url: '/slack-settings/authorize-install',
      responseSchema: SlackOauthMapping,
      requestData: {
        code,
        state_param: state,
      },
    });
  },
  uninstall: () => {
    return postApi<SlackOauthUninstallSchemaContract, UninstallRequest>({
      url: '/slack-settings/uninstall',
      responseSchema: SlackOauthUninstallSchema,
      requestData: {},
    });
  },
  validateActivationToken: (token: string) => {
    return getApi<SlackUserActivationValidationSchemaContract>({
      url: `/slack-user/validate-activation?token=${token}`,
      responseSchema: SlackUserActivationValidationSchema,
    });
  },
};
