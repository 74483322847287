import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetOrgFlexiPartnerDetailsSchemaContract {
  isOpted: boolean;
  optedOn: null | string;
  walletBudgets: {
    wallet: {
      id: number;
      name: string;
      partner_id: number;
      partner_details: null | {
        walletId: string;
        productCode: null | string;
        basicPartnerDetails: null | {
          [k: string]: unknown;
        };
        [k: string]: unknown;
      };
      is_approved: boolean;
      compliance: {
        type: string;
        caseWiseRules:
          | []
          | [
              {
                min: number;
                max: number;
                case: string;
                [k: string]: unknown;
              },
            ];
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
    id: number;
    maxCompliance: {
      case: null | string;
      min: null | number;
      max: null | number;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  }[];
  enabledWallets: string[];
  totalAmountAllocated: number;
  deliveryType: 1 | 2;
  cardDeliveryDetails: {
    address: null | string;
    city: null | string;
    state: null | string;
    pinCode: null | string;
    fullName: null | string;
    phoneNumber: null | string;
    [k: string]: unknown;
  };
  flexiBenefitComponentName?: null | string;
  kycData: {
    status: number;
    documents: {
      gstin: {
        gstinNumber: null | string;
        file: null | string;
        comment: null | string;
        isApproved: null | boolean;
        [k: string]: unknown;
      };
      cancelledCheque: {
        file: null | string;
        comment: null | string;
        isApproved: null | boolean;
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  requiredKYCFields: {
    requireGSTIN: boolean;
    requireCancelledCheque: boolean;
    [k: string]: unknown;
  };
  enableForAllEmployees: boolean;
  restrictions: string[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    isOpted: { type: 'boolean' },
    optedOn: { type: ['null', 'string'], format: 'date' },
    walletBudgets: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          wallet: {
            type: 'object',
            properties: {
              id: { type: 'integer' },
              name: { type: 'string' },
              partner_id: { type: 'integer' },
              partner_details: {
                type: ['null', 'object'],
                properties: {
                  walletId: { type: 'string' },
                  productCode: { type: ['null', 'string'] },
                  basicPartnerDetails: { type: ['null', 'object'] },
                },
                required: ['walletId', 'productCode', 'basicPartnerDetails'],
              },
              is_approved: { type: 'boolean' },
              compliance: {
                type: 'object',
                properties: {
                  type: { type: 'string' },
                  caseWiseRules: {
                    type: 'array',
                    items: [
                      {
                        type: 'object',
                        properties: {
                          min: { type: 'integer' },
                          max: { type: 'integer' },
                          case: { type: 'string' },
                        },
                        required: ['min', 'max', 'case'],
                      },
                    ],
                  },
                },
                required: ['type', 'caseWiseRules'],
              },
            },
            required: ['id', 'name', 'partner_id', 'partner_details', 'is_approved', 'compliance'],
          },
          id: { type: 'integer' },
          maxCompliance: {
            type: 'object',
            properties: {
              case: { type: ['null', 'string'] },
              min: { type: ['null', 'integer'] },
              max: { type: ['null', 'integer'] },
            },
            required: ['case', 'min', 'max'],
          },
        },
        required: ['wallet', 'id', 'maxCompliance'],
      },
    },
    enabledWallets: { type: 'array', items: { type: 'string' } },
    totalAmountAllocated: { type: 'number' },
    deliveryType: { type: 'integer', enum: [1, 2] },
    cardDeliveryDetails: {
      type: 'object',
      properties: {
        address: { type: ['null', 'string'] },
        city: { type: ['null', 'string'] },
        state: { type: ['null', 'string'] },
        pinCode: { type: ['null', 'string'] },
        fullName: { type: ['null', 'string'] },
        phoneNumber: { type: ['null', 'string'] },
      },
      required: ['address', 'city', 'state', 'pinCode', 'fullName', 'phoneNumber'],
    },
    flexiBenefitComponentName: { type: ['null', 'string'] },
    kycData: {
      type: 'object',
      properties: {
        status: { type: ['integer'] },
        documents: {
          type: 'object',
          properties: {
            gstin: {
              type: 'object',
              properties: {
                gstinNumber: { type: ['null', 'string'] },
                file: { type: ['null', 'string'] },
                comment: { type: ['null', 'string'] },
                isApproved: { type: ['null', 'boolean'] },
              },
              required: ['gstinNumber', 'file', 'comment', 'isApproved'],
            },
            cancelledCheque: {
              type: 'object',
              properties: {
                file: { type: ['null', 'string'] },
                comment: { type: ['null', 'string'] },
                isApproved: { type: ['null', 'boolean'] },
              },
              required: ['file', 'comment', 'isApproved'],
            },
          },
          required: ['gstin', 'cancelledCheque'],
        },
      },
      required: ['status', 'documents'],
    },
    requiredKYCFields: {
      type: 'object',
      properties: {
        requireGSTIN: { type: ['boolean'] },
        requireCancelledCheque: { type: ['boolean'] },
      },
      required: ['requireGSTIN', 'requireCancelledCheque'],
    },
    enableForAllEmployees: { type: 'boolean' },
    restrictions: { type: ['array'], items: { type: 'string' } },
  },
  required: [
    'isOpted',
    'optedOn',
    'walletBudgets',
    'enabledWallets',
    'totalAmountAllocated',
    'deliveryType',
    'cardDeliveryDetails',
    'kycData',
    'requiredKYCFields',
    'enableForAllEmployees',
    'restrictions',
  ],
};
