import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type RxMerchantsContract =
  | []
  | [
      {
        gstin: string | null;
        pan: string | null;
        billing_address: {
          line1: string | null;
          line2: string | null;
          city: string | null;
          state: string | null;
          country: string | null;
          zipcode: string | null;
          [k: string]: unknown;
        };
        description: string | null;
        iec_code: string | null;
        id: string;
        activated: boolean;
        website: string | null;
        name: string | null;
        billing_label: string | null;
        is_business_banking_enabled: boolean;
        role: string | null;
        product: string | null;
        [k: string]: unknown;
      },
    ];

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'array',
  items: [
    {
      type: 'object',
      properties: {
        gstin: { type: ['string', 'null'] },
        pan: { type: ['string', 'null'] },
        billing_address: {
          type: 'object',
          properties: {
            line1: { type: ['string', 'null'] },
            line2: { type: ['string', 'null'] },
            city: { type: ['string', 'null'] },
            state: { type: ['string', 'null'] },
            country: { type: ['string', 'null'] },
            zipcode: { type: ['string', 'null'] },
          },
          required: ['line1', 'line2', 'city', 'state', 'country', 'zipcode'],
        },
        description: { type: ['string', 'null'] },
        iec_code: { type: ['string', 'null'] },
        id: { type: 'string' },
        activated: { type: 'boolean' },
        website: { type: ['string', 'null'] },
        name: { type: ['string', 'null'] },
        billing_label: { type: ['string', 'null'] },
        is_business_banking_enabled: { type: 'boolean' },
        role: { type: ['string', 'null'] },
        product: { type: ['string', 'null'] },
      },
      required: [
        'gstin',
        'pan',
        'billing_address',
        'description',
        'iec_code',
        'id',
        'activated',
        'website',
        'name',
        'billing_label',
        'is_business_banking_enabled',
        'role',
        'product',
      ],
    },
  ],
};
