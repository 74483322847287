import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface JibbleAttendanceSettingsSchemaContract {
  success: boolean;
  integrationConfig: {
    halfDayThreshold: number;
    fullDayThreshold: number;
    calculateLopOnWorkingDays: boolean;
    showAttendanceInPayslip: boolean;
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: ['object'],
  properties: {
    success: { type: 'boolean' },
    integrationConfig: {
      type: 'object',
      properties: {
        halfDayThreshold: { type: 'number' },
        fullDayThreshold: { type: 'number' },
        calculateLopOnWorkingDays: { type: 'boolean' },
        showAttendanceInPayslip: { type: 'boolean' },
      },
      required: [
        'halfDayThreshold',
        'fullDayThreshold',
        'calculateLopOnWorkingDays',
        'showAttendanceInPayslip',
      ],
    },
  },
  required: ['success', 'integrationConfig'],
};
