import BulkUploadDetailsSchema, { BulkUploadDetailsSchemaContract } from '../schemas/BulkUploadDetailsSchema';
import { postApi, getApi } from '../utils/Api';
import { BulkUploadGetByTypeRequestContract } from '../schemas/BulkUploadGetByTypeRequest';
import { BulkUploadTypes } from 'constants/bulkUpload';
import BulkUploadStagingDetailsSchema, { BulkUploadStagingDetailsSchemaContract } from '../schemas/BulkUploadStagingDetailsSchema';



export default {
    sendFile : (requestData: FormData) => {
        return postApi<BulkUploadDetailsSchemaContract,FormData>({
            url:`/bulk-uploads/upload-file`,
            responseSchema: BulkUploadDetailsSchema,
            requestData
        },'multipart')
    },

    fetchBulkUploadRecord : (bulkUploadId: number) => {
        return getApi<BulkUploadDetailsSchemaContract>({
            url:`/bulk-uploads/${bulkUploadId}`,
            responseSchema: BulkUploadDetailsSchema
        })
    },

    fetchBulkUploadRecordByType : (bulkUploadType: BulkUploadTypes) => {
    return postApi<BulkUploadDetailsSchemaContract, BulkUploadGetByTypeRequestContract>({
        url:`/bulk-uploads/get-by-type`,
        responseSchema: BulkUploadDetailsSchema,
        requestData: {
            type: bulkUploadType,
          },
    })
    },
    
    fetchBulkUploadStagingRecord : (bulkUploadId: number, page:number,rowLimit:number) => {
        return getApi<BulkUploadStagingDetailsSchemaContract>({
            url:`/bulk-uploads/${bulkUploadId}/execution-preview?page=${page}&page_size=${rowLimit}`,
            responseSchema: BulkUploadStagingDetailsSchema
        })
        },

    sendExecuteRequest : (bulkUploadId: number) => {
        return postApi<BulkUploadDetailsSchemaContract,{}>({
            url:`/bulk-uploads/${bulkUploadId}/execute`,
            responseSchema: BulkUploadDetailsSchema
        })
    },

    cancelBulkUpload : (bulkUploadId: number) => {
        return postApi<BulkUploadDetailsSchemaContract,{}>({
            url:`/bulk-uploads/${bulkUploadId}/cancel`,
            responseSchema: BulkUploadDetailsSchema
        })
    }
    
}
