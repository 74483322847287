import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetHrmsEmployeeTypesFromKnitContract {
  success: boolean;
  data: {
    fields: {
      id: string | null;
      label: string;
      [k: string]: unknown;
    }[];
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    success: { type: 'boolean' },
    data: {
      type: 'object',
      properties: {
        fields: {
          type: 'array',
          items: {
            type: 'object',
            properties: { id: { type: ['string', 'null'] }, label: { type: 'string' } },
            required: ['id', 'label'],
          },
        },
      },
      required: ['fields'],
    },
  },
  required: ['success', 'data'],
};
