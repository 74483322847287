import GetPayrollSetupSchema, { GetPayrollSetupSchemaContract } from '../schemas/GetPayrollSetupSchema';
import { getApi, postApi, deleteApi } from '../utils/Api';
import { UpdatePayrollSetupSchemaContract } from '../schemas/UpdatePayrollSetupSchema';
import SuccessResponseSchema, { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';

const apis = {
    getSettings: () => {

        return getApi<GetPayrollSetupSchemaContract>({
            url: '/payroll-setup',
            responseSchema: GetPayrollSetupSchema,
        })

    },

    updateSettings: (requestData: UpdatePayrollSetupSchemaContract) => {

        return postApi<GetPayrollSetupSchemaContract, UpdatePayrollSetupSchemaContract>({
            url: '/payroll-setup',
            requestData: requestData,
            responseSchema: GetPayrollSetupSchema
        })

    },

    deleteFutureCycle: () => {

        return deleteApi<null, SuccessResponseSchemaContract>({
            url: '/payroll-cycles/delete-upcoming-cycles',
            responseSchema: SuccessResponseSchema
        })
    }
}

export default apis;