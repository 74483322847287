import React, { ChangeEvent, FormEvent, PropsWithChildren, forwardRef } from 'react';
import styles from './index.module.scss';

export const Input = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string;
    customErrorMessage?: string;
  }
>(function InputWithRef({ className, customErrorMessage, ...props }, ref) {
  const handleCustomValidation = (e: FormEvent<HTMLInputElement>) => {
    if (customErrorMessage) {
      (e.target as HTMLInputElement).setCustomValidity(customErrorMessage);
    }
    props.onInvalid && props.onInvalid(e);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.setCustomValidity('');
    props.onChange && props.onChange(e);
  };

  return (
    <input
      onWheel={props.type === 'number' ? (e) => e.currentTarget.blur() : undefined}
      {...props}
      ref={ref}
      onInvalid={handleCustomValidation}
      onChange={handleChange}
      className={`
      ${styles['Input']} ${className ? className : ''} border border-primary p-3 rounded`}
    />
  );
});

export function InputElement({
  label,
  className,
  children,
  required = false,
}: PropsWithChildren<{
  label?: React.ReactNode;
  className?: string;
  required?: boolean;
}>) {
  return (
    <div className={`${className} flex flex-col w-full`}>
      <label className={`text-label ${required ? 'required' : ''}`}>{label}</label>
      {children}
    </div>
  );
}

export function CheckBox({
  className = '',
  labelClassName = '',
  label,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  label?: React.ReactNode;
  labelClassName?: string;
}) {
  return (
    <span className={`inline-block ${className}`}>
      <input
        {...props}
        type="checkbox"
        className={`
        ${styles['CheckBoxInput']} border border-primary p-3 rounded`}
      />
      <label htmlFor={props.id} className={'text-label !text-100'+labelClassName}>
        {label}
      </label>
    </span>
  );
}
