import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetComputedComplianceArrearsSchemaContract {
  compliance_arrears:
    | {
        name: string;
        type: string;
        amount: number;
        [k: string]: unknown;
      }[]
    | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    compliance_arrears: {
      type: ['array', 'null'],
      items: {
        type: ['object'],
        properties: {
          name: { type: 'string' },
          type: { type: 'string' },
          amount: { type: 'number' },
        },
        required: ['name', 'amount', 'type'],
      },
    },
  },
  required: ['compliance_arrears'],
};
