import React, { PropsWithChildren } from 'react';

export function SimpleListLoader({
  isLoading,
  className,
  children,
}: PropsWithChildren<{ isLoading?: boolean; className?: string }>) {
  if (isLoading === false) {
    return <></>;
  }
  return (
    <div className={`${className ? className : ''} w-full text-center py-10 font-bold`}>
      {children ? children : 'Loading'} ...
    </div>
  );
}

export function ListLoader({
  isLoading,
  className,
  children,
}: PropsWithChildren<{ isLoading?: boolean; className?: string }>) {
  return (
    <SimpleListLoader
      isLoading={isLoading}
      className={`${className ? className : ''} bg-green-100 rounded-lg`}
      children={children}
    />
  );
}
