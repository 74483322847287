import { getApi } from 'utils/Api';
import VarianceReportSchema, {
  VarianceReportSchemaContract,
} from '../schemas/VarianceReportSchema';

import { format } from 'date-fns';

import reportConstants from '../components/Reports/VarianceReport/constants';

export default {
  fetchData: (attribute: string, timeline: string) => {
    let from = '';
    let to = '';
    const today = new Date();
    switch (timeline) {
      case reportConstants.TIMELINE_3_MONTHS: {
        to = format(today, 'yyyy-MM-dd'); // change library
        let fromMonth = today;

        fromMonth.setMonth(today.getMonth() - 3 + 1);
        from = format(fromMonth, 'yyyy-MM-dd');
        break;
      }
      case reportConstants.TIMELINE_6_MONTHS: {
        to = format(today, 'yyyy-MM-dd');
        let fromMonth = today;
        fromMonth.setMonth(today.getMonth() - 6 + 1);
        from = format(fromMonth, 'yyyy-MM-dd');
        break;
      }

      case reportConstants.TIMELINE_12_MONTHS: {
        to = format(today, 'yyyy-MM-dd');
        let fromMonth = today;
        fromMonth.setMonth(today.getMonth() - 12 + 1);
        from = format(fromMonth, 'yyyy-MM-dd');
        break;
      }

      case reportConstants.TIMELINE_CURRENT_QUARTER: {
        to = format(today, 'yyyy-MM-dd');

        let quarter = Math.floor(today.getMonth() / 3);
        let fromDate = new Date(today.getFullYear(), quarter * 3, 1);
        from = format(fromDate, 'yyyy-MM-dd');
        break;
      }

      case reportConstants.TIMELINE_LAST_QUARTER: {
        let cQuarter = Math.floor(today.getMonth() / 3);
        let fromDate = new Date(today.getFullYear(), cQuarter * 3 - 3, 1);
        from = format(fromDate, 'yyyy-MM-dd');

        let toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 3, 0);
        to = format(toDate, 'yyyy-MM-dd');
        break;
      }

      case reportConstants.TIMELINE_CURRENT_YEAR: {
        to = format(today, 'yyyy-MM-dd');

        let year = today.getMonth() > 3 ? today.getFullYear() : today.getFullYear() - 1;
        let fromDate = new Date(year, 3, 1);
        from = format(fromDate, 'yyyy-MM-dd');
        break;
      }
      case reportConstants.TIMELINE_FY_2019: {
        let startDate = new Date(2019,3,1);
        from = format(startDate, 'yyyy-MM-dd');
        let endDate = new Date(2020,2,31);
        to = format(endDate, 'yyyy-MM-dd');
        break;
      }
      case reportConstants.TIMELINE_FY_2020: {
        let startDate = new Date(2020,3,1);
        from = format(startDate, 'yyyy-MM-dd');
        let endDate = new Date(2021,2,31);
        to = format(endDate, 'yyyy-MM-dd');
        break;
      }
      case reportConstants.TIMELINE_FY_2021: {
        let startDate = new Date(2021,3,1);
        from = format(startDate, 'yyyy-MM-dd');
        let endDate = new Date(2022,2,31);
        to = format(endDate, 'yyyy-MM-dd');
        break;
      }
      case reportConstants.TIMELINE_FY_2022: {
        let startDate = new Date(2022, 3, 1);
        from = format(startDate, 'yyyy-MM-dd');
        let endDate = new Date(2023, 2, 31);
        to = format(endDate, 'yyyy-MM-dd');
        break;
      }
      case reportConstants.TIMELINE_FY_2023: {
        let startDate = new Date(2023, 3, 1);
        from = format(startDate, 'yyyy-MM-dd');
        let endDate = new Date(2024, 2, 31);
        to = format(endDate, 'yyyy-MM-dd');
        break;
      }
    }

    return getApi<VarianceReportSchemaContract>({
      url: `/reports/getVarianceReportAttributesData?from_date=${from}&to_date=${to}&attribute=${attribute}`,
      responseSchema: VarianceReportSchema,
    });
  },
};
