const userRoles = Object.freeze({
  ADMIN: 1,
  HUMAN_RESOURCES: 2,
  EXECUTE_PAYROLL: 3,
  VIEW_REPORTS: 4,
  REIMBURSEMENTS: 5,
  EMPLOYEE: 100,
});

export default userRoles;
