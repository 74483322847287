import {getApi, postApi} from "../utils/Api";
import SuccessResponseSchema, {SuccessResponseSchemaContract} from "../schemas/SuccessResponseSchema";
import {JibbleInititalEmployeeSyncResponseSchemaContract} from "../schemas/JibbleInititalEmployeeSyncResponseSchema";
import JibbleAttendanceSettingsSchema, {JibbleAttendanceSettingsSchemaContract} from "../schemas/JibbleAttendanceSettingsSchema";
import JibbleEmployeesDataSchema, {JibbleEmployeesDataSchemaContract} from "../schemas/JibbleEmployeesDataSchema";
import JibbleAttendanceDataSchema, {JibbleAttendanceDataSchemaContract} from "../schemas/JibbleAttendanceDataSchema";
import JibbleIntegrationStatusSchema, {JibbleIntegrationStatusSchemaContract} from "../schemas/JibbleIntegrationStatusSchema";

type DeactivationRequest = {};
type ActivationRequest = {};

export interface JibbleAccountCreationRequestBody {
    organization_id: number;
    people_id: number;
    people_organization_id: number;
    name: string;
    email: string;
    phoneNumber?: string;
}

export interface BulkJibbleAccountCreationRequest {
    check_for_existing_accounts: boolean;
    employees_to_be_added: JibbleAccountCreationRequestBody[];
    is_onboarding: boolean;
}

export interface SyncJibbleAccountByMemberCodeRequest {
    employeeData: typeof JibbleEmployeesDataSchema['jibble_employee_data']['data'];
    updatedJibbleMemberCode: string;
}

export interface SyncAttendanceDataProps {
    payroll_month: string;
    from_date: string;
    to_date: string;
    page: number,
    rows_limit: number
}

export interface SyncAttendanceDataResponse {
    success: boolean;
    message?: string;
    payroll_month: string;
    from_date: string;
    to_date: string;
}

export interface UpdateLopDaysProps {
    organization_id: number;
    people_id: number;
    payroll_month: string;
    old_lop_days: number;
    new_lop_days: number;
}

export interface GetLopAdditionStatusResponse {
    is_lop_addition_in_progress: boolean;
}

const jibbleSettings =  {
    fetchIntegrationStatus: () => {
        return getApi<JibbleIntegrationStatusSchemaContract>({
            url: '/jibble/integration-status',
            responseSchema: JibbleIntegrationStatusSchema
        });
    },

    activate: (jibbleSecretId: string, jibbleSecret: string) => {
        return postApi<SuccessResponseSchemaContract, ActivationRequest>({
            url: '/jibble/activate',
            responseSchema: SuccessResponseSchema,
            requestData: {
                jibble_secret_id: jibbleSecretId,
                jibble_secret_key: jibbleSecret,
            }
        });
    },

    deactivate: () => {
        return postApi<SuccessResponseSchemaContract, DeactivationRequest>({
            url: '/jibble/deactivate',
            responseSchema: SuccessResponseSchema,
            requestData: {}
        });
    },

    getAttendanceSettings: () => {
        return getApi<JibbleAttendanceSettingsSchemaContract> ({
            url: '/jibble/get-attendance-config',
            responseSchema: JibbleAttendanceSettingsSchema
        });
    },

    updateAttendanceSettings: (requestData: JibbleAttendanceSettingsSchemaContract['integration_config']) => {
        return postApi<SuccessResponseSchemaContract, JibbleAttendanceSettingsSchemaContract['integration_config']> ({
            url: '/jibble/set-attendance-config',
            responseSchema: SuccessResponseSchema,
            requestData: requestData
        });
    },

    xpayrollEmployeesInitialSync: () => {
        return getApi<JibbleInititalEmployeeSyncResponseSchemaContract>({
            url: '/jibble/initial-sync',
            responseSchema: SuccessResponseSchema,
        });
    },

    bulkOnboardEmployeesToJibble: (requestData: BulkJibbleAccountCreationRequest) => {
        return postApi<SuccessResponseSchemaContract, {}> ({
            url: '/jibble/bulk-onboard-employees',
            responseSchema: SuccessResponseSchema,
            requestData: requestData
        });
    },

    getEmployeeData: ({
        searchPhrase,
        accountCreationStatusValues,
        page,
        rowsLimit
    }: {
        searchPhrase: string,
        accountCreationStatusValues: number[],
        page: number,
        rowsLimit: number
    }) => {
        return postApi<JibbleEmployeesDataSchemaContract, {}> ({
            url: '/jibble/get-jibble-people-data',
            responseSchema: JibbleEmployeesDataSchema,
            requestData: {
                search_phrase: searchPhrase,
                account_creation_status: accountCreationStatusValues,
                page: page,
                limit: rowsLimit
            }
        });
    },

    syncJibbleAccountByMemberCode: ({
        employeeData,
        updatedJibbleMemberCode
    }: SyncJibbleAccountByMemberCodeRequest) => {
        return postApi<SuccessResponseSchemaContract, {}> ({
            url: '/jibble/sync-jibble-account-by-member-code',
            responseSchema: SuccessResponseSchema,
            requestData: {
                people_id: employeeData['people_id'],
                people_organization_id: employeeData['people_organization_id'],
                current_jibble_member_code: employeeData['jibble_member_code'] ?? '',
                updated_jibble_member_code: updatedJibbleMemberCode,
                jibble_people_id: employeeData['jibble_people_id'] ?? '',
                email: employeeData['email']
            }
        })
    },

    getSyncedAttendanceData: ({
        payrollMonth,
        page,
        rowsLimit
    }: {
        payrollMonth: string,
        page: number,
        rowsLimit: number
    }) => {
        return postApi<JibbleAttendanceDataSchemaContract, {}> ({
            url: '/jibble/get-jibble-attendance-data',
            responseSchema: JibbleAttendanceDataSchema,
            requestData: {
                payroll_month: payrollMonth,
                page: page,
                limit: rowsLimit
            }
        });
    },

    syncAttendanceData: (requestData: SyncAttendanceDataProps) => {
        return postApi<SyncAttendanceDataResponse, SyncAttendanceDataProps> ({
            url: '/jibble/sync-attendance-from-jibble',
            responseSchema: {},
            requestData:requestData
        });
    },

    updateAttendanceLopDays: (requestData: UpdateLopDaysProps) => {
        return postApi<SuccessResponseSchemaContract, {}>({
            url: '/jibble/update-attendance-lop',
            responseSchema: SuccessResponseSchema,
            requestData: requestData
        })
    },

    addLopDeductions: ({attendanceIds}: {attendanceIds: number[]}) => {
        return postApi<SuccessResponseSchemaContract, {}>({
            url: '/jibble/add-lop-deductions',
            responseSchema: SuccessResponseSchema,
            requestData: {
                attendanceIds: attendanceIds
            }
        })
    },

    getLopAdditionStatus: () => {
        return getApi<GetLopAdditionStatusResponse>({
            url: '/jibble/get-lop-addition-status',
            responseSchema: {},
        });
    },
};

export default jibbleSettings;