import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface IntegrationsSchemaContract {
  data?: {
    id: number;
    name: string;
    type: string;
    description: string;
    info: string;
    isAvailable: boolean;
    isConnected: boolean;
    [k: string]: unknown;
  }[];
  required?: unknown;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    data: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'integer' },
          name: { type: 'string' },
          type: { type: 'string' },
          description: { type: 'string' },
          info: { type: 'string' },
          isAvailable: { type: 'boolean' },
          isConnected: { type: 'boolean' },
        },
        required: ['id', 'name', 'type', 'description', 'info', 'isAvailable', 'isConnected'],
      },
    },
    required: ['data'],
  },
};
