import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface BonusEnabledTypesSchemaContract {
  organization_id: number;
  types: {
    name: string;
    id: number;
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    organization_id: { type: ['integer'] },
    types: {
      type: ['array'],
      title: '',
      items: {
        type: 'object',
        properties: { name: { type: ['string'] }, id: { type: ['integer'] } },
        required: ['name', 'id'],
      },
    },
  },
  required: ['organization_id', 'types'],
};
