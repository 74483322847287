import { postApi, getApi } from 'utils/Api';
import SuccessResponseSchema, {SuccessResponseSchemaContract} from "../schemas/SuccessResponseSchema";

type DeactivationRequest = {};
type ActivationRequest = {};

export default {
  fetchActivationStatus: () => {
    return getApi<SuccessResponseSchemaContract>({
      url: '/whatsapp-settings/activation-status',
      responseSchema: SuccessResponseSchema,
    });
  },

  activate: () => {
    return postApi<SuccessResponseSchemaContract, ActivationRequest>({
      url: '/whatsapp-settings/activate',
      responseSchema: SuccessResponseSchema,
      requestData: {}
    });
  },

  deactivate: () => {
    return postApi<SuccessResponseSchemaContract, DeactivationRequest>({
      url: '/whatsapp-settings/deactivate',
      responseSchema: SuccessResponseSchema,
      requestData: {}
    });
  },
};
