import React from 'react';
import styles from './index.module.scss';

type PropTypes = {
  size?: number;
};

const Spinner = ({ size = 20 }: PropTypes) => {
  return <div className={styles['spinner']} style={{ width: size, height: size }}></div>;
};

export default Spinner;
