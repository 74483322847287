import { FnfUpdateRequestSchemaContract } from 'schemas/FnfUpdateRequestSchema';
import FnfUpdateResponseSchema, {
  FnfUpdateResponseSchemaContract,
} from 'schemas/FnfUpdateResponseSchema';
import GetFnfDismissalDateSchema, {
  GetFnfDismissalDateSchemaContract,
} from 'schemas/GetFnfDismissalDateSchema';
import GetFnfResponseSchema, { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import GetNetpayOnHoldListItemSchema, {
  GetNetpayOnHoldListItemSchemaContract,
} from 'schemas/GetNetpayOnHoldListItemSchema';
import GetNetpayOnHoldListSchema, {
  GetNetpayOnHoldListSchemaContract,
} from 'schemas/GetNetpayOnHoldListSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import { getApi, postApi } from 'utils/Api';

export interface GetNetpayOnHoldListRequestsData {
  page?: number;
  limit?: number;
  search?: string | number;
  ['status[]']?: string[];
  ['departments[]']?: string[];
  ['locations[]']?: string[];
}

const fnfApis = {
  getFnfData: (peopleId: number, dismissalDate: string) => {
    return getApi<GetFnfResponseSchemaContract>({
      url: `/get-fnf?people_id=${peopleId}&dismissal_date=${dismissalDate}`,
      responseSchema: GetFnfResponseSchema,
    });
  },

  getDismissalDate: (peopleId: number) => {
    return getApi<GetFnfDismissalDateSchemaContract>({
      url: `/fnf/dismissal-date?people_id=${peopleId}`,
      responseSchema: GetFnfDismissalDateSchema,
    });
  },

  updateFnfData: (data: FnfUpdateRequestSchemaContract) => {
    return postApi<FnfUpdateResponseSchemaContract, FnfUpdateRequestSchemaContract>({
      url: `/fnf/update`,
      requestData: data,
      responseSchema: FnfUpdateResponseSchema,
    });
  },

  getNetpayOnHoldList: (queryString: string) => {
    return getApi<GetNetpayOnHoldListSchemaContract>({
      url: `/fnf/net-pay-on-hold-list?${queryString}`,
      responseSchema: GetNetpayOnHoldListSchema,
    });
  },

  getNetpayOnHoldListItem: (peopleId: string | number) => {
    return getApi<GetNetpayOnHoldListItemSchemaContract>({
      url: `/fnf/net-pay-on-hold-list-item?peopleId=${peopleId}`,
      responseSchema: GetNetpayOnHoldListItemSchema,
    });
  },

  releaseNetpay: (requestData: { peopleId: string | number; month?: string }) => {
    return postApi<SuccessResponseSchemaContract, { peopleId: string | number; month?: string }>({
      url: '/fnf/release-net-pay',
      requestData,
      responseSchema: SuccessResponseSchema,
    });
  },
  releaseNetpayV2: (requestData: { peopleId: string | number; month?: string }) => {
    return postApi<SuccessResponseSchemaContract, { peopleId: string | number; month?: string }>({
      url: '/fnf/release-net-pay-v2',
      requestData,
      responseSchema: SuccessResponseSchema,
    });
  },
  releaseNetpayV2WithOtp: (requestData: { peopleId: string | number; month?: string, two_factor_auth_otp: string }) => {
    return postApi<SuccessResponseSchemaContract, { peopleId: string | number; month?: string, two_factor_auth_otp: string }>({
      url: '/fnf/release-net-pay-v2-with-otp',
      requestData,
      responseSchema: SuccessResponseSchema,
    });
  },
};

export default fnfApis;
