import CamsSettingsSchema ,{CamsSettingsSchemaContract} from 'schemas/CamsSettingsSchema';

import SuccessResponseSchema, {
    SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';

import {getApi, postApi, deleteApi} from 'utils/Api';

type addDeviceRequestContract = {
    name : string;
    serviceId : string;
    authToken : string;
}
type editDeviceNameType = {
    deviceId:string,
    name:string
}
type syncAllEmployeesType = {}
type refreshDeviceType ={
    deviceId:string;
}
type deleteDeviceRequestType={
    deviceId:string;
}

export default {  
    addDevice : ({name, serviceId, authToken} : addDeviceRequestContract ) => {
        return postApi<SuccessResponseSchemaContract, addDeviceRequestContract>({
            url : '/cams',
            responseSchema : SuccessResponseSchema,
            requestData :{
                name : name,
                serviceId : serviceId,
                authToken : authToken
            }    
        })
    },
    getConnectedDevices : () => {
        return getApi<CamsSettingsSchemaContract>({
            url:'/cams',
            responseSchema:CamsSettingsSchema
        })
    },
    deleteDevice : (deviceId:string) => {
        return deleteApi<SuccessResponseSchemaContract,deleteDeviceRequestType>({
            url:'/cams',
            responseSchema : SuccessResponseSchema,
            requestData : {
                deviceId:deviceId
            }
        })
    },
    syncAllEmployees : () => {
        return postApi<SuccessResponseSchemaContract,syncAllEmployeesType>({
            url:'/cams-sync-employees',
            responseSchema:SuccessResponseSchema,
        })
    },
    updateDeviceName : ({deviceId, name}:editDeviceNameType ) => {
        return postApi<SuccessResponseSchemaContract,editDeviceNameType>({
            url:'/cams/edit-device-name',
            responseSchema : SuccessResponseSchema,
            requestData : {
                deviceId : deviceId,
                name: name
            }
        })
    },
    refreshDevice : ({deviceId}:refreshDeviceType) => {
        return postApi<SuccessResponseSchemaContract,refreshDeviceType>({
            url:'/cams-refresh',
            responseSchema:SuccessResponseSchema,
            requestData : {
                deviceId : deviceId
            }
        })
    }

}

