import { OTPInput } from '@razorpay/blade/components';
import React, { useState } from 'react';

import closeIcon from 'assets/icon--close-blue.svg';
import otpIcon from 'assets/verify-otp.png';
import { LinkButtonV2, StandardButton } from 'components/ui/Button';
import { getStaticMediaUrl } from 'utils/Urls';

import { SUPPORT_EMAIL } from 'constants/companyContacts';
import { trackSegment } from 'utils/segment';
import styles from './Login/index.module.scss';
import OtpTimer from './OtpTimer';

export type OtpModalProps = {
  data: {
    is_authenticator_enabled?: boolean;
    email?: string;
    remainingTime: number;
    error?: string;
    errorCode?: string;
    isResendAttempt?: boolean;
  };
  isLoading?: boolean;
  isDisabled?: boolean;
  onVerify?: (e: React.MouseEvent, otp: string) => void;
  onResend?: (e: React.MouseEvent, resetTimerCallback: Function) => void;
  onClose?: (e: React.MouseEvent) => void;
  className?: string;
  actionName: string;
};

const OtpModal = ({
  data,
  onVerify,
  onResend,
  onClose,
  isLoading = false,
  isDisabled = false,
  className = '',
  actionName,
}: OtpModalProps) => {
  const [otp, setOtp] = useState<string>('');

  const onOtpChange = (event: any) => {
    const enteredOtp = event.value;
    const otpPattern = /^[0-9]{6}$/;

    if (otpPattern.test(enteredOtp)) {
      setOtp(enteredOtp);
    } else {
      setOtp('');
    }
  };

  const handleVerifyClick = (e: React.MouseEvent) => {
    trackSegment('two_factor_authentication.verify_click', {
      properties: { actionName },
    });
    onVerify?.(e, otp);
  };

  const handleResendClick = (e: React.MouseEvent, resetTimerCallback: Function) => {
    trackSegment('two_factor_authentication.resend_click', {
      properties: { actionName },
    });
    onResend?.(e, resetTimerCallback);
  };

  return (
    <div
      className={`position-fixed left-0 top-0 flex h-screen w-screen z-50 bg-modal-blur-background ${className}`}>
      <div className="m-auto flex flex-col items-center">
        <div>
          <div className={styles['otp-component'] + ' rounded-lg relative'}>
            <img src={getStaticMediaUrl(otpIcon)} alt="" width="120px" />

            <button
              aria-label="Close Modal"
              type="button"
              onClick={onClose}
              className="btn btn--tertiary absolute right-6 top-6 !w-8 !h-8 min-w-0 min-h-0 !grid place-items-center !p-2 !m-0">
              <img src={getStaticMediaUrl(closeIcon)} alt="Close" />
            </button>
            <div className={styles['otp-title']}>
              {data?.is_authenticator_enabled
                ? 'Authentication code'
                : 'Enter OTP for verification'}
            </div>
            <div className={styles['otp-sub-title']}>
              {data?.is_authenticator_enabled
                ? 'Open the two-factor authenticator app on your mobile device to view the authentication code'
                : `One Time Password sent successfully to ${data?.email}`}
            </div>
            <OTPInput
              label=""
              errorText={data.error}
              value={otp}
              name="otp-field"
              onChange={onOtpChange}
              otpLength={6}
              placeholder=""
              validationState={data.error ? 'error' : 'none'}
            />
            {data.isResendAttempt ? (
              <div className="text-pill-green-dark text-100 mt-3">A new OTP has been sent</div>
            ) : null}
            <input type="hidden" name="otp" value={otp} />
            <div className="flex items-center">
              {!data?.is_authenticator_enabled && (
                <div className="mt-[48px] flex items-center">
                  <div className={styles['otp-time-text']}> Did not receive OTP yet?</div>
                  <OtpTimer duration={data.remainingTime} format="mmss" isVisible={true}>
                    {({
                      timerDone,
                      resetTimer,
                    }: {
                      timerDone: boolean;
                      resetTimer: () => void;
                    }) => (
                      <div>
                        {timerDone ? (
                          <LinkButtonV2
                            className="m-0 p-0 normal-case"
                            onClick={(e) => handleResendClick(e, resetTimer)}
                            disabled={isDisabled || isLoading}>
                            Resend now
                          </LinkButtonV2>
                        ) : (
                          <div style={{ fontSize: 15, color: 'rgba(255, 255, 255, 0.38)' }}>
                            Resend in
                          </div>
                        )}
                      </div>
                    )}
                  </OtpTimer>
                </div>
              )}
            </div>
            <div className="my-[32px] w-full">
              <StandardButton
                className={'w-full m-0 p-0'}
                disabled={!otp || isDisabled}
                isPending={isLoading}
                onClick={(e) => handleVerifyClick(e)}>
                Verify
              </StandardButton>
            </div>

            <div className="text-[14px] mt-10 flex justify-center items-center text-center flex-wrap text-100">
              Can't find the OTP? Reach out to us at
              <a target="_blank" href={`mailto:${SUPPORT_EMAIL}`} className="ml-2 font-bold">
                {SUPPORT_EMAIL}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
