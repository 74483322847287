import { getApi, postApi } from 'utils/Api';
import BulkDownloadSchema, { BulkDownloadSchemaContract } from 'schemas/BulkDownloadSchema';
import { PayslipBulkDownloadSchemaContract } from 'schemas/PayslipBulkDownloadSchema';
import BulkDownloadResponseSchema, { BulkDownloadResponseSchemaContract } from 'schemas/BulkDownloadResponseSchema';
import { DocumentsBulkDownloadSchemaContract } from 'schemas/DocumentsBulkDownloadSchema';
import { ReimbursementProofsBulkDownloadSchemaContract } from 'schemas/ReimbursementProofsBulkDownloadSchema';
import { TaxDeductionProofsBulkDownloadSchemaContract } from 'schemas/TaxDeductionProofsBulkDownloadSchema';

export default {
  getBulkDownload: (id: number) => {
    return getApi<BulkDownloadSchemaContract>({
      url: `/bulk-download/${id}`,
      responseSchema: BulkDownloadSchema,
    });
  },
  initiateEmployeePayslip: (requestData: PayslipBulkDownloadSchemaContract, peopleOrgId: number|null = null) => {
    return postApi<BulkDownloadResponseSchemaContract,PayslipBulkDownloadSchemaContract>({
        url:`/bulk-download/payslip${peopleOrgId ? `/${peopleOrgId}` : ""}`,
        responseSchema: BulkDownloadResponseSchema,
        requestData
    })
  },
  initiateOrgPayslip: (requestData: PayslipBulkDownloadSchemaContract) => {
    return postApi<BulkDownloadResponseSchemaContract,PayslipBulkDownloadSchemaContract>({
        url:`/bulk-download/organization/payslip`,
        responseSchema: BulkDownloadResponseSchema,
        requestData
    })
  },
  initiateOrgDocuments: (requestData: DocumentsBulkDownloadSchemaContract) => {
    return postApi<BulkDownloadResponseSchemaContract,DocumentsBulkDownloadSchemaContract>({
        url:`/bulk-download/organization/documents`,
        responseSchema: BulkDownloadResponseSchema,
        requestData
    })
  },
  initiateOrgReimbursementProofs: (requestData: ReimbursementProofsBulkDownloadSchemaContract) => {
    return postApi<BulkDownloadResponseSchemaContract,ReimbursementProofsBulkDownloadSchemaContract>({
        url:`/bulk-download/organization/reimbursements`,
        responseSchema: BulkDownloadResponseSchema,
        requestData
    })
  },
  initiateOrgTaxDeductionProofs: (requestData: TaxDeductionProofsBulkDownloadSchemaContract) => {
    return postApi<BulkDownloadResponseSchemaContract,TaxDeductionProofsBulkDownloadSchemaContract>({
        url:`/bulk-download/organization/tax-deductions`,
        responseSchema: BulkDownloadResponseSchema,
        requestData
    })
  }
};