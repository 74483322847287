import { getApi, postApi } from 'utils/Api';
import PfRegistrationSchema, {
  PfRegistrationSchemaContract,
} from '../schemas/PfRegistrationSchema';

import SuccessResponseSchema from 'schemas/SuccessResponseSchema';

export default {
  fetchData: (page: number, rowLimit: number, searchFilter: string, statusFilter: string) => {
    const queryParams = `?page=${page}&page_size=${rowLimit}&search=${searchFilter}&status=${statusFilter}`;
    return getApi<PfRegistrationSchemaContract>({
      url: `/reports/pf-dashboard${queryParams}`,
      responseSchema: PfRegistrationSchema,
    });
  },

  retryRegistration: (userId: number) => {
    return postApi<PfRegistrationSchemaContract, {}>({
      url: `/reports/pf-dashboard/retry?userId=${userId}`,
      responseSchema: SuccessResponseSchema,
    });
  },
};
