import React, { useEffect} from 'react';
import '../BaseShell/tailwind.css';

const convertSecondsToFormattedTime = (timeInSeconds: number, format: string = 'hhmmss'): string => {
    const isoString = new Date(timeInSeconds * 1000).toISOString();

    if (format === 'mmss') return isoString.substr(14, 5);

    return isoString.substr(11, 8);
};

interface OtpTimerProps {
    duration: number;
    format: string;
    isVisible: boolean;
    children: (props: { timerDone: boolean; resetTimer: () => void; }) => React.ReactNode;
}

const OtpTimer: React.FC<OtpTimerProps> = ({ duration, children, format, isVisible }) => {

    const [timerDone, setTimerDone] = React.useState(false);
    const [timeLeftInSeconds, setTimeLeftInSeconds] = React.useState(
        duration || 0,
    );

    useEffect(() => {
        const timer = setInterval(() => {
            if (timeLeftInSeconds === 0) {
                clearInterval(timer);
                setTimerDone(true);
            } else {
                setTimeLeftInSeconds((prevState) => prevState - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [timeLeftInSeconds]);

    const formattedTimer = convertSecondsToFormattedTime(
        timeLeftInSeconds,
        format,
    );

    const resetTimer = () => {
        setTimeLeftInSeconds(duration);
        setTimerDone(false);
    };

    return (
        <div className="flex" >
            {children({
                timerDone,
                resetTimer: resetTimer,
            })}
            <span  style={{fontSize:15, paddingLeft:4, color: "#7fb2f9"}}>
          {!timerDone && isVisible && formattedTimer}
        </span>
        </div>
    );
}

export default OtpTimer;