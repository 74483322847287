import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface WorkflowRequestApprovalSchemaContract {
  success?: number[];
  failure?: number[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    success: { type: 'array', minItems: 0, items: { type: 'number' } },
    failure: { type: 'array', minItems: 0, items: { type: 'number' } },
  },
};
