
export function getStaticMediaUrl(pathOrInlineData: string) {
  if (pathOrInlineData.includes('/static') && !pathOrInlineData.includes('http')) {
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT_SERVER_EXTERNAL_LINK) {
      return `${process.env.REACT_APP_LOCAL_DEVELOPMENT_SERVER_EXTERNAL_LINK}/${pathOrInlineData.replace(
        /^\//,
        '',
      )}`;
    } else {
      return `/${pathOrInlineData.replace(/^\//, '')}`;
    }
  }

  return pathOrInlineData;
}

export function prepareUrl(
  url: string,
  params: Record<string, string | number | string[] | number[] | null | undefined>,
) {
  let paramsStringList = [];
  for (const key in params) {
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Array.isArray(value)) {
      value = value.join(',');
    }
    paramsStringList.push(`${key}=${value}`);
  }
  let paramsString = paramsStringList.length ? '?' + paramsStringList.join('&') : '';
  return `${url}${paramsString}`;
}

export const getUrlWithoutQuery = (url: string) => {
  let cleanedUrl = '';
  try {
    const urlObject = new URL(url);
    urlObject.search = '';
    cleanedUrl = urlObject.href;
  } catch (e) {}

  return cleanedUrl;
};

export const getRedactedPath = (path: string) => {
  const regex = /\/\d+/g;
  return path.replace(regex, '/ID');
}
