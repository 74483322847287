import { ToastFromEventType } from 'components/ui/Toast/types';

export function showToastViaEvent({ id, text, timeout, type, icon }: ToastFromEventType) {
  window.postMessage(
    {
      eventType: 'show-toast',
      id,
      text,
      timeout,
      type,
      icon,
    },
    window.location.origin,
  );
}

export function closeToastViaEvent(id: string) {
  window.postMessage(
    {
      eventType: 'close-toast',
      id,
    },
    window.location.origin,
  );
}

export const showSuccessToastNotification = ({
  text,
  timeout,
  id,
}: Pick<ToastFromEventType, 'text' | 'id' | 'timeout'>) => {
  showToastViaEvent({
    id,
    text,
    timeout,
    type: 'success',
    icon: 'success',
  });
};

export const showErrorToastNotification = ({
  text,
  timeout,
  id,
}: Pick<ToastFromEventType, 'text' | 'id' | 'timeout'>) => {
  showToastViaEvent({
    id,
    text,
    timeout,
    type: 'danger',
    icon: 'info',
  });
};
