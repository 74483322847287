import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetFnfResponseSchemaContract {
  is_dismissal_synced_by_hrms: boolean;
  is_fnf_processed: boolean;
  payroll_id: number | null;
  is_only_admin: boolean;
  show_bonus_banner: boolean;
  reason_for_leaving: string | null;
  loan_amount_required_for_full_recovery: number | null;
  deactivate_date: string | null;
  date_of_joining: string | null;
  net_pay_on_hold_from: string | null;
  personal_email_address: string | null;
  has_active_insurance: boolean;
  employee_name: string | null;
  employee_id?: string | number;
  employee_department?: string | null;
  employee_title?: string | null;
  leave_types:
    | {
        type: string;
        balance: number;
        total: number;
        [k: string]: unknown;
      }[]
    | null;
  salary_advance: {
    total_amount: number;
    emi: number;
    [k: string]: unknown;
  } | null;
  payroll_status: {
    is_finalized: boolean;
    is_skipped: boolean;
    is_paid: boolean;
    is_finalization_in_progress: boolean;
    [k: string]: unknown;
  };
  additions:
    | {
        name: string;
        amount: number;
        [k: string]: unknown;
      }[]
    | null;
  deductions: {
    non_lop_deductions:
      | {
          name: string;
          amount: number;
          deduct_from: number | null;
          [k: string]: unknown;
        }[]
      | null;
    lop_deduction: {
      amount: number;
      lop_days: string;
      type: number;
      [k: string]: unknown;
    } | null;
    total_deduction: number;
    [k: string]: unknown;
  };
  leave_encashments: {
    total_encashment: number | null;
    leave_days_encashed: string | null;
    [k: string]: unknown;
  } | null;
  gratuity: {
    gratuity_applicable: boolean;
    gratuity_amount: number | null;
    [k: string]: unknown;
  } | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    is_dismissal_synced_by_hrms: { type: ['boolean'] },
    is_fnf_processed: { type: ['boolean'] },
    payroll_id: { type: ['number', 'null'] },
    is_only_admin: { type: ['boolean'] },
    show_bonus_banner: { type: ['boolean'] },
    reason_for_leaving: { type: ['string', 'null'] },
    loan_amount_required_for_full_recovery: { type: ['number', 'null'] },
    deactivate_date: { type: ['string', 'null'], format: 'date' },
    date_of_joining: { type: ['string', 'null'], format: 'date' },
    net_pay_on_hold_from: { type: ['string', 'null'], format: 'date' },
    personal_email_address: { type: ['string', 'null'] },
    has_active_insurance: { type: ['boolean'] },
    employee_name: { type: ['string', 'null'] },
    employee_id: { type: ['string', 'number'] },
    employee_department: { type: ['string', 'null'] },
    employee_title: { type: ['string', 'null'] },
    leave_types: {
      type: ['array', 'null'],
      items: {
        type: 'object',
        properties: {
          type: { type: ['string'] },
          balance: { type: ['number'] },
          total: { type: ['number'] },
        },
        required: ['type', 'balance', 'total'],
      },
    },
    salary_advance: {
      type: ['object', 'null'],
      properties: { total_amount: { type: ['number'] }, emi: { type: ['number'] } },
      required: ['total_amount', 'emi'],
    },
    payroll_status: {
      type: ['object'],
      properties: {
        is_finalized: { type: ['boolean'] },
        is_skipped: { type: ['boolean'] },
        is_paid: { type: ['boolean'] },
        is_finalization_in_progress: { type: ['boolean'] },
      },
      required: ['is_finalized', 'is_skipped', 'is_paid', 'is_finalization_in_progress'],
    },
    additions: {
      type: ['array', 'null'],
      items: {
        type: 'object',
        properties: { name: { type: ['string'] }, amount: { type: ['number'] } },
        required: ['name', 'amount'],
      },
    },
    deductions: {
      type: ['object'],
      properties: {
        non_lop_deductions: {
          type: ['array', 'null'],
          items: {
            type: 'object',
            properties: {
              name: { type: ['string'] },
              amount: { type: ['number'] },
              deduct_from: { type: ['number', 'null'] },
            },
            required: ['name', 'amount', 'deduct_from'],
          },
        },
        lop_deduction: {
          type: ['object', 'null'],
          properties: {
            amount: { type: ['number'] },
            lop_days: { type: ['string'] },
            type: { type: ['number'] },
          },
          required: ['amount', 'lop_days', 'type'],
        },
        total_deduction: { type: 'number' },
      },
      required: ['non_lop_deductions', 'lop_deduction', 'total_deduction'],
    },
    leave_encashments: {
      type: ['object', 'null'],
      properties: {
        total_encashment: { type: ['number', 'null'] },
        leave_days_encashed: { type: ['string', 'null'] },
      },
      required: ['total_encashment', 'leave_days_encashed'],
    },
    gratuity: {
      type: ['object', 'null'],
      properties: {
        gratuity_applicable: { type: ['boolean'] },
        gratuity_amount: { type: ['number', 'null'] },
      },
      required: ['gratuity_applicable', 'gratuity_amount'],
    },
  },
  required: [
    'is_dismissal_synced_by_hrms',
    'is_fnf_processed',
    'is_only_admin',
    'show_bonus_banner',
    'reason_for_leaving',
    'net_pay_on_hold_from',
    'loan_amount_required_for_full_recovery',
    'deactivate_date',
    'date_of_joining',
    'personal_email_address',
    'has_active_insurance',
    'employee_name',
    'leave_types',
    'salary_advance',
    'payroll_status',
    'additions',
    'deductions',
    'leave_encashments',
    'gratuity',
    'payroll_id',
  ],
};
