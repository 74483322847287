import { GetMeApiSchemaContract } from '../../schemas/GetMeApiSchema';
import { IAppErrorDetails } from '../../utils/AppError';
import {
  IFetchLoggedInUserAction,
  ILoggedInUserFetchFailedAction,
  ILoggedInUserFetchSuccededAction,
  UpdateUiUserPreferencesAction,
} from './actionCreators';
import { ActionTypes } from './constants';

export interface ILoggedInUser {
  hasError: boolean;
  data: GetMeApiSchemaContract | null;
  errorData: IAppErrorDetails | null;
  isFetching: boolean;
}

const initialState: ILoggedInUser = {
  data: null,
  hasError: false,
  isFetching: false,
  errorData: null,
};

export function loggedInUser(
  state = initialState,
  action:
    | IFetchLoggedInUserAction
    | ILoggedInUserFetchSuccededAction
    | ILoggedInUserFetchFailedAction
    | UpdateUiUserPreferencesAction,
): ILoggedInUser {
  switch (action.type) {
    case ActionTypes.FETCH_LOGGED_IN_USER:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.LOGGED_IN_USER_FETCH_SUCCEDED:
      return {
        ...state,
        data: action.payload,
        hasError: false,
        isFetching: false,
        errorData: null,
      };
    case ActionTypes.LOGGED_IN_USER_FETCH_FAILED:
      return { ...state, data: null, hasError: true, isFetching: false, errorData: action.payload };
    case ActionTypes.UPDATE_UI_USER_PREFERENCES:
      return {
        ...state,
        data: {
          ...state.data,
          currentOrganization: {
            ...(state.data?.currentOrganization ?? {}),
            // @ts-ignore
            employeeDetails: {
              ...(state.data?.currentOrganization?.employeeDetails ?? {}),
              uiPreferences: {
                ...state.data?.currentOrganization?.employeeDetails?.uiPreferences,
                ...action.payload,
              },
            },
          },
        },
      };
    default:
      return state;
  }
}
