import { getEnvironmentName, isProduction } from './getEnvironment';

const APP_NAME = 'rx-payroll';
const STAGE_KEY = 'MFWSBXmeXhKQRXcEztn2MqjsTUwlTXsE';
const LUMBERJACK_METRICS_STAGE_URL = 'https://lumberjack-metrics.stage.razorpay.in/v1/frontend-metrics';
const PROD_KEY = '3HvwWidTR6Zf4PZSzPBpECxbflKqkFvb';
const LUMBERJACK_METRICS_PROD_URL = 'https://lumberjack-metrics.razorpay.com/v1/frontend-metrics';
export const Metrics = {
    ERROR_COUNT: 'error.count'
};

export const capturePrometheusMetrics = (metrics: string, labels: {}) => {
    const env = getEnvironmentName();
    const isProd = isProduction();
    const key = isProd ? PROD_KEY : STAGE_KEY;
    const lumberjackUrl = isProd ? LUMBERJACK_METRICS_PROD_URL : LUMBERJACK_METRICS_STAGE_URL;

    const data = {
        key,
        metrics: [
            {
                name: metrics,
                labels: [
                    {
                        env,
                        app: APP_NAME,
                        ...labels
                    },
                ],
            },
        ],
    };

    fetch(lumberjackUrl, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            accept: 'application/json',
            'Content-Type': 'text/plain',
        },
    });
}