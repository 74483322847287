import { GetMeApiSchemaContract } from '../../schemas/GetMeApiSchema';
import { IAppErrorDetails } from '../../utils/AppError';
import { ActionTypes } from './constants';

/**
 * success
 */
export interface ILoggedInUserFetchSuccededAction {
  type: typeof ActionTypes.LOGGED_IN_USER_FETCH_SUCCEDED;
  payload: GetMeApiSchemaContract;
}
export const loggedInUserFetchSucceded = (
  payload: GetMeApiSchemaContract,
): ILoggedInUserFetchSuccededAction => ({
  type: ActionTypes.LOGGED_IN_USER_FETCH_SUCCEDED,
  payload,
});

/**
 * Failed
 */
export interface ILoggedInUserFetchFailedAction {
  type: typeof ActionTypes.LOGGED_IN_USER_FETCH_FAILED;
  payload: IAppErrorDetails;
}
export const loggedInUserFetchFailed = (
  payload: IAppErrorDetails,
): ILoggedInUserFetchFailedAction => ({
  type: ActionTypes.LOGGED_IN_USER_FETCH_FAILED,
  payload,
});

/**
 * Fetch
 */
export interface IFetchLoggedInUserAction {
  type: typeof ActionTypes.FETCH_LOGGED_IN_USER;
}
export const fetchLoggedInUser = (): IFetchLoggedInUserAction => ({
  type: ActionTypes.FETCH_LOGGED_IN_USER,
});

export interface UpdateUiUserPreferencesAction {
  type: typeof ActionTypes.UPDATE_UI_USER_PREFERENCES;
  payload: {
    [key: string]: any;
  };
}

export const updateUiUserPreferencesAction = (
  uiPreferences: UpdateUiUserPreferencesAction['payload'],
): UpdateUiUserPreferencesAction => ({
  type: ActionTypes.UPDATE_UI_USER_PREFERENCES,
  payload: uiPreferences,
});
