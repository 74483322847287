import { useCallback, useEffect } from 'react';

const useClickOutside = ({
  ref,
  onOutSideClick,
}: {
  ref: React.RefObject<any>;
  onOutSideClick: () => void;
}) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        onOutSideClick();
      }
    },
    [ref, onOutSideClick],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useClickOutside;
