import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type BulkUploadDetailsSchemaContract = {
  id: number;
  created_at: string;
  updated_at: string;
  file_name: null | string;
  error_file_name: null | string;
  type: number;
  status: number;
  by_people_id: number | null;
  organization_id: number | null;
  number_of_rows_with_error: null | number;
  total_number_of_rows: null | number;
  file_summary: null | {
    numberOfAdditions?: null | number;
    numberOfDeductions?: null | number;
    numberOfLOPDays?: null | number;
    sumOfAdditions?: null | number;
    sumOfDeductions?: null | number;
    sumOfLOPdays?: null | number;
    [k: string]: unknown;
  };
  input_config: null | {
    payrollMonth?: string | null;
    effectiveMonth?: string | null;
    [k: string]: unknown;
  };
  validation_started_at: null | string;
  validation_finished_at: null | string;
  execution_started_at: null | string;
  execution_finished_at: null | string;
  errors: string[] | null;
  uploaded_file_url: null | string;
  error_file_url: null | string;
  percentage_completed?: null | number;
  [k: string]: unknown;
}[];

export default <JSONSchema4>{
  type: ['array'],
  title: '',
  items: {
    type: 'object',
    title: '',
    properties: {
      id: { type: 'number' },
      created_at: { type: ['string'], format: 'date-time' },
      updated_at: { type: ['string'], format: 'date-time' },
      file_name: { type: ['null', 'string'] },
      error_file_name: { type: ['null', 'string'] },
      type: { type: 'number' },
      status: { type: ['number'] },
      by_people_id: { type: ['number', 'null'] },
      organization_id: { type: ['number', 'null'] },
      number_of_rows_with_error: { type: ['null', 'number'] },
      total_number_of_rows: { type: ['null', 'number'] },
      file_summary: {
        type: ['null', 'object'],
        properties: {
          numberOfAdditions: { type: ['null', 'number'] },
          numberOfDeductions: { type: ['null', 'number'] },
          numberOfLOPDays: { type: ['null', 'number'] },
          sumOfAdditions: { type: ['null', 'number'] },
          sumOfDeductions: { type: ['null', 'number'] },
          sumOfLOPdays: { type: ['null', 'number'] },
        },
      },
      input_config: {
        type: ['null', 'object'],
        properties: {
          payrollMonth: { type: ['string', 'null'], format: 'date' },
          effectiveMonth: { type: ['string', 'null'], format: 'date' },
        },
      },
      validation_started_at: { type: ['null', 'string'], format: 'date-time' },
      validation_finished_at: { type: ['null', 'string'], format: 'date-time' },
      execution_started_at: { type: ['null', 'string'], format: 'date-time' },
      execution_finished_at: { type: ['null', 'string'], format: 'date-time' },
      errors: { type: ['array', 'null'], items: { type: 'string' } },
      uploaded_file_url: { type: ['null', 'string'] },
      error_file_url: { type: ['null', 'string'] },
      percentage_completed: { type: ['null', 'number'], minimum: 0, maximum: 100 },
    },
    required: [
      'id',
      'created_at',
      'updated_at',
      'file_name',
      'error_file_name',
      'type',
      'status',
      'by_people_id',
      'organization_id',
      'number_of_rows_with_error',
      'total_number_of_rows',
      'file_summary',
      'input_config',
      'validation_started_at',
      'validation_finished_at',
      'execution_started_at',
      'execution_finished_at',
      'errors',
      'uploaded_file_url',
      'error_file_url',
    ],
  },
};
