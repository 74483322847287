import { postApi } from 'utils/Api';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';

type AnalyticsRequestContract = {
  event_name: string;
  event_type: string;
  properties?: object;
  source?: string;
};

type AnalyticsRequest = {
  eventName: string;
  eventType: string;
  properties?: object;
  source?: string;
};

export default {
  push: ({ eventName, eventType, properties, source }: AnalyticsRequest) => {
    return postApi<SuccessResponseSchemaContract, AnalyticsRequestContract>({
      url: '/analytics',
      responseSchema: SuccessResponseSchema,
      requestData: {
        event_name: eventName,
        event_type: eventType,
        properties,
        source,
      },
    });
  },
};
