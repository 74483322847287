export function getEventQueue(queueName) {
  var eventQueue = localStorage.getItem(queueName);
  eventQueue = JSON.parse(eventQueue);
  return eventQueue || [];
}

export function createEventQueue(queueName) {
  var eventQueue = [];
  localStorage.setItem(queueName, JSON.stringify(eventQueue));
}

export function addEventToQueue(event, queueName) {
  var eventQueue = getEventQueue(queueName);
  eventQueue.push(event);
  localStorage.setItem(queueName, JSON.stringify(eventQueue));
}

export function clearQueue(queueName) {
  localStorage.setItem(queueName, null);
}
