import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetAllEndorsementsContract {
  summary: {
    employeeIdsWhoseDetailsAreUpdated: number[];
    dependentIdsWhoseDetailsAreUpdated: number[];
    totalMissingEmployeeDetails: number;
    totalAmount: number;
    amount: number;
    gst: number;
    employeeIdsWhoseDetailsAreMissing: number[];
    newEmployeeIdsWithDependents: number[];
    newDependentsForNewEmployeeIds: number[];
    newDependentIdsForExistingEmployeeIds: number[];
    dismissedEmployeeIds: number[];
    dismissedDependentIds: number[];
    premiumBreakup: {
      newEmployees: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` "^[0-9]+$".
         */
        [k: string]: number;
      };
      newDependents: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` "^[0-9]+$".
         */
        [k: string]: number;
      };
      newEmployeesWithDependents: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` "^[0-9]+$".
         */
        [k: string]: number;
      };
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  policyRestrictionsForEndorsements: {
    hasError: boolean;
    issues: string[];
    [k: string]: unknown;
  };
  total: number;
  currentOrganizationBalance: number;
  employees: {
    id: number;
    employeeID: string | null;
    email: string | null;
    name: string | null;
    dateOfBirth: string | null;
    gender: ('male' | 'female' | 'other') | null;
    comment: string | null;
    isDataCollected: boolean;
    dependents: {
      id: number;
      name: string;
      relation: string;
      dateOfBirth: string | null;
      gender: ('male' | 'female' | 'other') | null;
      [k: string]: unknown;
    }[];
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    summary: {
      type: 'object',
      properties: {
        employeeIdsWhoseDetailsAreUpdated: { type: 'array', items: { type: 'integer' } },
        dependentIdsWhoseDetailsAreUpdated: { type: 'array', items: { type: 'integer' } },
        totalMissingEmployeeDetails: { type: 'number' },
        totalAmount: { type: 'number' },
        amount: { type: 'number' },
        gst: { type: 'number' },
        employeeIdsWhoseDetailsAreMissing: { type: 'array', items: { type: 'integer' } },
        newEmployeeIdsWithDependents: { type: 'array', items: { type: 'integer' } },
        newDependentsForNewEmployeeIds: { type: 'array', items: { type: 'integer' } },
        newDependentIdsForExistingEmployeeIds: { type: 'array', items: { type: 'integer' } },
        dismissedEmployeeIds: { type: 'array', items: { type: 'integer' } },
        dismissedDependentIds: { type: 'array', items: { type: 'integer' } },
        premiumBreakup: {
          type: 'object',
          properties: {
            newEmployees: {
              type: 'object',
              patternProperties: { '^[0-9]+$': { type: 'integer' } },
            },
            newDependents: {
              type: 'object',
              patternProperties: { '^[0-9]+$': { type: 'integer' } },
            },
            newEmployeesWithDependents: {
              type: 'object',
              patternProperties: { '^[0-9]+$': { type: 'integer' } },
            },
          },
          required: ['newEmployees', 'newDependents', 'newEmployeesWithDependents'],
        },
      },
      required: [
        'employeeIdsWhoseDetailsAreMissing',
        'employeeIdsWhoseDetailsAreUpdated',
        'dependentIdsWhoseDetailsAreUpdated',
        'totalAmount',
        'amount',
        'gst',
        'newEmployeeIdsWithDependents',
        'newDependentsForNewEmployeeIds',
        'newDependentIdsForExistingEmployeeIds',
        'dismissedEmployeeIds',
        'dismissedDependentIds',
        'totalMissingEmployeeDetails',
        'premiumBreakup',
      ],
    },
    policyRestrictionsForEndorsements: {
      type: 'object',
      properties: {
        hasError: { type: 'boolean' },
        issues: { type: 'array', items: { type: 'string' } },
      },
      required: ['hasError', 'issues'],
    },
    total: { type: 'number' },
    currentOrganizationBalance: { type: 'number' },
    employees: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'integer' },
          employeeID: { type: ['string', 'null'] },
          email: { type: ['string', 'null'] },
          name: { type: ['string', 'null'] },
          dateOfBirth: { type: ['string', 'null'] },
          gender: { type: ['string', 'null'], enum: ['male', 'female', 'other'] },
          comment: { type: ['string', 'null'] },
          isDataCollected: { type: ['boolean'] },
          dependents: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                id: { type: 'integer' },
                name: { type: 'string' },
                relation: { type: 'string' },
                dateOfBirth: { type: ['string', 'null'] },
                gender: { type: ['string', 'null'], enum: ['male', 'female', 'other'] },
              },
              required: ['id', 'name', 'dateOfBirth', 'relation', 'gender'],
            },
          },
        },
        required: [
          'isDataCollected',
          'id',
          'name',
          'dateOfBirth',
          'gender',
          'comment',
          'dependents',
          'email',
          'employeeID',
        ],
      },
    },
  },
  required: [
    'summary',
    'total',
    'employees',
    'currentOrganizationBalance',
    'policyRestrictionsForEndorsements',
  ],
};
