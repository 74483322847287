export * from './EHttpStatusCodes';
export * from './EActionTypes';

export enum ECustomErrors {
  UNKNOWN = 'UNKNOWN',
  INVALID_SCHEMA = 'INVALID_SCHEMA',
}

export enum EEmployeeType {
  EMPLOYEE = 'employee',
  CONTRACTOR = 'contractor',
}

export enum EPeopleRole {
  ADMIN = 1,
  HUMAN_RESOURCES = 2,
  EXECUTE_PAYROLL = 3,
  VIEW_REPORTS = 4,
  REIMBURSEMENTS = 5,
  EMPLOYEE = 100,
}

export enum ETransactionStatus {
  EXECUTED_SUCCESSFULLY = 1,
  EXECUTION_PENDING = 2,
  EXECUTION_IN_PROGRESS = 3,
  FAILED_PERMANENTLY = 4,
  CANCELED = 5,
  DEFERRED_WITH_EXPIRY = 6,
  RX_EXECUTION_IN_PROGRESS = 7,
  DEFERRED_WITHOUT_EXPIRY = 8,
}
