import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetInsuranceApiSchemaContract {
  status:
    | 'ESTIMATION_IN_PROGRESS'
    | 'DATA_COLLECTION_IN_PROGRESS'
    | 'PURCHASE_IN_PROGRESS'
    | 'PURCHASED'
    | 'NO_ACTIVE_INSURANCE';
  insuranceUnderRenewal: boolean;
  isInsuranceRenewalEndorsementAllowed: boolean;
  transactionStatus?: number | null;
  planId: number | null;
  sumInsured: number | null;
  withChildrenAndSpouse: boolean | null;
  /**
   * cross parents are not allowed
   */
  withParentsOrInLaws: boolean | null;
  shouldCoverEmployeeFromEsi: boolean | null;
  id?: number;
  organizationId: number;
  vendorId?: number | null;
  vendorName?: string | null;
  vendorOrganizationDetails: {
    organisationId: string;
    [k: string]: unknown;
  } | null;
  isBlocked: boolean;
  planName: string | null;
  startDate: string | null;
  registeredInterestAt: string | null;
  endDate: string | null;
  vendorInsuranceDetails: {
    /**
     * This will only be generated when the status is either in purchase or purchased.
     */
    estimationId: string;
    [k: string]: unknown;
  } | null;
  cdBalance: number;
  areHealthCardsAvailable: boolean;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    status: {
      type: 'string',
      minLength: 1,
      enum: [
        'ESTIMATION_IN_PROGRESS',
        'DATA_COLLECTION_IN_PROGRESS',
        'PURCHASE_IN_PROGRESS',
        'PURCHASED',
        'NO_ACTIVE_INSURANCE',
      ],
      example: 'NO_ACTIVE_INSURANCE',
      default: 'NO_ACTIVE_INSURANCE',
      description: '',
    },
    insuranceUnderRenewal: { type: 'boolean', default: false },
    isInsuranceRenewalEndorsementAllowed: { type: 'boolean', default: true },
    transactionStatus: { type: ['integer', 'null'], default: null },
    planId: { type: ['integer', 'null'], default: null },
    sumInsured: {
      type: ['number', 'null'],
      default: null,
      example: 100000,
      multipleOf: 100000,
      minimum: 100000,
      maximum: 500000,
    },
    withChildrenAndSpouse: { type: ['boolean', 'null'], default: null },
    withParentsOrInLaws: {
      type: ['boolean', 'null'],
      default: null,
      description: 'cross parents are not allowed',
    },
    shouldCoverEmployeeFromEsi: { type: ['boolean', 'null'], default: null },
    id: { type: 'integer', minimum: 1, multipleOf: 1, format: 'int64' },
    organizationId: { type: 'integer', minimum: 1, multipleOf: 1, format: 'int64' },
    vendorId: {
      type: ['integer', 'null'],
      format: 'int32',
      minimum: 1,
      multipleOf: 1,
      default: null,
    },
    vendorName: { type: ['string', 'null'], minLength: 1, default: null },
    vendorOrganizationDetails: {
      type: ['object', 'null'],
      required: ['organisationId'],
      default: null,
      properties: { organisationId: { type: ['string'], minLength: 1 } },
    },
    isBlocked: { type: 'boolean', default: 'false' },
    planName: { type: ['string', 'null'], minLength: 1, default: null },
    startDate: { type: ['string', 'null'], default: null, minLength: 1, format: 'date-time' },
    registeredInterestAt: {
      type: ['string', 'null'],
      default: null,
      minLength: 1,
      format: 'date-time',
    },
    endDate: { type: ['string', 'null'], default: null, minLength: 1, format: 'date-time' },
    vendorInsuranceDetails: {
      type: ['object', 'null'],
      default: null,
      required: ['estimationId'],
      properties: {
        estimationId: {
          type: ['string'],
          description:
            'This will only be generated when the status is either in purchase or purchased.',
        },
      },
    },
    cdBalance: { type: 'integer', default: 0 },
    areHealthCardsAvailable: { type: 'boolean', default: false },
  },
  required: [
    'isBlocked',
    'organizationId',
    'vendorOrganizationDetails',
    'planName',
    'startDate',
    'endDate',
    'vendorInsuranceDetails',
    'cdBalance',
    'status',
    'planId',
    'planName',
    'sumInsured',
    'withChildrenAndSpouse',
    'withParentsOrInLaws',
    'shouldCoverEmployeeFromEsi',
    'registeredInterestAt',
    'areHealthCardsAvailable',
    'insuranceUnderRenewal',
    'isInsuranceRenewalEndorsementAllowed',
  ],
};
