import { postApi, getApi } from 'utils/Api';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import ZohoSettingsSchema, { ZohoSettingsContract } from 'schemas/ZohoSettings';

type ActivationRequest = {
  code: string;
  country: string;
  state: string;
};

type DeactivationRequest = {};

type SyncRequest = {};

type UpdateFieldsRequest = {
  fields: string[];
};

export default {
  activate: (country: string, code: string, state: string) => {
    return postApi<SuccessResponseSchemaContract, ActivationRequest>({
      url: '/zoho/activate',
      responseSchema: SuccessResponseSchema,
      requestData: {
        country,
        code,
        state,
      },
    });
  },
  fetchActivationStatus: () => {
    return getApi<ZohoSettingsContract>({
      url: '/zoho/show',
      responseSchema: ZohoSettingsSchema,
    });
  },
  disable: () => {
    return postApi<SuccessResponseSchemaContract, DeactivationRequest>({
      url: '/zoho/disable',
      responseSchema: SuccessResponseSchema,
      requestData: {},
    });
  },
  sync: () => {
    return postApi<SuccessResponseSchemaContract, SyncRequest>({
      url: '/zoho/sync',
      responseSchema: SuccessResponseSchema,
      requestData: {},
    });
  },
  updateFields: (fields: string[]) => {
    return postApi<SuccessResponseSchemaContract, UpdateFieldsRequest>({
      url: '/zoho/update-fields',
      responseSchema: SuccessResponseSchema,
      requestData: {
        fields,
      },
    });
  },
  enableUserDataSync: () => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/zoho/enable',
      responseSchema: SuccessResponseSchema,
      requestData: {},
    });
  },
  updateEmployeeTypeConfig: (types: string[]) => {
    return postApi<SuccessResponseSchemaContract, { types: string[] }>({
      url: '/zoho/update-employee-type-config',
      responseSchema: SuccessResponseSchema,
      requestData: {
        types,
      },
    });
  },
  updateEmployeeActiveStatuses: (statuses: string[]) => {
    return postApi<SuccessResponseSchemaContract, { statuses: string[] }>({
      url: '/zoho/update-employee-active-statuses-config',
      responseSchema: SuccessResponseSchema,
      requestData: {
        statuses,
      },
    });
  },
};
