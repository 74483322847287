export const ALLOWANCE_AMOUNT_TYPE_FIXED = 0;
export const ALLOWANCE_AMOUNT_TYPE_PERCENTAGE = 1;

export const amountTypes = {
  ALLOWANCE_AMOUNT_TYPE_FIXED: 0,
  ALLOWANCE_AMOUNT_TYPE_PERCENTAGE: 1,
};

export const taxableTypes = {
  TYPE_YES: 1,
  TYPE_NO: 0,
  TYPE_FLEXI: 2,
};

class Allowance {
  name: string;
  value?: number;
  taxableType: number;
  amountType: number;

  constructor({
    name,
    value,
    taxableType,
    amountType,
  }: {
    name: string;
    value?: number;
    taxableType: number;
    amountType: number;
  }) {
    this.name = name;
    this.value = value;
    this.taxableType = taxableType;
    this.amountType = amountType;
  }
}

export default Allowance;
