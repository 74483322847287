import { useHistory } from 'react-router-dom';
import { GetInsuranceApiSchemaContract } from '../../schemas/GetInsuranceApiSchema';
import { IAppErrorDetails } from '../../utils/AppError';

export interface IRouter {}

export enum ActionTypes {
  NAVIGATE_AFTER = 'NAVIGATE_AFTER',
}

const initialState: IRouter = {};

type Actions = never;

export function insurance(state = initialState, action: Actions): IRouter {
  return state;
}

export interface INavigateAfter {
  type: ActionTypes.NAVIGATE_AFTER;
  payload: {
    route: string;
    history: ReturnType<typeof useHistory>;
    action: {
      type: string;
      [k: string]: any;
    };
  };
}

export const navigateAfter = (
  route: string,
  action: {
    type: string;
    [k: string]: any;
  },
  history: ReturnType<typeof useHistory>,
): INavigateAfter => ({
  type: ActionTypes.NAVIGATE_AFTER,
  payload: {
    route,
    history,
    action,
  },
});
