declare global {
    let __webpack_public_path__: string;
    interface Window {
        webpackPublicPath?: string;
    }
}

if( window.webpackPublicPath !== '/'){
    __webpack_public_path__ = window.webpackPublicPath ? window.webpackPublicPath : '/';
}


export {};