import { delay, put, StrictEffect, takeLatest, takeLeading } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import {
  IFetchInsuranceEndorsements,
  insuranceEndorsementsFetchFailed,
  insuranceEndorsementsFetchSucceeded,
  insuranceEndorsementsFetching,
  ActionTypes,
  IInsuranceEndorsements,
  IPurchaseInsuranceEndorsements,
  purchaseEndorsementFailed,
  purchaseEndorsementSucceeded,
  purchasingEndorsements,
} from '../../reducers/insuranceEndorsements';
import { selectState } from '../sagaUtils';
import { prepareUrl } from '../../utils/Urls';
import { IInsurance } from '../../reducers/insurance';
import GetInsuranceEndorsements, {
  GetAllEndorsementsContract,
} from '../../schemas/GetAllEndorsements';

function* fetchInsuranceEndorsementsSaga(
  action: IFetchInsuranceEndorsements,
): Generator<StrictEffect, void, GetAllEndorsementsContract | IInsuranceEndorsements> {
  try {
    const currentEndorsements = <IInsuranceEndorsements>(
      yield selectState((state) => state.insuranceEndorsements)
    );

    const allFetchOptions = {
      ...currentEndorsements.fetchedFor,
      ...action.payload,
      fetchFresh: true,
      offset: 0,
    };

    if (allFetchOptions.search === '') {
      allFetchOptions.search = null;
    }

    yield put(insuranceEndorsementsFetching(allFetchOptions));
    if ('search' in action.payload) {
      yield delay(500);
    }
    const insuranceEndorsements = <GetAllEndorsementsContract>(
      yield AppSaga.getApi({
        url: prepareUrl(`/insurance/${action.payload.insuranceId}/endorsements`, {
          ...allFetchOptions,
          fetchFresh: null,
          limit: 10,
        }),
        responseSchema: GetInsuranceEndorsements,
      })
    );
    yield put(insuranceEndorsementsFetchSucceeded(insuranceEndorsements, true));
  } catch (e) {
    yield put(insuranceEndorsementsFetchFailed(getAppErrorDetails(e)));
  }
}

function* fetchInsuranceNextEndorsementsSaga(): Generator<
  StrictEffect,
  void,
  GetAllEndorsementsContract | IInsuranceEndorsements | IInsurance
> {
  try {
    const currentInsurance = <IInsurance>(yield selectState((state) => state.insurance));
    const currentEndorsements = <IInsuranceEndorsements>(
      yield selectState((state) => state.insuranceEndorsements)
    );
    if (currentEndorsements.data.total <= currentEndorsements.data.employees.length) {
      return;
    }
    let nextOffset =
      (currentEndorsements.fetchedFor.offset ? currentEndorsements.fetchedFor.offset : 0) + 10;
    const allFetchOptions = {
      ...currentEndorsements.fetchedFor,
      fetchFresh: false,
      offset: nextOffset,
    };
    if (allFetchOptions.search === '') {
      allFetchOptions.search = null;
    }
    yield put(
      insuranceEndorsementsFetching({
        ...currentEndorsements.fetchedFor,
        offset: nextOffset,
        fetchFresh: false,
      }),
    );
    const insuranceEndorsements = <GetAllEndorsementsContract>(
      yield AppSaga.getApi({
        url: prepareUrl(`/insurance/${currentEndorsements.fetchedFor.insuranceId}/endorsements`, {
          ...allFetchOptions,
          fetchFresh: null,
          limit: 10,
        }),
        responseSchema: GetInsuranceEndorsements,
      })
    );
    yield put(insuranceEndorsementsFetchSucceeded(insuranceEndorsements, false));
  } catch (e) {
    yield put(insuranceEndorsementsFetchFailed(getAppErrorDetails(e)));
    console.error(e);
  }
}

function* purchaseEndorsement(
  action: IPurchaseInsuranceEndorsements,
): Generator<StrictEffect, void, IInsuranceEndorsements | GetAllEndorsementsContract> {
  try {
    const insuranceEndorsements = <IInsuranceEndorsements>(
      yield selectState((state) => state.insuranceEndorsements)
    );

    if (
      !insuranceEndorsements.data ||
      insuranceEndorsements.data.summary.totalMissingEmployeeDetails > 0
    ) {
      console.log('There are still some missing employee details');
      return;
    }

    yield put(purchasingEndorsements());

    yield AppSaga.postApi({
      url: `/insurance/${insuranceEndorsements.fetchedFor.insuranceId}/endorsements/purchase`,
      responseSchema: {},
      requestData: {
        totalAmount: insuranceEndorsements.data.summary.totalAmount,
      },
    });

    yield put(purchaseEndorsementSucceeded());
  } catch (e) {
    yield put(purchaseEndorsementFailed(getAppErrorDetails(e)));
    console.error(e);
  }
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_INSURANCE_ENDORSEMENTS, fetchInsuranceEndorsementsSaga);
  yield takeLeading(
    ActionTypes.FETCH_NEXT_INSURANCE_ENDORSEMENTS,
    fetchInsuranceNextEndorsementsSaga,
  );
  yield takeLeading(ActionTypes.PURCHASE_ENDORSEMENTS, purchaseEndorsement);
}
