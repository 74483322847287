import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetKnitHrmsIntegrationSchemaContract {
  enabled: boolean;
  is_user_data_sync_enabled: boolean;
  user_data_sync_status: string | null;
  synced_fields: {
    field: string;
    name: string;
    [k: string]: unknown;
  }[];
  available_fields: {
    field: string;
    name: string;
    is_mandatory?: boolean;
    [k: string]: unknown;
  }[];
  employee_types_full_time: string[];
  employee_types_contractor: string[];
  employee_inactive_statuses: string[];
  hrms_provider: string;
  integration_name: string;
  last_synced_at?: string | null;
  sync_frequency: {
    duration: number;
    unit: string;
    [k: string]: unknown;
  };
  entity_name?: string | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    enabled: { type: 'boolean' },
    is_user_data_sync_enabled: { type: 'boolean' },
    user_data_sync_status: { type: ['string', 'null'] },
    synced_fields: {
      type: 'array',
      items: {
        type: 'object',
        properties: { field: { type: 'string' }, name: { type: 'string' } },
        required: ['field', 'name'],
      },
    },
    available_fields: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          field: { type: 'string' },
          name: { type: 'string' },
          is_mandatory: { type: 'boolean' },
        },
        required: ['field', 'name'],
      },
    },
    employee_types_full_time: { type: 'array', items: { type: 'string' } },
    employee_types_contractor: { type: 'array', items: { type: 'string' } },
    employee_inactive_statuses: { type: 'array', items: { type: 'string' } },
    hrms_provider: { type: 'string' },
    integration_name: { type: 'string' },
    last_synced_at: { type: ['string', 'null'] },
    sync_frequency: {
      type: 'object',
      properties: { duration: { type: 'number' }, unit: { type: 'string' } },
      required: ['duration', 'unit'],
    },
    entity_name: { type: ['string', 'null'] },
  },
  required: [
    'enabled',
    'is_user_data_sync_enabled',
    'user_data_sync_status',
    'synced_fields',
    'available_fields',
    'employee_types_full_time',
    'employee_types_contractor',
    'employee_inactive_statuses',
    'hrms_provider',
    'integration_name',
    'sync_frequency',
  ],
};
