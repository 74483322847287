import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetInsurancePricingDataContract {
  Starter: {
    planId: number;
    planName: string;
    startingPrice: number;
    [k: string]: unknown;
  };
  Essential: {
    planId: number;
    planName: string;
    startingPrice: number;
    [k: string]: unknown;
  };
  Premium: {
    planId: number;
    planName: string;
    startingPrice: number;
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    Starter: {
      type: 'object',
      properties: {
        planId: { type: 'number' },
        planName: { type: 'string' },
        startingPrice: { type: 'number' },
      },
      required: ['planId', 'planName', 'startingPrice'],
    },
    Essential: {
      type: 'object',
      properties: {
        planId: { type: 'number' },
        planName: { type: 'string' },
        startingPrice: { type: 'number' },
      },
      required: ['planId', 'planName', 'startingPrice'],
    },
    Premium: {
      type: 'object',
      properties: {
        planId: { type: 'number' },
        planName: { type: 'string' },
        startingPrice: { type: 'number' },
      },
      required: ['planId', 'planName', 'startingPrice'],
    },
  },
  required: ['Starter', 'Essential', 'Premium'],
};
