import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface JibbleAttendanceDataSchemaContract {
  success: boolean;
  message?: string;
  attendance_data: {
    current_page: number;
    data: {
      id: number;
      name: string | null;
      email: string;
      jibble_member_code: string | null;
      jibble_people_id: string | null;
      organization_id: number;
      people_id: number;
      people_organization_id: number;
      payroll_month: string;
      payroll_staging_id: number | null;
      lop_amount: number;
      created_at: string;
      deleted_at: string | null;
      attendance_data: {
        addedAsDeduction: boolean;
        attendance: {
          absentDays: unknown[];
          dailyPayrollHours: {
            [k: string]: unknown;
          };
          halfAbsentDays: unknown[];
          holidays: unknown[];
          restDays: unknown[];
          unpaidFullLeaveDays: unknown[];
          [k: string]: unknown;
        };
        employeeWorkingDays: number;
        fromDate: string;
        lopDays: number;
        manuallyUpdated: boolean;
        toDate: string;
        totalWorkingDays: number;
        syncTime: string;
        [k: string]: unknown;
      };
      payroll_attendance_data: {
        addedAsDeduction: boolean;
        attendance: {
          absentDays: unknown[];
          dailyPayrollHours: {
            [k: string]: unknown;
          };
          halfAbsentDays: unknown[];
          holidays: unknown[];
          restDays: unknown[];
          unpaidFullLeaveDays: unknown[];
          [k: string]: unknown;
        };
        employeeWorkingDays: number;
        fromDate: string;
        lopDays: number;
        manuallyUpdated: boolean;
        toDate: string;
        totalWorkingDays: number;
        syncTime: string;
        [k: string]: unknown;
      } | null;
      [k: string]: unknown;
    }[];
    first_page_url: string;
    from: number | null;
    last_page: number;
    last_page_url: string;
    links: {
      active: boolean;
      label: string;
      url: string | null;
      [k: string]: unknown;
    }[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number | null;
    total: number;
    [k: string]: unknown;
  };
  payroll_status: {
    attendanceId: number;
    payrollStagingId: number;
    isBlocked: boolean;
    isCanceled: boolean;
    isFinal: boolean;
    [k: string]: unknown;
  }[];
  payroll_month: string;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: ['object'],
  properties: {
    success: { type: ['boolean'] },
    message: { type: ['string'] },
    attendance_data: {
      type: ['object'],
      properties: {
        current_page: { type: 'integer' },
        data: {
          type: ['array'],
          uniqueItems: true,
          minItems: 0,
          items: {
            type: ['object'],
            properties: {
              id: { type: ['number'] },
              name: { type: ['string', 'null'] },
              email: { type: ['string'] },
              jibble_member_code: { type: ['string', 'null'] },
              jibble_people_id: { type: ['string', 'null'] },
              organization_id: { type: ['number'] },
              people_id: { type: ['number'] },
              people_organization_id: { type: ['number'] },
              payroll_month: { type: ['string'] },
              payroll_staging_id: { type: ['number', 'null'] },
              lop_amount: { type: ['number'] },
              created_at: { type: ['string'] },
              deleted_at: { type: ['string', 'null'] },
              attendance_data: {
                type: 'object',
                properties: {
                  addedAsDeduction: { type: 'boolean' },
                  attendance: {
                    type: 'object',
                    properties: {
                      absentDays: { type: 'array', minItems: 0 },
                      dailyPayrollHours: { type: 'object' },
                      halfAbsentDays: { type: 'array', minItems: 0 },
                      holidays: { type: 'array', minItems: 0 },
                      restDays: { type: 'array', minItems: 0 },
                      unpaidFullLeaveDays: { type: 'array', minItems: 0 },
                    },
                    required: [
                      'absentDays',
                      'dailyPayrollHours',
                      'halfAbsentDays',
                      'holidays',
                      'restDays',
                      'unpaidFullLeaveDays',
                    ],
                  },
                  employeeWorkingDays: { type: 'number' },
                  fromDate: { type: 'string' },
                  lopDays: { type: 'number' },
                  manuallyUpdated: { type: 'boolean' },
                  toDate: { type: 'string' },
                  totalWorkingDays: { type: 'number' },
                  syncTime: { type: 'string' },
                },
                required: [
                  'addedAsDeduction',
                  'attendance',
                  'employeeWorkingDays',
                  'fromDate',
                  'lopDays',
                  'manuallyUpdated',
                  'toDate',
                  'totalWorkingDays',
                  'syncTime',
                ],
              },
              payroll_attendance_data: {
                type: ['object', 'null'],
                properties: {
                  addedAsDeduction: { type: 'boolean' },
                  attendance: {
                    type: 'object',
                    properties: {
                      absentDays: { type: 'array', minItems: 0 },
                      dailyPayrollHours: { type: 'object' },
                      halfAbsentDays: { type: 'array', minItems: 0 },
                      holidays: { type: 'array', minItems: 0 },
                      restDays: { type: 'array', minItems: 0 },
                      unpaidFullLeaveDays: { type: 'array', minItems: 0 },
                    },
                    required: [
                      'absentDays',
                      'dailyPayrollHours',
                      'halfAbsentDays',
                      'holidays',
                      'restDays',
                      'unpaidFullLeaveDays',
                    ],
                  },
                  employeeWorkingDays: { type: 'number' },
                  fromDate: { type: 'string' },
                  lopDays: { type: 'number' },
                  manuallyUpdated: { type: 'boolean' },
                  toDate: { type: 'string' },
                  totalWorkingDays: { type: 'number' },
                  syncTime: { type: 'string' },
                },
                required: [
                  'addedAsDeduction',
                  'attendance',
                  'employeeWorkingDays',
                  'fromDate',
                  'lopDays',
                  'manuallyUpdated',
                  'toDate',
                  'totalWorkingDays',
                  'syncTime',
                ],
              },
            },
            required: [
              'id',
              'name',
              'email',
              'organization_id',
              'jibble_member_code',
              'jibble_people_id',
              'people_id',
              'people_organization_id',
              'payroll_month',
              'payroll_staging_id',
              'lop_amount',
              'created_at',
              'deleted_at',
              'attendance_data',
              'payroll_attendance_data',
            ],
          },
        },
        first_page_url: { type: 'string' },
        from: { type: ['integer', 'null'] },
        last_page: { type: 'integer' },
        last_page_url: { type: 'string' },
        links: {
          type: 'array',
          uniqueItems: true,
          minItems: 0,
          items: {
            type: ['object'],
            properties: {
              active: { type: 'boolean' },
              label: { type: 'string' },
              url: { type: ['string', 'null'] },
            },
            required: ['active', 'label', 'url'],
          },
        },
        next_page_url: { type: ['string', 'null'] },
        path: { type: 'string' },
        per_page: { type: 'integer' },
        prev_page_url: { type: ['string', 'null'] },
        to: { type: ['integer', 'null'] },
        total: { type: 'integer' },
      },
      required: [
        'current_page',
        'data',
        'first_page_url',
        'from',
        'last_page',
        'last_page_url',
        'links',
        'next_page_url',
        'path',
        'per_page',
        'prev_page_url',
        'to',
        'total',
      ],
    },
    payroll_status: {
      type: 'array',
      minItems: 0,
      items: {
        type: 'object',
        properties: {
          attendanceId: { type: 'number' },
          payrollStagingId: { type: 'number' },
          isBlocked: { type: 'boolean' },
          isCanceled: { type: 'boolean' },
          isFinal: { type: 'boolean' },
        },
        required: ['attendanceId', 'payrollStagingId', 'isBlocked', 'isCanceled', 'isFinal'],
      },
    },
    payroll_month: { type: 'string' },
  },
  required: ['success', 'attendance_data', 'payroll_status', 'payroll_month'],
};
