import PayrollAiAssistantPollSchema, {
  PayrollAiAssistantPollSchemaContract,
} from 'schemas/PayrollAiAssistantPollSchema';
import PayrollAiAssistantResponseSchema, {
  PayrollAiAssistantResponseSchemaContract,
} from 'schemas/PayrollAiAssistantResponseSchema';
import SuccessResponseSchema, { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import { getApi, postApi, putApi } from 'utils/Api';

interface GetLatestChatResponseRequestData {
  conversationKey: string;
  conversationCount: number;
}

interface UpdateUserPromptsRequestData {
  user_prompts: UserPromptRequest | null;
  direct_prompt?: string | null;
}

export interface UserPromptRequest {
  [key: string]: string;
}

export interface NonPayrollUserRequestData {
  type: number;
  input: string;
  "g-recaptcha-response": string;
}

export interface FeedbackRequestData {
  feedback: string;
}

export interface ShareRequestData {
  email: string;
}

// @TODO payroll-ai-assistant - replace with correct API endpoints and requestData, responseSchema types
const payrollAiAssistant = {
  getLatestChatResponse: ({
    conversationKey,
    conversationCount,
  }: GetLatestChatResponseRequestData) =>
    getApi<PayrollAiAssistantPollSchemaContract>({
      url: `/tax-optimizer?conversation_count=${conversationCount}`,
      responseSchema: PayrollAiAssistantPollSchema,
      headers: {
        'conversation-token' : conversationKey
      }
    }),
  updateUserPromptsAndRetry: (
    conversationKey: string,
    userPromptsData: UserPromptRequest | null,
    directPrompt: string | null,
  ) => {
    return putApi<PayrollAiAssistantResponseSchemaContract, UpdateUserPromptsRequestData>({
      url: `/tax-optimizer`,
      requestData: {
        user_prompts: userPromptsData,
        direct_prompt: directPrompt,
      },
      responseSchema: PayrollAiAssistantResponseSchema,
      headers: {
        'conversation-token' : conversationKey
      }
    });
  },
  initiateForPayrollUsers: () =>
    postApi<PayrollAiAssistantResponseSchemaContract, {}>({
      url: `/tax-optimizer`,
      requestData: {},
      responseSchema: PayrollAiAssistantResponseSchema,
    }),
  initiateForNonPayrollUsers: (ctc: string, captcha: string) =>
    postApi<PayrollAiAssistantResponseSchemaContract, NonPayrollUserRequestData>({
      url: `/tax-optimizer/public`,
      requestData: {
        "type" : 3,
        "input": ctc,
        "g-recaptcha-response" : captcha
      },
      responseSchema: PayrollAiAssistantResponseSchema,
    }),
  sendFeedback: (conversationKey: string, feedback: string) =>
    postApi<SuccessResponseSchemaContract, FeedbackRequestData>({
      url: `/tax-optimizer/feedback`,
      requestData: {
        feedback: feedback
      },
      responseSchema: SuccessResponseSchema,
      headers: {
        'conversation-token' : conversationKey
      }
    }),
  sendEmail: (conversationKey: string, email: string) =>
    postApi<SuccessResponseSchemaContract, ShareRequestData>({
      url: `/tax-optimizer/share`,
      requestData: {
        email: email
      },
      responseSchema: SuccessResponseSchema,
      headers: {
        'conversation-token' : conversationKey
      }
    }),
};

export { payrollAiAssistant };
