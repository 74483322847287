import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetArrearsSchemaContract {
  arrears: {
    name: string;
    type: string;
    amount: number;
    [k: string]: unknown;
  }[];
  platform_arrears?:
    | {
        name: string;
        type: string;
        amount: number;
        [k: string]: unknown;
      }[]
    | null;
  arrear_breakdown: {
    [k: string]: {
      name: string;
      type: string;
      amount: number;
      [k: string]: unknown;
    }[];
  } | null;
  salary_revision_breakdown?: {
    [k: string]: {
      name: string;
      type: string;
      amount: number;
      [k: string]: unknown;
    }[];
  } | null;
  rlop_breakdown?: {
    [k: string]: {
      name: string;
      type: string;
      amount: number;
      [k: string]: unknown;
    }[];
  } | null;
  compliance_arrears?:
    | {
        name: string;
        type: string;
        amount: number;
        [k: string]: unknown;
      }[]
    | null;
  is_system_calculated: boolean;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    arrears: {
      type: 'array',
      items: {
        type: ['object'],
        properties: {
          name: { type: 'string' },
          type: { type: 'string' },
          amount: { type: 'number' },
        },
        required: ['name', 'amount', 'type'],
      },
    },
    platform_arrears: {
      type: ['array', 'null'],
      items: {
        type: ['object'],
        properties: {
          name: { type: 'string' },
          type: { type: 'string' },
          amount: { type: 'number' },
        },
        required: ['name', 'amount', 'type'],
      },
    },
    arrear_breakdown: {
      type: ['object', 'null'],
      additionalProperties: {
        type: 'array',
        items: {
          type: ['object'],
          properties: {
            name: { type: 'string' },
            type: { type: 'string' },
            amount: { type: 'number' },
          },
          required: ['name', 'amount', 'type'],
        },
      },
    },
    salary_revision_breakdown: {
      type: ['object', 'null'],
      additionalProperties: {
        type: 'array',
        items: {
          type: ['object'],
          properties: {
            name: { type: 'string' },
            type: { type: 'string' },
            amount: { type: 'number' },
          },
          required: ['name', 'amount', 'type'],
        },
      },
    },
    rlop_breakdown: {
      type: ['object', 'null'],
      additionalProperties: {
        type: 'array',
        items: {
          type: ['object'],
          properties: {
            name: { type: 'string' },
            type: { type: 'string' },
            amount: { type: 'number' },
          },
          required: ['name', 'amount', 'type'],
        },
      },
    },
    compliance_arrears: {
      type: ['array', 'null'],
      items: {
        type: ['object'],
        properties: {
          name: { type: 'string' },
          type: { type: 'string' },
          amount: { type: 'number' },
        },
        required: ['name', 'amount', 'type'],
      },
    },
    is_system_calculated: { type: 'boolean' },
  },
  required: ['arrears', 'arrear_breakdown', 'is_system_calculated'],
};
