import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import { fetchNotifications } from '../../../reducers/notifications';
import '../tailwind.css';
import { NotificationIcon } from './components/NotificationIcon';
import { NotificationsList } from './components/NotificationList';
import styles from './index.module.scss';

export function Notification() {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const navBarClass = drawerOpened ? styles.NotificationActive : '';
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  // let hasUnreadNotifications = false;
  // const unreadQuery = useQuery('checkRead', () => {
  //   if(id) {
  //     return api.notifications.fetchUnreadNotifications(id);
  //   }
  // })

  // if(unreadQuery.status === 'success' && unreadQuery.data) {
  //   if(unreadQuery.data.unread === true) {

  //     hasUnreadNotifications = true;
  //   }
  // }

  async function sendNotificationInstrumentationEvent() {
    if (!drawerOpened) {
      try {
        const response = await api.analytics.push({
          eventName: 'notifications.clicked',
          eventType: 'viewed',
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleClickOutside = (event: Event) => {
    if (
      ref &&
      ref.current &&
      event.target instanceof HTMLElement &&
      !ref.current.contains(event.target)
    ) {
      setDrawerOpened(false);
    }
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  const toggleDrawer = () => {
    setDrawerOpened(!drawerOpened);
    sendNotificationInstrumentationEvent();
  };

  return (
    <div ref={ref} className='h-full'>
      <span className={'header--component-item ' + navBarClass} onClick={toggleDrawer}>
        <NotificationIcon />
      </span>
      {drawerOpened && (
        <div
          className={`${styles.NotificationList} shadow-xl  z-10 bg-greyOne fixed left-0 w-screen h-screen  md:right-0 md:left-auto md:w-144 md:absolute md:max-h-192`}>
          <div className="max-h-192 overflow-auto">
            <div className="bg-hover-grey text-white-o-80 px-10 py-6 tracking-wider text-2xl">
              NOTIFICATIONS
            </div>
            <NotificationsList handleCloseNotification={handleClickOutside} />
          </div>
          <div className={styles['gradientClass']}></div>
        </div>
      )}
    </div>
  );
}

export default function NotificationContainer() {
  return <Notification />;
}
