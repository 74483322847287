import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetPayrollSetupSchemaContract {
  payrollEnabled: boolean;
  payoutDate: number | null;
  autoExecutePayroll: boolean;
  hasFinalizedAPayroll: boolean;
  nextPayrollMonthToFinalize: string;
  payrollCycle: {
    startDate: number;
    endDate: number;
    [k: string]: unknown;
  };
  payrollInputCycle: {
    startDate: number;
    endDate: number;
    [k: string]: unknown;
  };
  effectiveFromMonth?: string | null;
  upcomingCycles: {
    payrollCycle?: {
      startDate: number;
      endDate: number;
      [k: string]: unknown;
    };
    payrollInputCycle?: {
      startDate: number;
      endDate: number;
      [k: string]: unknown;
    };
    effectiveFromMonth?: string | null;
    newJoinerArrearEnabled?: boolean;
    newJoinerCutoffDate?: number | null;
    [k: string]: unknown;
  } | null;
  newJoinerArrearEnabled: boolean;
  newJoinerCutoffDate: number | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    payrollEnabled: { type: 'boolean' },
    payoutDate: { type: ['number', 'null'] },
    autoExecutePayroll: { type: 'boolean' },
    hasFinalizedAPayroll: { type: 'boolean' },
    nextPayrollMonthToFinalize: { type: ['string'], format: 'date' },
    payrollCycle: {
      type: 'object',
      title: '',
      properties: { startDate: { type: 'number' }, endDate: { type: 'number' } },
      required: ['startDate', 'endDate'],
    },
    payrollInputCycle: {
      type: 'object',
      title: '',
      properties: { startDate: { type: 'number' }, endDate: { type: 'number' } },
      required: ['startDate', 'endDate'],
    },
    effectiveFromMonth: { type: ['string', 'null'], format: 'date' },
    upcomingCycles: {
      type: ['object', 'null'],
      properties: {
        payrollCycle: {
          type: 'object',
          title: '',
          properties: { startDate: { type: 'number' }, endDate: { type: 'number' } },
          required: ['startDate', 'endDate'],
        },
        payrollInputCycle: {
          type: 'object',
          title: '',
          properties: { startDate: { type: 'number' }, endDate: { type: 'number' } },
          required: ['startDate', 'endDate'],
        },
        effectiveFromMonth: { type: ['string', 'null'], format: 'date' },
        newJoinerArrearEnabled: { type: 'boolean' },
        newJoinerCutoffDate: { type: ['number', 'null'], minimum: 1, maximum: 31 },
      },
    },
    newJoinerArrearEnabled: { type: 'boolean' },
    newJoinerCutoffDate: { type: ['number', 'null'], minimum: 1, maximum: 31 },
  },
  required: [
    'payrollEnabled',
    'payoutDate',
    'autoExecutePayroll',
    'hasFinalizedAPayroll',
    'nextPayrollMonthToFinalize',
    'payrollCycle',
    'payrollInputCycle',
    'upcomingCycles',
    'newJoinerArrearEnabled',
    'newJoinerCutoffDate',
  ],
};
