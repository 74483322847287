import TaxDeclarationSettingsSchema, { TaxDeclarationSettingsSchemaContract } from '../schemas/TaxDeclarationSettingsSchema';
import { getApi, postApi, deleteApi } from '../utils/Api';
import { GenericDeclaration, WindowPayloadType } from 'components/ui/DeclarationWindow/types';
import { WindowTarget, WindowType } from '../components/Settings/TaxDeclarationAndProofSettings/types';
import SuccessResponseSchema, { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import { GetCustomWindowResponse } from 'components/ui/CustomWindow/types';

export interface WindowRequestData {
    windowType?: 'declaration' | 'proof',
    windowObject?: WindowPayloadType,
    windowTarget: keyof WindowTarget,
    customWindows?: {
        type: string;
        startDate: string;
        endDate: string;
        employeeEmails: Array<string>;
    }
}

const apis = {
    getSettings: () => {
        return getApi<TaxDeclarationSettingsSchemaContract>({
            url: `/settings/it_declaration`,
            responseSchema: TaxDeclarationSettingsSchema,
        });
    },

    updateSettings: (requestData: TaxDeclarationSettingsSchemaContract) => {
        return postApi<TaxDeclarationSettingsSchemaContract, TaxDeclarationSettingsSchemaContract>({
            url: `/settings/it_declaration`,
            requestData: requestData,
            responseSchema: TaxDeclarationSettingsSchema
        })
    },

    getGenericWindow: (windowType: WindowType) => {
        return getApi<GenericDeclaration>({
            url: `/it_declaration/declaration_and_proof_window/generic?windowType=${windowType}`,
            responseSchema: {},
        });
    },

    createUpdateWindow: (requestData: WindowRequestData) => {
        return postApi<SuccessResponseSchemaContract, WindowRequestData>({
            url: `/it_declaration/declaration_and_proof_window`,
            requestData: requestData,
            responseSchema: SuccessResponseSchema
        });
    },

    fetchCustomWindow: () => {
        return getApi<GetCustomWindowResponse>({
            url: `/it_declaration/declaration_and_proof_window/custom`,
            responseSchema: {}
        })
    },

    deleteCustomWindow: (windowIds: number[]) => {
        return deleteApi<SuccessResponseSchemaContract, { windowIds: number[] }>({
            url: `/it_declaration/declaration_and_proof_window/custom`,
            requestData: { windowIds: windowIds },
            responseSchema: SuccessResponseSchema
        })
    }

}

export default apis;