import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface CreateWorkflowSchemaContract {
  id: number;
  action_type: number;
  organization_id: number;
  status: number;
  version: number;
  created_by: number;
  created_at: number;
  updated_at: number;
  approvers: {
    levels: {
      level: number;
      levelId: string;
      peopleIds: number[];
      minApprovals: number;
      [k: string]: unknown;
    }[];
    [k: string]: unknown;
  };
  people_metadata: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      people_id: number;
      employee_type: string;
      employee_id: string;
      name: string;
      id_prefix: string | null;
      [k: string]: unknown;
    };
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    id: { type: 'number' },
    action_type: { type: 'number' },
    organization_id: { type: 'number' },
    status: { type: 'number' },
    version: { type: 'number' },
    created_by: { type: 'number' },
    created_at: { type: 'number' },
    updated_at: { type: 'number' },
    approvers: {
      type: 'object',
      properties: {
        levels: {
          type: 'array',
          minItems: 0,
          items: {
            type: 'object',
            properties: {
              level: { type: 'number' },
              levelId: { type: 'string' },
              peopleIds: {
                type: 'array',
                uniqueItems: true,
                minItems: 0,
                items: { type: 'number' },
              },
              minApprovals: { type: 'number' },
            },
            required: ['level', 'levelId', 'peopleIds', 'minApprovals'],
          },
        },
      },
      required: ['levels'],
    },
    people_metadata: {
      type: 'object',
      patternProperties: {
        '.*': {
          type: 'object',
          properties: {
            people_id: { type: 'number' },
            employee_type: { type: 'string' },
            employee_id: { type: 'string' },
            name: { type: 'string' },
            id_prefix: { type: ['string', 'null'] },
          },
          required: ['people_id', 'employee_type', 'employee_id', 'name', 'id_prefix'],
        },
      },
    },
  },
  required: [
    'id',
    'action_type',
    'organization_id',
    'status',
    'version',
    'created_by',
    'created_at',
    'updated_at',
    'approvers',
    'people_metadata',
  ],
};
