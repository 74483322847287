export default function shallowCompareObjects<T extends Object>(item1: T, item2: T) {
  if (typeof item1 === 'object' && typeof item2 === 'object') {
    const map = new Map(Object.entries(item2));
    for (const [key, value] of Object.entries(item1)) {
      if (value !== map.get(key)) {
        return false;
      }
    }
  } else {
    return false;
  }

  return true;
}
