import { postApi } from 'utils/Api';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';

export type SsoOnboardingRequestContract = {
  employee_count: string;
  job_title: string;
  brand_name: string;
};


export type PanDetailsVerificationRequestContract = {
  company_name: string;
  pan: string;
  type: number;
};

const onboarding = {
  updateSsoOnboardingData: (requestData: SsoOnboardingRequestContract) =>
    postApi<SuccessResponseSchemaContract, SsoOnboardingRequestContract>({
      url: 'onboarding/update-onboarding-data-post-sso',
      responseSchema: SuccessResponseSchema,
      requestData,
    }),

  updatePanDetailsAndVerify: (requestData: PanDetailsVerificationRequestContract) =>
    postApi<SuccessResponseSchemaContract, PanDetailsVerificationRequestContract>({
      url: 'organization/update-pan-details-and-verify',
      responseSchema: SuccessResponseSchema,
      requestData,
    }),
};

export default onboarding;
