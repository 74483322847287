import { AdvancedWindow } from 'components/Settings/FlexibleBenefitsSettings/types';
import { DeclarationWindowType } from 'components/ui/DeclarationWindow/types';
import { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import { deleteApi, getApi, patchApi, postApi } from 'utils/Api';

export interface UpdateDeclarataionsPayload {
  allowances: Array<{
    name: string;
    declaredAmount: number;
    isSelected: boolean;
  }>;
}

export interface GetFlexiBenefitsSettingsResponse {
  shouldHoldFlexiAmount: boolean;
  employeeCanDeclareFlexiBenefits: boolean;
  employeeCanUpdateAmountInDeclaredFlexiBenefits: boolean;
  fbpDefaultTaxableSettingAutoTurnOn?: null | string;
  shouldHoldFlexiAmountSettingAutoTurnOff: null | boolean;
  employeeDeclarationWindow: {
    type: DeclarationWindowType;
    dates?: {
      startDate: string;
      endDate: string;
    }[];
  };
  employeeProofUploadWindow: {
    type: DeclarationWindowType;
    dates?: {
      startDate: string;
      endDate: string;
    }[];
  };
  FbpDefaultTaxable: boolean;
  autoOpenDeclarationWindowForNewEmployees?: null | string;
  autoOpenDeclarationWindowForSalaryRevisedEmployees?: null | string;
  allowProofsUploadTillDismissal?: boolean;
}

export interface GetDeclarationsResponse {
  allowances: Array<{
    name: string;
    maxLimit: number;
    declaredAmount: number;
    proratedAmount?: number;
    isSelected?: boolean;
  }>;
  isDeclarationWindowOpen: boolean;
  estimatedTaxSaving: number;
  messageRegardingDeclarationWindow?: string;
  settings: GetFlexiBenefitsSettingsResponse;
}

type GetExceptionWindowResponse = Array<AdvancedWindow>;

export const apis = {
  getDeclarations: (userOrg: string | number) => {
    return getApi<GetDeclarationsResponse>({
      url: `/user-org/${userOrg}/flexible-benefits/declaration`,
      responseSchema: {},
    });
  },
  updateDeclarations: ({
    allowances,
    userOrg,
  }: UpdateDeclarataionsPayload & { userOrg: string | number }) => {
    return postApi<SuccessResponseSchemaContract, UpdateDeclarataionsPayload>({
      url: `/user-org/${userOrg}/flexible-benefits/declaration`,
      requestData: { allowances },
      responseSchema: {},
    });
  },
  computeTaxSavings: ({
    allowances,
    userOrg,
  }: UpdateDeclarataionsPayload & { userOrg: string | number }) => {
    return postApi<
      SuccessResponseSchemaContract & { estimatedTaxSavings: number },
      UpdateDeclarataionsPayload
    >({
      url: `/user-org/${userOrg}/flexible-benefits/estimate-tax-savings`,
      requestData: { allowances },
      responseSchema: {},
    });
  },
  getSettings: (org: string | number) => {
    return getApi<GetFlexiBenefitsSettingsResponse>({
      url: `/organization/${org}/flexible-benefits/settings`,
      responseSchema: {},
    });
  },
  updateSettings: ({
    org,
    settings,
  }: {
    org: string | number;
    settings: Partial<GetFlexiBenefitsSettingsResponse>;
  }) => {
    return patchApi<
      GetFlexiBenefitsSettingsResponse,
      { settings: Partial<GetFlexiBenefitsSettingsResponse> }
    >({
      url: `/organization/${org}/flexible-benefits/settings`,
      requestData: { settings },
      responseSchema: {},
    });
  },
  getExceptionWindowSettings: (org: string | number) => {
    return getApi<GetExceptionWindowResponse>({
      url: `/organization/${org}/flexible-benefits/advanced-settings`,
      responseSchema: {},
    });
  },
  addExceptionWindowSettings: ({
    org,
    data,
  }: {
    org: string | number;
    data: {
      type: string;
      startDate: string;
      endDate: string;
      employeeEmails: Array<string>;
    };
  }) => {
    return postApi<
      GetExceptionWindowResponse,
      {
        type: string;
        startDate: string;
        endDate: string;
        employeeEmails: Array<string>;
      }
    >({
      url: `/organization/${org}/flexible-benefits/advanced-settings`,
      responseSchema: {},
      requestData: data,
    });
  },
  updateExceptionWindowSettings: ({ org, data }: { org: string | number; data: [] }) => {
    return patchApi<GetExceptionWindowResponse, { data: GetExceptionWindowResponse }>({
      url: `/organization/${org}/flexible-benefits/advanced-settings`,
      responseSchema: {},
      requestData: { data },
    });
  },
  deleteExceptionWindowSetting: (args: { org: string | number; ids: Array<string | number> }) => {
    const idAsString = args.ids.reduce((a, c) => `${a}ids[]=${c}&`, '');
    const urlParams = new URLSearchParams(idAsString as string);
    return deleteApi<SuccessResponseSchemaContract, any>({
      url: `/organization/${args.org}/flexible-benefits/advanced-settings?${urlParams.toString()}`,
      responseSchema: {},
    });
  },
};

export default apis;
