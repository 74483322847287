export const startRecording = (hotjarLabel: string, tags: string[] = []) => {
  // Trigger hotjar recording
  // Docs - https://help.hotjar.com/hc/en-us/articles/115015712548-How-to-Use-JavaScript-Triggers-to-Start-Recordings
  const hj = (window as any).hj;
  if (hj) {
    hj('trigger', hotjarLabel);
    const defaultTags: string[] = [];
    hj('tagRecording', defaultTags.concat(tags));
  }
};

export const identifyHotjar = (userId: string, userAttributes: { is_admin: boolean, org_id: string }) => {
  const hj = (window as any).hj;
  if (hj) {
    hj('identify', userId, userAttributes);
  }
}
