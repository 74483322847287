import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface ZohoSettingsContract {
  enabled: boolean;
  is_user_data_sync_enabled: boolean;
  last_fetched_at: string | null;
  user_data_sync_status:
    | 'STATUS_SYNC_IN_PROGRESS'
    | 'STATUS_SYNC_FAILURE'
    | 'STATUS_SYNC_SUCCESSFULL'
    | 'STATUS_SYNC_INITIATED'
    | null;
  synced_fields: {
    field: string;
    name: string;
    [k: string]: unknown;
  }[];
  active_integration: {
    type: number;
    name: string;
    [k: string]: unknown;
  } | null;
  all_fields?: {
    field: string;
    name: string;
    [k: string]: unknown;
  }[];
  available_fields?:
    | {
        Options?:
          | {
              /**
               * This interface was referenced by `undefined`'s JSON-Schema definition
               * via the `patternProperty` ".*".
               */
              [k: string]:
                | {
                    Id?: string;
                    Value?: string;
                    [k: string]: unknown;
                  }
                | string;
            }
          | null
          | unknown[];
        labelname: string;
        displayname?: string;
        xpayrollFieldType: string;
        xpayrollFieldName: string;
        isMandatoryXPayrollFieldToSync: boolean;
        [k: string]: unknown;
      }[]
    | null;
  employee_types_full_time: string[];
  employee_active_statuses: string[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    enabled: { type: 'boolean' },
    is_user_data_sync_enabled: { type: ['boolean'] },
    last_fetched_at: { type: ['string', 'null'], format: 'date-time' },
    user_data_sync_status: {
      type: ['string', 'null'],
      enum: [
        'STATUS_SYNC_IN_PROGRESS',
        'STATUS_SYNC_FAILURE',
        'STATUS_SYNC_SUCCESSFULL',
        'STATUS_SYNC_INITIATED',
        null,
      ],
    },
    synced_fields: {
      type: ['array'],
      items: {
        type: ['object'],
        properties: { field: { type: 'string' }, name: { type: 'string' } },
        required: ['field', 'name'],
      },
    },
    active_integration: {
      type: ['object', 'null'],
      properties: { type: { type: 'integer' }, name: { type: 'string' } },
      required: ['type', 'name'],
    },
    all_fields: {
      type: ['array'],
      items: {
        type: ['object'],
        properties: { field: { type: 'string' }, name: { type: 'string' } },
        required: ['field', 'name'],
      },
    },
    available_fields: {
      type: ['array', 'null'],
      items: {
        type: 'object',
        properties: {
          Options: {
            type: ['object', 'null', 'array'],
            patternProperties: {
              '.*': {
                type: ['object', 'string'],
                properties: { Id: { type: 'string' }, Value: { type: 'string' } },
              },
            },
          },
          labelname: { type: 'string' },
          displayname: { type: 'string' },
          xpayrollFieldType: { type: 'string' },
          xpayrollFieldName: { type: 'string' },
          isMandatoryXPayrollFieldToSync: { type: 'boolean' },
        },
        required: [
          'labelname',
          'xpayrollFieldType',
          'xpayrollFieldName',
          'isMandatoryXPayrollFieldToSync',
        ],
      },
    },
    employee_types_full_time: { type: 'array', items: { type: 'string' } },
    employee_active_statuses: { type: 'array', items: { type: 'string' } },
  },
  required: [
    'enabled',
    'is_user_data_sync_enabled',
    'last_fetched_at',
    'user_data_sync_status',
    'synced_fields',
    'active_integration',
    'employee_types_full_time',
    'employee_active_statuses',
  ],
};
