import { CreateCustomReportApiSchemaContract } from '../schemas/CreateCustomReportApiSchema';
import GetCustomReportConfigSchema, {
  GetCustomReportConfigSchemaContract,
} from '../schemas/GetCustomReportConfigSchema';
import { GetCustomReportSchemaContract } from '../schemas/GetCustomReportSchema';
import { getApi, postApi } from '../utils/Api';
export interface getAuthSessionTokenApiResponse {
  sessionToken: string;
}

export type GetCustomReportPayload = {
  filters: { key: string; value: string }[];
  type: number | string | null;
  page: number;
  sortByKey: null | string;
  sortByOrder: null | string;
  limit: number;
};

export type CreateCustomReportPayload = {
  type: string | null;
  inputs: { key: string; value: string }[];
};

export function getCustomReport({
  type,
  limit = 10,
  page = 1,
  sortByKey,
  sortByOrder,
  filters,
}: GetCustomReportPayload) {
  return postApi<GetCustomReportSchemaContract, GetCustomReportPayload>({
    url: `get-custom-reports`,
    responseSchema: {},
    requestData: {
      filters,
      type,
      limit,
      page,
      sortByKey,
      sortByOrder,
    },
  });
}

export function getCustomReportConfig(type: number | string | null) {
  return getApi<GetCustomReportConfigSchemaContract>({
    url: `custom-reports-config?type=${type}`,
    responseSchema: GetCustomReportConfigSchema,
  });
}

export function createCustomReport(requestData: CreateCustomReportPayload) {
  return postApi<CreateCustomReportApiSchemaContract, CreateCustomReportPayload>({
    url: `custom-reports`,
    responseSchema: {},
    requestData,
  });
}
