import { getApi } from 'utils/Api';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';

export default {
  fetchUnreadNotifications: () => {
    return getApi<SuccessResponseSchemaContract>({
      url: `/notifications/checkUnreadNotifications`,
      responseSchema: SuccessResponseSchema,
    });
  },
};
