import { data } from 'jquery';
import { useHistory } from 'react-router-dom';
import { GetInsuranceApiSchemaContract } from '../../schemas/GetInsuranceApiSchema';
import { IAppErrorDetails } from '../../utils/AppError';

export interface IInsurance {
  hasError: boolean;
  data: GetInsuranceApiSchemaContract | null;
  errorData: IAppErrorDetails | null;
  isFetching: boolean;
  insufficientBalance: boolean;
}

export enum ActionTypes {
  INSURANCE_FETCH_SUCCEEDED = 'INSURANCE_FETCH_SUCCEEDED',
  INSURANCE_FETCH_FAILED = 'INSURANCE_FETCH_FAILED',
  FETCH_INSURANCE = 'FETCH_INSURANCE',
  INSURANCE_FETCHING = 'INSURANCE_FETCHING',
  INSURANCE_START = 'INSURANCE_START',
  INSURANCE_UPDATE = 'INSURANCE_UPDATE',
  INSURANCE_UPDATE_AND_REDIRECT = 'INSURANCE_UPDATE_AND_REDIRECT',
  PURCHASE_INSURANCE = 'PURCHASE_INSURANCE',
  INSURANCE_PURCHASE_FAILED = 'INSURANCE_PURCHASE_FAILED',
  INSURANCE_RESET = 'INSURANCE_RESET',
  INSURANCE_RESET_DEFAULT = 'INSURANCE_RESET_DEFAULT'
}

const initialState: IInsurance = {
  hasError: false,
  data: null,
  errorData: null,
  isFetching: false,
  insufficientBalance: false,
};

type Actions =
  | IInsuranceFetchSucceeded
  | IInsuranceFetchFailed
  | IInsuranceFetching
  | IInsurancePurchaseFailed
  | IInsuranceResetToDefault;

export function insurance(state = initialState, action: Actions): IInsurance {
  switch (action.type) {
    case ActionTypes.INSURANCE_FETCHING:
      return { ...state, isFetching: true, insufficientBalance: false };
    case ActionTypes.INSURANCE_FETCH_SUCCEEDED:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        hasError: false,
        errorData: null,
        isFetching: false,
        insufficientBalance: false,
      };
    case ActionTypes.INSURANCE_FETCH_FAILED:
      return {
        ...state,
        data: null,
        hasError: true,
        errorData: action.payload,
        isFetching: false,
        insufficientBalance: false,
      };
    case ActionTypes.INSURANCE_PURCHASE_FAILED:
      if (action.payload.code === 'INSUFFICIENT_BALANCE') {
        return { ...state, insufficientBalance: true };
      }
      return {
        ...state,
        data: null,
        hasError: true,
        errorData: action.payload,
        isFetching: false,
        insufficientBalance: false,
      };
    case ActionTypes.INSURANCE_RESET_DEFAULT:
      return {
        ...state,
        data:null,
        hasError: false,
        errorData: null,
        isFetching: false,
        insufficientBalance: false
      };
    default:
      return state;
  }
}

/** Set generic error */

export interface IInsuranceFetchSucceeded {
  type: typeof ActionTypes.INSURANCE_FETCH_SUCCEEDED;
  payload: GetInsuranceApiSchemaContract;
}

export const insuranceFetchSucceeded = (
  payload: GetInsuranceApiSchemaContract,
): IInsuranceFetchSucceeded => ({
  type: ActionTypes.INSURANCE_FETCH_SUCCEEDED,
  payload,
});

/** Reset generic error */

export interface IInsuranceFetchFailed {
  type: typeof ActionTypes.INSURANCE_FETCH_FAILED;
  payload: IAppErrorDetails;
}

export const insuranceFetchFailed = (payload: IAppErrorDetails): IInsuranceFetchFailed => ({
  type: ActionTypes.INSURANCE_FETCH_FAILED,
  payload,
});

/** Mark insurance in fetching state */

export interface IFetchInsurance {
  type: typeof ActionTypes.FETCH_INSURANCE;
  payload: { organizationId: number | null };
}

export const fetchInsurance = (organizationId: number | null): IFetchInsurance => ({
  type: ActionTypes.FETCH_INSURANCE,
  payload: {
    organizationId,
  },
});

export interface IInsuranceFetching {
  type: typeof ActionTypes.INSURANCE_FETCHING;
}

export const insuranceFetching = (): IInsuranceFetching => ({
  type: ActionTypes.INSURANCE_FETCHING,
});

/** Start exploring insurance */

export interface IStartInsurance {
  type: typeof ActionTypes.INSURANCE_START;
  payload: {
    organizationId: number;
    history: ReturnType<typeof useHistory>;
  };
}

export const startInsurance = (
  organizationId: number,
  history: ReturnType<typeof useHistory>,
): IStartInsurance => ({
  type: ActionTypes.INSURANCE_START,
  payload: {
    organizationId,
    history,
  },
});

export interface IUpdateInsurancePayload
  extends Partial<
    Pick<
      GetInsuranceApiSchemaContract,
      | 'status'
      | 'planId'
      | 'sumInsured'
      | 'withChildrenAndSpouse'
      | 'withParentsOrInLaws'
      | 'shouldCoverEmployeeFromEsi'
      | 'registeredInterestAt'
    >
  > {}

export interface IUpdateInsurance {
  type: ActionTypes.INSURANCE_UPDATE;
  payload: {
    insuranceId: number;
    payload: IUpdateInsurancePayload;
  };
}

export const updateInsurance = (
  insuranceId: number,
  payload: IUpdateInsurancePayload,
): IUpdateInsurance => ({
  type: ActionTypes.INSURANCE_UPDATE,
  payload: {
    insuranceId,
    payload,
  },
});

export interface IUpdateInsuranceAndRedirect {
  type: ActionTypes.INSURANCE_UPDATE_AND_REDIRECT;
  payload: {
    insuranceId: number;
    route: string;
    payload: IUpdateInsurancePayload;
    history: ReturnType<typeof useHistory>;
  };
}

export const updateInsuranceAndRedirect = (
  insuranceId: number,
  payload: IUpdateInsurancePayload,
  history: ReturnType<typeof useHistory>,
  route: string,
): IUpdateInsuranceAndRedirect => ({
  type: ActionTypes.INSURANCE_UPDATE_AND_REDIRECT,
  payload: {
    insuranceId,
    payload,
    route,
    history,
  },
});

export interface IPurchaseInsurance {
  type: ActionTypes.PURCHASE_INSURANCE;
  payload: {
    insuranceId: number;
  };
}

export const purchaseInsurance = (insuranceId: number): IPurchaseInsurance => ({
  type: ActionTypes.PURCHASE_INSURANCE,
  payload: {
    insuranceId,
  },
});


export interface IInsurancePurchaseFailed {
  type: typeof ActionTypes.INSURANCE_PURCHASE_FAILED;
  payload: IAppErrorDetails;
}

export interface IInsuranceReset {
  type: typeof ActionTypes.INSURANCE_RESET;
}

export interface IInsuranceResetToDefault {
  type: typeof ActionTypes.INSURANCE_RESET_DEFAULT;
}

export const insurancePurchaseFailed = (payload: IAppErrorDetails): IInsurancePurchaseFailed => ({
  type: ActionTypes.INSURANCE_PURCHASE_FAILED,
  payload,
});

export const resetInsurance = (): IInsuranceReset =>{
  console.log('INSURANCE_RESET');
  return { type : ActionTypes.INSURANCE_RESET };
}

export const resetInsuranceToDefault = ():IInsuranceResetToDefault => ({
  type: ActionTypes.INSURANCE_RESET_DEFAULT
})