import { useHistory } from 'react-router-dom';
import { GetInsuranceEstimateContract } from '../../schemas/GetInsuranceEstimate';
import { IAppErrorDetails } from '../../utils/AppError';

export interface IInsuranceEstimate {
  hasError: boolean;
  data: GetInsuranceEstimateContract | null;
  errorData: IAppErrorDetails | null;
  isFetching: boolean;
}

export enum ActionTypes {
  INSURANCE_ESTIMATE_FETCH_SUCCEEDED = 'INSURANCE_ESTIMATE_FETCH_SUCCEEDED',
  INSURANCE_ESTIMATE_FETCH_FAILED = 'INSURANCE_ESTIMATE_FETCH_FAILED',
  FETCH_INSURANCE_ESTIMATE = 'FETCH_INSURANCE_ESTIMATE',
  INSURANCE_ESTIMATE_FETCHING = 'INSURANCE_ESTIMATE_FETCHING',
}

const initialState: IInsuranceEstimate = {
  hasError: false,
  data: null,
  errorData: null,
  isFetching: false,
};

type Actions =
  | IInsuranceEstimateFetchSucceeded
  | IInsuranceEstimateFetchFailed
  | IInsuranceEstimateFetching;

export function insuranceEstimate(state = initialState, action: Actions): IInsuranceEstimate {
  switch (action.type) {
    case ActionTypes.INSURANCE_ESTIMATE_FETCHING:
      return { ...state, isFetching: true };
    case ActionTypes.INSURANCE_ESTIMATE_FETCH_SUCCEEDED:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        hasError: false,
        errorData: null,
        isFetching: false,
      };
    case ActionTypes.INSURANCE_ESTIMATE_FETCH_FAILED:
      return { ...state, data: null, hasError: true, errorData: action.payload, isFetching: false };
    default:
      return state;
  }
}

/** Set generic error */

export interface IInsuranceEstimateFetchSucceeded {
  type: typeof ActionTypes.INSURANCE_ESTIMATE_FETCH_SUCCEEDED;
  payload: GetInsuranceEstimateContract;
}

export const insuranceEstimationFetchSucceeded = (
  payload: GetInsuranceEstimateContract,
): IInsuranceEstimateFetchSucceeded => ({
  type: ActionTypes.INSURANCE_ESTIMATE_FETCH_SUCCEEDED,
  payload,
});

/** Reset generic error */

export interface IInsuranceEstimateFetchFailed {
  type: typeof ActionTypes.INSURANCE_ESTIMATE_FETCH_FAILED;
  payload: IAppErrorDetails;
}

export const insuranceEstimateFetchFailed = (
  payload: IAppErrorDetails,
): IInsuranceEstimateFetchFailed => ({
  type: ActionTypes.INSURANCE_ESTIMATE_FETCH_FAILED,
  payload,
});

/** Mark insurance in fetching state */

export interface IFetchInsuranceEstimate {
  type: typeof ActionTypes.FETCH_INSURANCE_ESTIMATE;
  payload: { insuranceId: number | null };
}

export const fetchInsuranceEstimate = (insuranceId: number | null): IFetchInsuranceEstimate => ({
  type: ActionTypes.FETCH_INSURANCE_ESTIMATE,
  payload: {
    insuranceId,
  },
});

export interface IInsuranceEstimateFetching {
  type: typeof ActionTypes.INSURANCE_ESTIMATE_FETCHING;
}

export const insuranceEstimateFetching = (): IInsuranceEstimateFetching => ({
  type: ActionTypes.INSURANCE_ESTIMATE_FETCHING,
});
