import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type BankingAccountsSchemaContract =
  | []
  | [
      {
        id: string;
        channel: string;
        status: string;
        merchant_id: string;
        account_number: string | null;
        account_ifsc: string | null;
        account_type: string | null;
        account_currency: string | null;
        beneficiary_email: string | null;
        beneficiary_mobile: string | null;
        beneficiary_name: string | null;
        balance: {
          id: string;
          currency: string | null;
          balance: number;
          locked_balance: number;
          last_fetched_at: number | null;
          [k: string]: unknown;
        };
        [k: string]: unknown;
      },
    ];

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'array',
  items: [
    {
      type: 'object',
      properties: {
        id: { type: 'string' },
        channel: { type: 'string' },
        status: { type: 'string' },
        merchant_id: { type: 'string' },
        account_number: { type: ['string', 'null'] },
        account_ifsc: { type: ['string', 'null'] },
        account_type: { type: ['string', 'null'] },
        account_currency: { type: ['string', 'null'] },
        beneficiary_email: { type: ['string', 'null'] },
        beneficiary_mobile: { type: ['string', 'null'] },
        beneficiary_name: { type: ['string', 'null'] },
        balance: {
          type: 'object',
          properties: {
            id: { type: 'string' },
            currency: { type: ['string', 'null'] },
            balance: { type: 'integer' },
            locked_balance: { type: 'integer' },
            last_fetched_at: { type: ['integer', 'null'] },
          },
          required: ['id', 'currency', 'balance', 'locked_balance', 'last_fetched_at'],
        },
      },
      required: [
        'id',
        'channel',
        'status',
        'merchant_id',
        'account_number',
        'account_ifsc',
        'account_type',
        'account_currency',
        'beneficiary_email',
        'beneficiary_mobile',
        'beneficiary_name',
        'balance',
      ],
    },
  ],
};
