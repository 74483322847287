import { postApi } from 'utils/Api';

export const search = {
  searchEmployees: (search_phrase: string) => {
    return postApi({
      url: `/people/search`,
      requestData: {
        search_phrase,
      },
      responseSchema: {},
    });
  },
};
