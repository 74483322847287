import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

export function WarningAlert({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <p className={`${className ? className : ''} alert--warning`}>{children}</p>;
}

export function SuccessAlert({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <p className={`${className ? className : ''} alert--success`}>{children}</p>;
}

export function WarningAlertYellow({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <p className={`${className ? className : ''} alert--warning--yellow`}>{children}</p>;
}

export function InfoAlert({ children, className = '' }: PropsWithChildren<{ className?: string }>) {
  return <p className={`${className ? className : ''} alert--info`}>{children}</p>;
}
export function InfoAlertBlade({
  children,
  className = '',
  icon,
}: PropsWithChildren<{ className?: string; icon?: string }>) {
  return (
    <p
      className={cx(
        'text-xl text-left bg-primary-o-10 rounded-sm border-solid border border-primary-o-24 p-3 flex',
        className,
      )}>
      {icon && <img src={icon} alt="" width={16} height={16} className="mr-3" />} {children}
    </p>
  );
}
