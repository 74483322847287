import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetCustomReportConfigSchemaContract {
  isRealTime: boolean;
  name: string;
  description: string;
  filters: unknown[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    isRealTime: { type: 'boolean' },
    name: { type: 'string' },
    description: { type: 'string' },
    filters: { type: 'array', items: {} },
  },
  required: ['isRealTime', 'name', 'description', 'filters'],
};
