import { put, StrictEffect, takeLatest, takeLeading, throttle } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import {
  IFetchInsuranceEmployee,
  insuranceEmployeeFetchFailed,
  insuranceEmployeeFetchSucceeded,
  insuranceEmployeeFetching,
  ActionTypes,
  IInsuranceEmployee,
  IUpdateInsuranceEmployee,
  IUpdateInsuranceEmployeeDependent,
  IDeleteInsuranceEmployeeDependent,
  IAddInsuranceEmployeeDependent,
  ISubmitInsuranceEmployee,
  ISendInsuranceReminder,
} from '../../reducers/insuranceEmployee';
import GetInsuranceEmployee, {
  GetOrgEmployeeForInsuranceSchemaContract,
} from '../../schemas/GetOrgEmployeeForInsuranceSchema';
import { selectState } from '../sagaUtils';
import { areAllBasicEmployeeAndDependentFieldsCorrect } from '../../reducers/insuranceEmployee/validators';
import { formatStringToISO } from '../../utils/Dates';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from '../../schemas/SuccessResponseSchema';

const getTempId = (
  (tempId: number = -1) =>
  () =>
    --tempId
)();

function* fetchInsuranceEmployeeSaga(
  action: IFetchInsuranceEmployee,
): Generator<StrictEffect, void, GetOrgEmployeeForInsuranceSchemaContract | IInsuranceEmployee> {
  try {
    yield put(insuranceEmployeeFetching());

    const insuranceEmployee = <GetOrgEmployeeForInsuranceSchemaContract>(yield AppSaga.getApi({
      url: `/user-organization/${action.payload.employeeId}/insurance`,
      responseSchema: GetInsuranceEmployee,
    }));

    yield put(insuranceEmployeeFetchSucceeded(insuranceEmployee, true));
  } catch (e) {
    yield put(insuranceEmployeeFetchFailed(getAppErrorDetails(e)));
  }
}

function* updateInsuranceEmployeeSaga(
  action: IUpdateInsuranceEmployee,
): Generator<StrictEffect, void, any> {
  try {
    const currentInsuranceEmployee = <IInsuranceEmployee>(yield selectState(
      (state) => state.insuranceEmployee,
    ));

    if (currentInsuranceEmployee.data) {
      yield put(
        insuranceEmployeeFetchSucceeded(
          { ...currentInsuranceEmployee.data, ...action.payload.payload },
          false,
        ),
      );
    }
  } catch (e) {
    yield put(insuranceEmployeeFetchFailed(getAppErrorDetails(e)));
    // yield put(setGenericAppError(getAppErrorDetails(e)))
  }
}

function* updateInsuranceEmployeeDependentSaga(
  action: IUpdateInsuranceEmployeeDependent,
): Generator<StrictEffect, void, any> {
  try {
    const currentInsuranceEmployee = <IInsuranceEmployee>(yield selectState(
      (state) => state.insuranceEmployee,
    ));

    if (currentInsuranceEmployee.data) {
      const dependents = currentInsuranceEmployee.data.dependents.map((dependent) => {
        if (dependent.id === action.payload.dependentId) {
          return { ...dependent, ...action.payload.payload };
        }
        return dependent;
      });
      yield put(
        insuranceEmployeeFetchSucceeded({ ...currentInsuranceEmployee.data, dependents }, false),
      );
    }
  } catch (e) {
    yield put(insuranceEmployeeFetchFailed(getAppErrorDetails(e)));
    // yield put(setGenericAppError(getAppErrorDetails(e)))
  }
}

function* addInsuranceEmployeeDependentSaga(
  action: IAddInsuranceEmployeeDependent,
): Generator<StrictEffect, void, any> {
  try {
    const currentInsuranceEmployee = <IInsuranceEmployee>(yield selectState(
      (state) => state.insuranceEmployee,
    ));

    if (currentInsuranceEmployee.data) {
      const dependents: GetOrgEmployeeForInsuranceSchemaContract['dependents'] = [
        ...currentInsuranceEmployee.data.dependents,
        {
          id: getTempId(),
          name: '',
          dateOfBirth: '',
          gender: 'male',
          isEligible: true,
          isPurchasePending: false,
          startDate: null,
          endDate: null,
          ...action.payload.payload,
        },
      ];
      yield put(
        insuranceEmployeeFetchSucceeded({ ...currentInsuranceEmployee.data, dependents }, false),
      );
    }
  } catch (e) {
    yield put(insuranceEmployeeFetchFailed(getAppErrorDetails(e)));
    // yield put(setGenericAppError(getAppErrorDetails(e)))
  }
}

function* removeInsuranceEmployeeDependentSaga(
  action: IDeleteInsuranceEmployeeDependent,
): Generator<StrictEffect, void, any> {
  try {
    const currentInsuranceEmployee = <IInsuranceEmployee> (yield selectState(
      (state) => state.insuranceEmployee,
    ));

    if (currentInsuranceEmployee.data) {
      const dependents = currentInsuranceEmployee.data.dependents.filter((dependent) => {
        return dependent.id !== action.payload.dependentId;
      });
      yield put(
        insuranceEmployeeFetchSucceeded({ ...currentInsuranceEmployee.data, dependents }, false),
      );
    }
  } catch (e) {
    yield put(insuranceEmployeeFetchFailed(getAppErrorDetails(e)));
    // yield put(setGenericAppError(getAppErrorDetails(e)))
  }
}

function* submitInsuranceEmployeeSaga(
  action: ISubmitInsuranceEmployee,
): Generator<StrictEffect, void, any> {
  try {
    const currentInsuranceEmployee = <IInsuranceEmployee>(yield selectState(
      (state) => state.insuranceEmployee,
    ));
    const currentInsuranceEmployeeData = currentInsuranceEmployee.data;

    if (action.payload.employeeId !== currentInsuranceEmployeeData?.id) {
      return;
    }

    if (!currentInsuranceEmployeeData || !currentInsuranceEmployeeData.dob) {
      return;
    }

    if (
      !areAllBasicEmployeeAndDependentFieldsCorrect(
        currentInsuranceEmployee,
        action.payload.isAdmin,
      )
    ) {
      console.log('Cannot submit as all conditions are not satisfied');
      return;
    }

    const dependentsPayload = currentInsuranceEmployeeData.dependents.map((dependent) => {
      let dependentPayload = {
        name: dependent.name,
        age: dependent.age,
        dateOfBirth: dependent.dateOfBirth,
        relation: dependent.relation,
        gender: dependent.gender,
      };
      if (dependent.id > 0) {
        return { ...dependentPayload, id: dependent.id };
      }
      return dependentPayload;
    });

    const payload: Record<string, any> = {
      name: currentInsuranceEmployeeData.name,
      dob: currentInsuranceEmployeeData.dob,
      gender: currentInsuranceEmployeeData.gender,
      detailsConfirmedAt: currentInsuranceEmployeeData.detailsConfirmedAt
        ? formatStringToISO(currentInsuranceEmployeeData.detailsConfirmedAt)
        : null,
      dependents: dependentsPayload,
      phoneNumber: currentInsuranceEmployeeData.phoneNumber,
    };

    if (currentInsuranceEmployeeData.hireDate) {
      payload['hireDate'] = currentInsuranceEmployeeData.hireDate;
    }

    yield put(insuranceEmployeeFetching());

    const insuranceEmployee = <GetOrgEmployeeForInsuranceSchemaContract>(yield AppSaga.putApi({
      url: `/user-organization/${currentInsuranceEmployeeData.id}/insurance`,
      responseSchema: GetInsuranceEmployee,
      requestData: payload,
    }));

    yield put(insuranceEmployeeFetchSucceeded(insuranceEmployee, true));

    if (action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (e) {
    yield put(insuranceEmployeeFetchFailed(getAppErrorDetails(e)));
    // yield put(setGenericAppError(getAppErrorDetails(e)))
  }
}

function* sendReminder(
  action: ISendInsuranceReminder,
): Generator<StrictEffect, void, SuccessResponseSchemaContract> {
  yield AppSaga.postApi({
    url: `/insurance/${action.payload.insuranceId}/employees/reminders`,
    responseSchema: SuccessResponseSchema,
    requestData: {},
  });
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_INSURANCE_EMPLOYEE, fetchInsuranceEmployeeSaga);
  yield takeLeading(ActionTypes.UPDATE_INSURANCE_EMPLOYEE, updateInsuranceEmployeeSaga);
  yield takeLeading(
    ActionTypes.UPDATE_INSURANCE_EMPLOYEE_DEPENDENT,
    updateInsuranceEmployeeDependentSaga,
  );
  yield takeLeading(
    ActionTypes.ADD_INSURANCE_EMPLOYEE_DEPENDENT,
    addInsuranceEmployeeDependentSaga,
  );
  yield takeLeading(
    ActionTypes.DELETE_INSURANCE_EMPLOYEE_DEPENDENT,
    removeInsuranceEmployeeDependentSaga,
  );
  yield takeLeading(ActionTypes.SUBMIT_INSURANCE_EMPLOYEE, submitInsuranceEmployeeSaga);
  yield throttle(500, ActionTypes.SEND_REMINDERS, sendReminder);
}
