import { postApi, getApi, deleteApi, patchApi } from '../utils/Api';
import { CreateBonusRequestSchemaContract } from '../schemas/CreateBonusRequestSchema';
import BonusDataArraySchema, { BonusDataArraySchemaContract } from '../schemas/BonusDataArraySchema';
import { FetchBonusRequestData } from 'components/Bonus/types';
import EditBonusRequestSchema, { EditBonusRequestSchemaContract } from '../schemas/EditBonusRequestSchema';
import BonusEnabledTypesSchema, { BonusEnabledTypesSchemaContract } from 'schemas/BonusEnabledTypesSchema';

export default {

    createBonus: (requestData: CreateBonusRequestSchemaContract) => {
        return postApi<BonusDataArraySchemaContract, CreateBonusRequestSchemaContract>({
            url: `/bonus/create`,
            requestData: requestData,
            responseSchema: BonusDataArraySchema
        })
    },

    fetchBonuses: ( requestData: FetchBonusRequestData ) => {

        const searchParam = new URLSearchParams();

        if (requestData.bonus_id) {
            searchParam.set('bonus_id', requestData.bonus_id.toString());
        }

        if (requestData.status) {
            searchParam.set('status', requestData.status.toString());
        }

        if (requestData.page) {
            searchParam.set('page', requestData.page.toString());
        }

        if (requestData.page_size) {
            searchParam.set('page_size', requestData.page_size.toString());
        }

        if (requestData.payout_month) {
            searchParam.set('payout_month', requestData.payout_month.toString());
        }

        if (requestData.people_id) {
            searchParam.set('people_id', requestData.people_id.toString());
        }

        if (requestData.source) {
            searchParam.set('source', requestData.source.toString());
        }

        const url = '/bonus?' + searchParam.toString();

        return getApi<BonusDataArraySchemaContract>({
            url: url,
            responseSchema: BonusDataArraySchema
        });

    },

    deleteBonus: (bonusId: number) => {
        return deleteApi<BonusDataArraySchemaContract, {}>({
            url: `/bonus/${bonusId}`,
            responseSchema: BonusDataArraySchema,
            requestData: {}
        })
    },

    editBonus: (bonusId : number, requestData: EditBonusRequestSchemaContract) => {
        return patchApi<BonusDataArraySchemaContract, EditBonusRequestSchemaContract>({
            url: `/bonus/${bonusId}`,
            requestData: requestData,
            responseSchema: BonusDataArraySchema
        })
    },

    enabledBonusTypes: () => {
        return getApi<BonusEnabledTypesSchemaContract>({
            url: `/bonus/active-bonus-types`,
            responseSchema: BonusEnabledTypesSchema
        })
    }


}