import {  postApi } from 'utils/Api';
import  {OtpRequestContract} from "../schemas/OtpRequest";
import otpResponse, {OtpResponseContract} from "../schemas/OtpResponse";
import {OtpGenerateRequestContract} from "../schemas/OtpGenerateRequest";
import otpGenerateResponse, {OtpGenerateResponseContract} from "../schemas/OtpGenerateResponse";

export default {
    initiateOTPVerify: (requestData: OtpRequestContract) => {
        return postApi<OtpResponseContract,OtpRequestContract>({
            url:`/otp/verify-otp-and-take-action`,
            responseSchema: otpResponse,
            requestData
        })
    },
    sendOtp: (requestData: OtpGenerateRequestContract) => {
        return postApi<OtpGenerateResponseContract,OtpGenerateRequestContract>({
            url:`/otp/generate-token`,
            responseSchema: otpGenerateResponse,
            requestData
        })
    }
};