import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type LoanDataSchemaContract = {
  id: number;
  people_id: number;
  organization_id: number;
  people_organization_id: number;
  interest_type: number;
  principal: number;
  interest_rate: number;
  tenure: number;
  recovery_start_from: string;
  status: number;
  remarks: string | null;
  created_by: number;
  [k: string]: unknown;
}[];

export default <JSONSchema4>{
  type: ['array'],
  title: '',
  items: {
    type: 'object',
    properties: {
      id: { type: ['integer'] },
      people_id: { type: ['integer'] },
      organization_id: { type: ['integer'] },
      people_organization_id: { type: ['integer'] },
      interest_type: { type: ['integer'] },
      principal: { type: ['integer'] },
      interest_rate: { type: ['number'] },
      tenure: { type: ['integer'] },
      recovery_start_from: { type: ['string'], format: 'date' },
      status: { type: ['integer'] },
      remarks: { type: ['string', 'null'] },
      created_by: { type: ['integer'] },
    },
    required: [
      'id',
      'people_id',
      'organization_id',
      'people_organization_id',
      'interest_type',
      'principal',
      'interest_rate',
      'tenure',
      'recovery_start_from',
      'status',
      'remarks',
      'created_by',
    ],
  },
};
