import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetHrmsEntityTypesFromKnitContract {
  success: boolean;
  data: {
    entity_name: string;
    entity_code: string;
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    success: { type: 'boolean' },
    data: {
      type: 'array',
      items: {
        type: 'object',
        properties: { entity_name: { type: 'string' }, entity_code: { type: 'string' } },
        required: ['entity_name', 'entity_code'],
      },
    },
  },
  required: ['success', 'data'],
};
