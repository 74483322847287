import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface BonusDataArraySchemaContract {
  total: number;
  current_page: number;
  data: {
    id: number | null;
    bonus_name: string | null;
    amount: number | null;
    source: string | null;
    payout_month: string | null;
    tds_recovery_option: string | null;
    part_of_ctc: boolean | null;
    clawback_applicable: boolean | null;
    clawback_period: number | null;
    remarks: string | null;
    status: number | null;
    people_id: number | null;
    organization_id: number | null;
    people_organization_id: number | null;
    created_by: number | null;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    deleted_by: number | null;
    employee_name: string | null;
    employee_id: string | null;
    employee_profile_link: string | null;
    clawback_month: string | null;
    organization_bonus_setting_id: number | null;
    [k: string]: unknown;
  }[];
  last_page: number | null;
  per_page: null | number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    total: { type: ['integer'] },
    current_page: { type: ['integer'] },
    data: {
      type: ['array'],
      title: '',
      items: {
        type: 'object',
        properties: {
          id: { type: ['integer', 'null'] },
          bonus_name: { type: ['string', 'null'] },
          amount: { type: ['number', 'null'] },
          source: { type: ['string', 'null'] },
          payout_month: { type: ['string', 'null'], format: 'date' },
          tds_recovery_option: { type: ['string', 'null'] },
          part_of_ctc: { type: ['boolean', 'null'] },
          clawback_applicable: { type: ['boolean', 'null'] },
          clawback_period: { type: ['integer', 'null'] },
          remarks: { type: ['string', 'null'] },
          status: { type: ['integer', 'null'] },
          people_id: { type: ['number', 'null'] },
          organization_id: { type: ['number', 'null'] },
          people_organization_id: { type: ['number', 'null'] },
          created_by: { type: ['number', 'null'] },
          created_at: { type: ['string', 'null'], format: 'date-time' },
          updated_at: { type: ['string', 'null'], format: 'date-time' },
          deleted_at: { type: ['string', 'null'], format: 'date-time' },
          deleted_by: { type: ['number', 'null'] },
          employee_name: { type: ['string', 'null'] },
          employee_id: { type: ['string', 'null'] },
          employee_profile_link: { type: ['string', 'null'] },
          clawback_month: { type: ['string', 'null'], format: 'date' },
          organization_bonus_setting_id: { type: ['integer', 'null'] },
        },
        required: [
          'id',
          'bonus_name',
          'amount',
          'source',
          'payout_month',
          'tds_recovery_option',
          'part_of_ctc',
          'clawback_applicable',
          'clawback_period',
          'remarks',
          'status',
          'people_id',
          'organization_id',
          'people_organization_id',
          'created_by',
          'created_at',
          'updated_at',
          'deleted_at',
          'deleted_by',
          'employee_name',
          'employee_id',
          'employee_profile_link',
          'clawback_month',
          'organization_bonus_setting_id',
        ],
      },
    },
    last_page: { type: ['number', 'null'] },
    per_page: { type: ['null', 'number'] },
  },
  required: ['total', 'data', 'current_page', 'last_page', 'per_page'],
};
