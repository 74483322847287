import GetEmployeeTaxReportSchema, {
  GetEmployeeTaxReportSchemaContract,
} from './../schemas/GetEmployeeTaxReportSchema';

import { getApi } from 'utils/Api';
import reportConstants from 'components/Reports/VarianceReport/constants';
import { format } from 'date-fns';
import EmployeeTaxReportSchema, { EmployeeTaxReportSchemaContract } from 'schemas/EmployeeTaxReportSchema';

// eslint-disable-next-line import/no-anonymous-default-export

export function calculateTimeline(timeline:string){
  let from = '';
  let to = '';
  const today = new Date();
  switch (timeline) {
    case reportConstants.TIMELINE_FY_2019: {
      let startDate = new Date(2019, 3, 1);
      from = format(startDate, 'yyyy-MM-dd');
      let endDate = new Date(2020, 2, 31);
      to = format(endDate, 'yyyy-MM-dd');
      break;
    }
    case reportConstants.TIMELINE_FY_2020: {
      let startDate = new Date(2020, 3, 1);
      from = format(startDate, 'yyyy-MM-dd');
      let endDate = new Date(2021, 2, 31);
      to = format(endDate, 'yyyy-MM-dd');
      break;
    }
    case reportConstants.TIMELINE_FY_2021: {
      let startDate = new Date(2021, 3, 1);
      from = format(startDate, 'yyyy-MM-dd');
      let endDate = new Date(2022, 2, 31);
      to = format(endDate, 'yyyy-MM-dd');
      break;
    }
    case reportConstants.TIMELINE_FY_2022: {
      let startDate = new Date(2022, 3, 1);
      from = format(startDate, 'yyyy-MM-dd');
      let endDate = new Date(2023, 2, 31);
      to = format(endDate, 'yyyy-MM-dd');
      break;
    }
  }
  return [from, to];
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTaxDeductionForFY: (timeline: string) => {
    const [from, to] = calculateTimeline(timeline);
    return getApi<GetEmployeeTaxReportSchemaContract>({
      url: `/admin/tax-deductions/?from=${from}&to=${to}`,
      responseSchema: GetEmployeeTaxReportSchema,
    });
  },

  getTaxReportDetails: (financialYear: number, month: string|null, peopleOrgId: string|null = null) => {
    return getApi<EmployeeTaxReportSchemaContract>({
      url: `/tax-report${peopleOrgId ? `/${peopleOrgId}` : ""}?financial_year=${financialYear}${month ? `&month=${month}` : ""}`,
      responseSchema: EmployeeTaxReportSchema,
    });
  },
};
