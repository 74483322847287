import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface BulkDownloadSchemaContract {
  id: number;
  type: string;
  people_id: number;
  organization_id: number;
  job_id: number | null;
  status: 'created' | 'success' | 'failed' | 'processing';
  finished_at: string | null;
  failed_at: string | null;
  filename: string;
  comments: number | null;
  created_at: string;
  updated_at: string;
  filesize?: number | null;
  hasFiles: boolean;
  download_url: string;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    id: { type: 'number' },
    type: { type: 'string' },
    people_id: { type: 'number' },
    organization_id: { type: 'number' },
    job_id: { type: ['number', 'null'] },
    status: { type: 'string', enum: ['created', 'success', 'failed', 'processing'] },
    finished_at: { type: ['string', 'null'], format: 'date-time' },
    failed_at: { type: ['string', 'null'], format: 'date-time' },
    filename: { type: 'string' },
    comments: { type: ['number', 'null'] },
    created_at: { type: ['string'], format: 'date-time' },
    updated_at: { type: ['string'], format: 'date-time' },
    filesize: { type: ['number', 'null'] },
    hasFiles: { type: 'boolean' },
    download_url: { type: 'string' },
  },
  required: [
    'id',
    'type',
    'people_id',
    'organization_id',
    'job_id',
    'status',
    'finished_at',
    'failed_at',
    'filename',
    'comments',
    'created_at',
    'updated_at',
    'hasFiles',
    'download_url',
  ],
};
