import { useHistory } from 'react-router-dom';
import { SidebarApiSchemaContract } from '../../schemas/SidebarApiSchema';
import { IAppErrorDetails } from '../../utils/AppError';

export interface ISidebar {
  hasError: boolean;
  data: SidebarApiSchemaContract;
  errorData: IAppErrorDetails | null;
  isFetching: boolean;
}

export enum ActionTypes {
  SIDEBAR_FETCH_SUCCEEDED = 'SIDEBAR_FETCH_SUCCEEDED',
  SIDEBAR_FETCH_FAILED = 'SIDEBAR_FETCH_FAILED',
  FETCH_SIDEBAR = 'FETCH_SIDEBAR',
  SIDEBAR_FETCHING = 'SIDEBAR_FETCHING',
}

const initialState: ISidebar = {
  hasError: false,
  data: [],
  errorData: null,
  isFetching: false,
};

type Actions = ISidebarFetchSucceeded | ISidebarFetchFailed | ISidebarFetching;
export type SidebarElement = SidebarApiSchemaContract[0];

export function sidebar(state = initialState, action: Actions): ISidebar {
  switch (action.type) {
    case ActionTypes.SIDEBAR_FETCHING:
      return { ...state, isFetching: true };
    case ActionTypes.SIDEBAR_FETCH_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        hasError: false,
        errorData: null,
        isFetching: false,
      };
    case ActionTypes.SIDEBAR_FETCH_FAILED:
      return { ...state, data: [], hasError: true, errorData: action.payload, isFetching: false };
    default:
      return state;
  }
}

/** Set generic error */

export interface ISidebarFetchSucceeded {
  type: typeof ActionTypes.SIDEBAR_FETCH_SUCCEEDED;
  payload: SidebarApiSchemaContract;
}

export const sidebarFetchSucceeded = (
  payload: SidebarApiSchemaContract,
): ISidebarFetchSucceeded => ({
  type: ActionTypes.SIDEBAR_FETCH_SUCCEEDED,
  payload,
});

/** Reset generic error */

export interface ISidebarFetchFailed {
  type: typeof ActionTypes.SIDEBAR_FETCH_FAILED;
  payload: IAppErrorDetails;
}

export const sidebarFetchFailed = (payload: IAppErrorDetails): ISidebarFetchFailed => ({
  type: ActionTypes.SIDEBAR_FETCH_FAILED,
  payload,
});

/** Mark sidebar in fetching state */

export interface IFetchSidebar {
  type: typeof ActionTypes.FETCH_SIDEBAR;
}

export const fetchSidebar = (): IFetchSidebar => ({
  type: ActionTypes.FETCH_SIDEBAR,
});

export interface ISidebarFetching {
  type: typeof ActionTypes.SIDEBAR_FETCHING;
}

export const sidebarFetching = (): ISidebarFetching => ({
  type: ActionTypes.SIDEBAR_FETCHING,
});
