import React from 'react';
import styles from './index.module.scss';
import useReduxSelector from 'utils/useReduxSelector';
import {getStaticMediaUrl} from "../../../../../utils/Urls";
import notificationIcon from 'assets/notification/icon--notifications.svg';
import notificationUnreadIcon from 'assets/notification/icon--notifications-unread.svg';

export function NotificationIcon() {
  return (
    <img
      className={styles.NotificationIcon}
      src={useReduxSelector((state) =>
        state.notification.hasUnreadNotifications
          ? getStaticMediaUrl(notificationUnreadIcon)
          : getStaticMediaUrl(notificationIcon),
      )}
      alt=""
    />
  );
}
