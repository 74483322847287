import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface PayrollAiAssistantResponseSchemaContract {
  conversation_key: string;
  conversation_count: number;
  user_name?: string;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    conversation_key: { type: 'string' },
    conversation_count: { type: 'integer' },
    user_name: { type: 'string' },
  },
  required: ['conversation_key', 'conversation_count'],
};
