import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface SlackUserActivationSchemaContract {
  id?: number;
  slack_user_id?: string;
  slack_team_id?: string;
  people_organization_id?: number | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    id: { type: 'integer' },
    slack_user_id: { type: 'string' },
    slack_team_id: { type: 'string' },
    people_organization_id: { type: ['integer', 'null'] },
  },
};
