import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface RxIntegrationSchemaContract {
  merchant_details: {
    is_active: boolean;
    selected_account: {
      id: string;
      account_ifsc: string;
      account_number: string;
      account_number_ends_with: string;
      account_type: string;
      channel: string;
      status: string;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  } | null;
  merchant_id: null | string;
  balance: number;
  transactions_in_count_progress: number;
  is_logged_in_x: boolean;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    merchant_details: {
      type: ['object', 'null'],
      properties: {
        is_active: { type: 'boolean' },
        selected_account: {
          type: 'object',
          properties: {
            id: { type: 'string' },
            account_ifsc: { type: 'string' },
            account_number: { type: 'string' },
            account_number_ends_with: { type: 'string' },
            account_type: { type: 'string' },
            channel: { type: 'string' },
            status: { type: 'string' },
          },
          required: [
            'id',
            'account_ifsc',
            'account_number',
            'account_number_ends_with',
            'account_type',
            'channel',
            'status',
          ],
        },
      },
      required: ['is_active', 'selected_account'],
    },
    merchant_id: { type: ['null', 'string'] },
    balance: { type: 'integer' },
    transactions_in_count_progress: { type: 'integer' },
    is_logged_in_x: { type: 'boolean' },
  },
  required: [
    'balance',
    'transactions_in_count_progress',
    'merchant_details',
    'is_logged_in_x',
    'merchant_id',
  ],
};
