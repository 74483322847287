declare global {
  interface Window {
    _C_S_R_F_: any;
  }
}
export const getCsrfToken = (
  (csrf: string | null = null) =>
  () => {
    if (window._C_S_R_F_ !== undefined) {
      csrf = window._C_S_R_F_;
      delete window._C_S_R_F_;
    }

    return csrf;
  }
)();
