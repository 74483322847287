import { IAppErrorDetails } from '../../utils/AppError';
import NotificationAPISchema, {
  NotificationApiSchemaContract,
} from '../../schemas/NotificationApiSchema';

export interface INotification {
  data: Record<string, NotificationApiSchemaContract>;
  hasUnreadNotifications: boolean;
  errorData: IAppErrorDetails | null;
  isFetching: boolean;
}

export enum ActionTypes {
  NOTIFICATION_FETCH_SUCCEEDED = 'NOTIFICATION_FETCH_SUCCEEDED',
  NOTIFICATION_FETCH_FAILED = 'NOTIFICATION_FETCH_FAILED',
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  NOTIFICATION_FETCHING = 'NOTIFICATION_FETCHING',
  NOTIFICATIONS_CHECK_UNREAD = 'NOTIFICATIONS_CHECK_UNREAD',
  MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ',
}

const initialState: INotification = {
  data: {},
  errorData: null,
  isFetching: false,
  hasUnreadNotifications: false,
};

type Actions =
  | INotificationFetchSucceeded
  | INotificationFetchFailed
  | INotificationFetching
  | IMarkNotificationsAsRead;

export function notification(state = initialState, action: Actions): INotification {
  switch (action.type) {
    case ActionTypes.NOTIFICATION_FETCHING:
      if (Object.keys(state.data).length === 0) {
        let local = sessionStorage.getItem('notificationCache');
        if (local && local !== 'undefined') {
          try {
            let localNotifications = JSON.parse(local);
            return {
              ...state,
              data: localNotifications,
              errorData: null,
              isFetching: true,
            };
          } catch (e) {}
        }
      }
      return { ...state, isFetching: true };
    case ActionTypes.NOTIFICATION_FETCH_SUCCEEDED:
      // let notifications = state.data;

      let payload = [...action.payload];
      let unreadFlag = state.hasUnreadNotifications;
      let groupedNotificationsAndReadState = groupNotifications(payload, unreadFlag);
      let groupedNotifications = groupedNotificationsAndReadState['notifications'];
      unreadFlag = groupedNotificationsAndReadState['hasUnreadNotifications'];
      sessionStorage.setItem('notificationCache', JSON.stringify(groupedNotifications));
      return {
        ...state,
        data: groupedNotifications,
        errorData: null,
        isFetching: false,
        hasUnreadNotifications: unreadFlag,
      };
    case ActionTypes.NOTIFICATION_FETCH_FAILED:
      return { ...state, data: state.data, errorData: null, isFetching: false };
    case ActionTypes.MARK_NOTIFICATIONS_READ:
      let notificationIds = action.payload.notificationIds;
      let notifications = state.data;

      Object.keys(notifications).forEach((month) => {
        notifications[month].forEach((individual) => {
          if (notificationIds.includes(individual.id)) {
            individual.readStatus = true;
          }
        });
      });
      return {
        ...state,
        data: notifications,
        errorData: null,
        isFetching: false,
        hasUnreadNotifications: false,
      };
    default:
      return state;
  }
}

function groupNotifications(
  payload: NotificationApiSchemaContract,
  hasUnreadNotifications: boolean,
) {
  let notifications: Record<string, NotificationApiSchemaContract> = {};

  if (payload !== null) {
    payload.forEach((item) => {
      let date = new Date(item['date']);
      let month = '';
      if (date.getMonth() === new Date().getMonth()) {
        month = 'This month';
      } else {
        month = date.toLocaleString('default', { month: 'long' });
      }

      if (!notifications[month]) {
        notifications[month] = [];
      }

      if (!hasUnreadNotifications) {
        if (!item['readStatus']) {
          hasUnreadNotifications = true;
        }
      }
      notifications[month].push(item);
    });
  }

  return { notifications, hasUnreadNotifications };
}

export interface INotificationFetchSucceeded {
  type: typeof ActionTypes.NOTIFICATION_FETCH_SUCCEEDED;
  payload: NotificationApiSchemaContract;
}

export const notificationFetchSucceeded = (
  payload: NotificationApiSchemaContract,
): INotificationFetchSucceeded => ({
  type: ActionTypes.NOTIFICATION_FETCH_SUCCEEDED,
  payload,
});

export interface INotificationFetchFailed {
  type: typeof ActionTypes.NOTIFICATION_FETCH_FAILED;
  payload: IAppErrorDetails;
}

export const notificationFetchFailed = (payload: IAppErrorDetails): INotificationFetchFailed => ({
  type: ActionTypes.NOTIFICATION_FETCH_FAILED,
  payload,
});

/** Mark notification in fetching state */

export interface IFetchNotification {
  type: typeof ActionTypes.FETCH_NOTIFICATIONS;
}

export const fetchNotifications = (): IFetchNotification => ({
  type: ActionTypes.FETCH_NOTIFICATIONS,
});

export interface IMarkNotificationsAsRead {
  type: typeof ActionTypes.MARK_NOTIFICATIONS_READ;
  payload: { notificationIds: string[] };
}

export const markNotificationsRead = (notificationIds: string[]): IMarkNotificationsAsRead => ({
  type: ActionTypes.MARK_NOTIFICATIONS_READ,
  payload: {
    notificationIds,
  },
});

export interface INotificationFetching {
  type: typeof ActionTypes.NOTIFICATION_FETCHING;
}

export const notificationFetching = (): INotificationFetching => ({
  type: ActionTypes.NOTIFICATION_FETCHING,
});
