import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface JibbleEmployeesDataSchemaContract {
  success: boolean;
  jibble_employee_data: {
    current_page: number;
    data: {
      name: string | null;
      email: string;
      account_creation_status: 1 | 2 | 3 | 4 | 5;
      jibble_member_code: string | null;
      jibble_people_id: string | null;
      organization_id: number;
      people_id: number;
      people_organization_id: number;
      [k: string]: unknown;
    }[];
    first_page_url: string;
    from: number | null;
    last_page: number;
    last_page_url: string;
    links: {
      active: boolean;
      label: string;
      url: string | null;
      [k: string]: unknown;
    }[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number | null;
    total: number;
    [k: string]: unknown;
  };
  unsynced_xpayroll_employees?: {
    name: string | null;
    email: string;
    account_creation_status: 1 | 2 | 3 | 4 | 5;
    jibble_member_code: string | null;
    jibble_people_id: string | null;
    organization_id: number;
    people_id: number;
    people_organization_id: number;
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: ['object'],
  properties: {
    success: { type: ['boolean'] },
    jibble_employee_data: {
      type: ['object'],
      properties: {
        current_page: { type: 'integer' },
        data: {
          type: ['array'],
          uniqueItems: true,
          minItems: 0,
          items: {
            type: ['object'],
            properties: {
              name: { type: ['string', 'null'] },
              email: { type: ['string'] },
              account_creation_status: { type: ['integer'], enum: [1, 2, 3, 4, 5] },
              jibble_member_code: { type: ['string', 'null'] },
              jibble_people_id: { type: ['string', 'null'] },
              organization_id: { type: ['number'] },
              people_id: { type: ['number'] },
              people_organization_id: { type: ['number'] },
            },
            required: [
              'name',
              'email',
              'account_creation_status',
              'organization_id',
              'jibble_member_code',
              'jibble_people_id',
              'people_id',
              'people_organization_id',
            ],
          },
        },
        first_page_url: { type: 'string' },
        from: { type: ['integer', 'null'] },
        last_page: { type: 'integer' },
        last_page_url: { type: 'string' },
        links: {
          type: 'array',
          uniqueItems: true,
          minItems: 0,
          items: {
            type: ['object'],
            properties: {
              active: { type: 'boolean' },
              label: { type: 'string' },
              url: { type: ['string', 'null'] },
            },
            required: ['active', 'label', 'url'],
          },
        },
        next_page_url: { type: ['string', 'null'] },
        path: { type: 'string' },
        per_page: { type: 'integer' },
        prev_page_url: { type: ['string', 'null'] },
        to: { type: ['integer', 'null'] },
        total: { type: 'integer' },
      },
      required: [
        'current_page',
        'data',
        'first_page_url',
        'from',
        'last_page',
        'last_page_url',
        'links',
        'next_page_url',
        'path',
        'per_page',
        'prev_page_url',
        'to',
        'total',
      ],
    },
    unsynced_xpayroll_employees: {
      type: ['array'],
      uniqueItems: true,
      minItems: 0,
      items: {
        type: ['object'],
        properties: {
          name: { type: ['string', 'null'] },
          email: { type: ['string'] },
          account_creation_status: { type: ['integer'], enum: [1, 2, 3, 4, 5] },
          jibble_member_code: { type: ['string', 'null'] },
          jibble_people_id: { type: ['string', 'null'] },
          organization_id: { type: ['number'] },
          people_id: { type: ['number'] },
          people_organization_id: { type: ['number'] },
        },
        required: [
          'name',
          'email',
          'account_creation_status',
          'organization_id',
          'jibble_member_code',
          'jibble_people_id',
          'people_id',
          'people_organization_id',
        ],
      },
    },
  },
  required: ['success', 'jibble_employee_data'],
};
