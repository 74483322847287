import GetPartnerDetailsSchema, {
  GetPartnerDetailsSchemaContract,
} from 'schemas/GetPartnerDetailsSchema';
import PartnerLastSyncTime,{ PartnerLastSyncTimeContract } from 'schemas/PartnerLastSyncTime';
import PartnerSettingsSchema, {
  PartnerSettingsSchemaContract,
} from 'schemas/PartnerSettingsSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import { getApi, postApi } from 'utils/Api';

export type generateApiKeyRequestType = {
  partnerId: number | undefined;
};
export default {
  getPartnerDetails: (identifier: string) => {
    return getApi<GetPartnerDetailsSchemaContract>({
      url: `/partner?identifier=${identifier}`,
      responseSchema: GetPartnerDetailsSchema,
    });
  },
  generateApiKey: ({ partnerId }: generateApiKeyRequestType) => {
    return postApi<PartnerSettingsSchemaContract, generateApiKeyRequestType>({
      url: `/api-integrations/generate-api-key/`,
      responseSchema: PartnerSettingsSchema,
      requestData: {
        partnerId: partnerId,
      },
    });
  },
  refreshApiKey: ({ partnerId }: generateApiKeyRequestType) => {
    return postApi<PartnerSettingsSchemaContract, generateApiKeyRequestType>({
      url: `/api-integrations/refresh-api-key/`,
      responseSchema: PartnerSettingsSchema,
      requestData: {
        partnerId: partnerId,
      },
    });
  },
  disableIntegration: ({ partnerId }: generateApiKeyRequestType) => {
    return postApi<SuccessResponseSchemaContract, generateApiKeyRequestType>({
      url: `/api-integrations/disable-integration/`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        partnerId: partnerId,
      },
    });
  },
  getLastSyncTime : ({partnerId} : generateApiKeyRequestType) => {
    return getApi<PartnerLastSyncTimeContract>({
      url: `/api-integrations/last-fetched-time?partnerId=${partnerId}`,
      responseSchema: PartnerLastSyncTime,
    });
  } 
};
