import { delay, put, StrictEffect, takeLatest, takeLeading } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import {
  IFetchInsuranceEmployees,
  insuranceEmployeesFetchFailed,
  insuranceEmployeesFetchSucceeded,
  insuranceEmployeesFetching,
  ActionTypes,
  IInsuranceEmployees,
} from '../../reducers/insuranceEmployees';
import GetInsuranceEmployees, {
  GetOrgEmployeesForInsuranceSchemaContract,
} from '../../schemas/GetOrgEmployeesForInsuranceSchema';
import { selectState } from '../sagaUtils';
import { prepareUrl } from '../../utils/Urls';
import { IInsurance } from '../../reducers/insurance';

function* fetchInsuranceEmployeesSaga(
  action: IFetchInsuranceEmployees,
): Generator<StrictEffect, void, GetOrgEmployeesForInsuranceSchemaContract | IInsuranceEmployees> {
  try {
    const currentEmployees = <IInsuranceEmployees>(
      yield selectState((state) => state.insuranceEmployees)
    );

    const allFetchOptions = { ...currentEmployees.fetchedFor, ...action.payload, offset: 0 };

    if (allFetchOptions.search === '') {
      allFetchOptions.search = null;
    }

    yield put(insuranceEmployeesFetching(allFetchOptions));
    if ('search' in action.payload) {
      yield delay(500);
    }
    const insuranceEmployees = <GetOrgEmployeesForInsuranceSchemaContract>(
      yield AppSaga.getApi({
        url: prepareUrl(`/organization/${action.payload.organizationId}/employees/insurances`, {
          ...allFetchOptions,
          fetchFresh: null,
          limit: 10,
        }),
        responseSchema: GetInsuranceEmployees,
      })
    );
    yield put(insuranceEmployeesFetchSucceeded(insuranceEmployees, true));
  } catch (e) {
    yield put(insuranceEmployeesFetchFailed(getAppErrorDetails(e)));
  }
}

function* fetchInsuranceNextEmployeesSaga(): Generator<
  StrictEffect,
  void,
  GetOrgEmployeesForInsuranceSchemaContract | IInsuranceEmployees | IInsurance
> {
  try {
    const currentInsurance = <IInsurance>(yield selectState((state) => state.insurance));
    const currentEmployees = <IInsuranceEmployees>(
      yield selectState((state) => state.insuranceEmployees)
    );
    if (
      currentEmployees.data.total <= currentEmployees.data.items.length ||
      !currentInsurance.data?.organizationId
    ) {
      return;
    }
    let nextOffset =
      (currentEmployees.fetchedFor.offset ? currentEmployees.fetchedFor.offset : 0) + 10;
    const allFetchOptions = {
      ...currentEmployees.fetchedFor,
      fetchFresh: false,
      offset: nextOffset,
    };
    if (allFetchOptions.search === '') {
      allFetchOptions.search = null;
    }
    yield put(
      insuranceEmployeesFetching({
        ...currentEmployees.fetchedFor,
        offset: nextOffset,
        fetchFresh: false,
      }),
    );
    const insuranceEmployees = <GetOrgEmployeesForInsuranceSchemaContract>(
      yield AppSaga.getApi({
        url: prepareUrl(
          `/organization/${currentInsurance.data.organizationId}/employees/insurances`,
          { ...allFetchOptions, fetchFresh: null, limit: 10 },
        ),
        responseSchema: GetInsuranceEmployees,
      })
    );
    yield put(insuranceEmployeesFetchSucceeded(insuranceEmployees, false));
  } catch (e) {
    yield put(insuranceEmployeesFetchFailed(getAppErrorDetails(e)));
    console.error(e);
  }
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_INSURANCE_EMPLOYEES, fetchInsuranceEmployeesSaga);
  yield takeLeading(ActionTypes.FETCH_NEXT_INSURANCE_EMPLOYEES, fetchInsuranceNextEmployeesSaga);
}
