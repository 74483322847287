import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetNetpayOnHoldListSchemaContract {
  current_page: number;
  data: {
    id: number;
    people_id: number | null;
    employee_id: string;
    employee_name: string | null;
    department: string | null;
    location: number | null;
    last_working_date: string | null;
    net_pay_amount: number | null;
    [k: string]: unknown;
  }[];
  from: number | null;
  last_page: number;
  per_page: number;
  to: number | null;
  total: number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    current_page: { type: 'number' },
    data: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'number' },
          people_id: { type: ['number', 'null'] },
          employee_id: { type: 'string' },
          employee_name: { type: ['string', 'null'] },
          department: { type: ['string', 'null'] },
          location: { type: ['number', 'null'] },
          last_working_date: { type: ['string', 'null'] },
          net_pay_amount: { type: ['number', 'null'] },
        },
        required: [
          'id',
          'people_id',
          'employee_id',
          'employee_name',
          'department',
          'location',
          'last_working_date',
          'net_pay_amount',
        ],
      },
    },
    from: { type: ['number', 'null'] },
    last_page: { type: 'number' },
    per_page: { type: 'number' },
    to: { type: ['number', 'null'] },
    total: { type: 'number' },
  },
  required: ['current_page', 'data', 'from', 'last_page', 'per_page', 'to', 'total'],
};
