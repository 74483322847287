import advanceSalaryIcon from 'assets/notification/notification--advanceSalary.svg';
import advanceSalaryUnreadIcon from 'assets/notification/notification--advanceSalary-unread.svg';
import payrollIcon from 'assets/notification/notification--payroll.svg';
import payrollUnreadIcon from 'assets/notification/notification--payroll-unread.svg';
import reimbursementsIcon from 'assets/notification/notification--reimbursements.svg';
import reimbursementsUnreadIcon from 'assets/notification/notification--reimbursements-unread.svg';
import complianceIcon from 'assets/notification/notification--compliance.svg';
import complianceUnreadIcon from 'assets/notification/notification--compliance-unread.svg';
import payslipIcon from 'assets/notification/notification--payslip.svg';
import payslipUnreadIcon from 'assets/notification/notification--payslip-unread.svg';
import employeeIcon from 'assets/notification/notification--employee.svg';
import employeeUnreadIcon from 'assets/notification/notification--employee-unread.svg';
import genericIcon from 'assets/notification/notification--generic.svg';
import genericUnreadIcon from 'assets/notification/notification--generic-unread.svg';

export function getIconForNotificationType(type: string) {
  switch (type) {
    case 'AdvanceSalaryRejected':
    case 'AdvanceSalaryApproved':
    case 'InsuranceRefund':
      return {
        read: advanceSalaryIcon,
        unread: advanceSalaryUnreadIcon,
      };
    case 'PayrollProcessed':
    case 'WorkflowPendingApprovalDashboard':
    case 'WorkflowMakerFinalizePayrollNotification':
    case 'WorkflowMakerEditPayrollNotification':
    case 'UpcomingPayrollReminder':
      return {
        read: payrollIcon,
        unread: payrollUnreadIcon,
      };

    case 'ReimbursementApproved':
    case 'ReimbursementRejected':
      return {
        read: reimbursementsIcon,
        unread: reimbursementsUnreadIcon,
      };
    case 'Form16':
    case 'KycApproved':
    case 'TdsFilingAcknowledgement':
    case 'InvalidPanVerificationError':
      return {
        read: complianceIcon,
        unread: complianceUnreadIcon,
      };

    case 'PayrollProcessedToEmployee':
    case 'BulkDownloadFailureNotification':
    case 'BulkDownloadLinkNotification':
      return {
        read: payslipIcon,
        unread: payslipUnreadIcon,
      };

    case 'BulkOnboardingRequestReceived':
    case 'BulkOnboardingRequestComplete':
    case 'HRMSSyncComplete':
      return {
        read: employeeIcon,
        unread: employeeUnreadIcon
      };

    case 'CustomNotification':
    default:
      return {
        read: genericIcon,
        unread: genericUnreadIcon,
      };
  }
}
