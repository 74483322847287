import { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import { UploadKycDocumentSchemaContract } from 'schemas/UploadKycDocumentSchema';
import { getApi, postApi } from 'utils/Api';

export interface UploadKycDocumentApiPayload {
  documentType: string;
  file: File;
  orgType: number;
}
interface ValidateBankAccountApiPayload {
  bank_ifsc: string;
  bank_account_number: string;
  bank_beneficiary_name: string;
}

type DocumentGroupComment = {
  status: number;
  comment: string;
};

export interface GetKycBankDetailsValidationStatusResponse {
  status: number | null;
  attempts_remaining: number | null;
  bank_ifsc: string | null;
  bank_account_number: string | null;
  bank_beneficiary_name?: string | null;
  verificationType?: 'auto' | 'manual' | '';
  error_description?: string | null;
  [k: string]: unknown;
}

export interface KycInfoApiResponse {
  kycInfo: {
    id: number;
    organizationId: number;
    type: null | number;
    documentBusinessIdentity: string[];
    documentBusinessExistence: string[];
    documentBusinessWorking: never[];
    documentBusinessOwners: string[];
    documentAddressBusinessOwners: string[];
    updatedAt: string;
    requestedOn: null | number;
    status: number;
    statusUpdatedAt: string;
    documentGstinCertificate: string[];
    documentCancelledCheque: string[];
    kycDocumentsOnholdComment: {
      documentGstin: DocumentGroupComment;
      documentBusinessOwners: DocumentGroupComment;
      documentBusinessWorking: DocumentGroupComment;
      documentBusinessIdentity: DocumentGroupComment;
      documentBusinessExistence: DocumentGroupComment;
      documentAddressBusinessOwners: DocumentGroupComment;
    };
    isRazorpayCustomer: number;
    kycMailOnholdComment: null | string;
    autoKyc?: {
      status: boolean;
      verificationType: 'auto' | 'manual' | '';
    };
  };
  kycDocumentInfo: Array<{
    documentKey: null | string;
    documentType: string;
    comments: null | string;
    verificationStatus: number;
    fileS3Url: string | null;
    isOcrPossible: boolean;
  }>;
  bankAccountInfo: {
    status: number | null;
    attempts_remaining?: number | null;
  };
}

export interface InitiateKycVerificationResponse extends SuccessResponseSchemaContract {
  verificationType: 'auto' | 'manual' | '';
}

const kycApis = {
  validateBankAccount: (requestData: ValidateBankAccountApiPayload) => {
    return postApi<SuccessResponseSchemaContract, ValidateBankAccountApiPayload>({
      url: `/organization/validate-bank-account`,
      responseSchema: {},
      requestData,
    });
  },
  getBankAccountVerificationStatus: () => {
    return getApi<GetKycBankDetailsValidationStatusResponse>({
      url: `/organization/bank-account-validiation-status`,
      responseSchema: {},
    });
  },
  uploadKycDocument: (requestData: UploadKycDocumentApiPayload) => {
    const formData = new FormData();
    formData.append('documentType', requestData.documentType);
    formData.append('orgType', `${requestData.orgType}`);
    formData.append('file', requestData.file);
    return postApi<UploadKycDocumentSchemaContract, FormData>(
      {
        url: `/organization/upload-kyc-document`,
        responseSchema: {},
        requestData: formData,
      },
      'multipart',
    );
  },
  getKycInfo: () => {
    return getApi<KycInfoApiResponse>({
      url: `/organization/get-kyc-info`,
      responseSchema: {},
    });
  },
  initiateKycVerification: () => {
    return postApi<InitiateKycVerificationResponse, {}>({
      url: `/organization/initiate-kyc-verification`,
      responseSchema: {},
      requestData: {},
    });
  },
};

export default kycApis;
