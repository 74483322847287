import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type GetEmployeeTaxReportSchemaContract = {
  payrollMonth?: string;
  earnings?: number;
  exemptions?: number;
  standardDeductions?: number;
  otherDeductions?: number;
  taxableIncome?: number;
  totalTax?: number;
  taxPaidAlready?: number;
  taxPayable?: number;
  tdsPerMonth?: number;
  [k: string]: unknown;
}[];

export default <JSONSchema4>{
  type: ['array'],
  title: '',
  items: {
    type: 'object',
    properties: {
      payrollMonth: { type: 'string' },
      earnings: { type: 'number' },
      exemptions: { type: 'number' },
      standardDeductions: { type: 'number' },
      otherDeductions: { type: 'number' },
      taxableIncome: { type: 'number' },
      totalTax: { type: 'number' },
      taxPaidAlready: { type: 'number' },
      taxPayable: { type: 'number' },
      tdsPerMonth: { type: 'number' },
    },
  },
};
