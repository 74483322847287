import { getApi, patchApi, postApi } from '../utils/Api';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from '../schemas/SuccessResponseSchema';
import GetKnitHrmsIntegrationSchema, {
  GetKnitHrmsIntegrationSchemaContract,
} from 'schemas/GetKnitHrmsIntegrationSchema';
import GetHrmsEmployeeTypesFromKnit, {
  GetHrmsEmployeeTypesFromKnitContract,
} from 'schemas/GetHrmsEmployeeTypesFromKnit';
import { HrmsSyncType } from 'components/Knit/KnitHrms/types';
import { KnitHrmsApp } from 'components/Knit/types';
import GetHrmsEntityTypesFromKnit, {
  GetHrmsEntityTypesFromKnitContract,
} from 'schemas/GetHrmsEntityTypesFromKnit';

export interface GetAuthSessionTokenApiResponse {
  sessionToken: string;
}

interface UpdateKnitHrmsIntegrationRequestData {
  fields_to_be_synced?: string[];
  employee_types_full_time?: string[]; // @TODO: get a list and create type for these fields
  employee_types_contractor?: string[]; // @TODO: get a list and create type for these fields
  employee_inactive_statuses?: string[]; // @TODO: get a list and create type for these fields
  hrms_provider: string;
  entity_name?: string;
}

interface InitiateHrmsSyncRequestData {
  hrms_provider: string;
  sync_type: HrmsSyncType;
}

const knitApis = {
  getAuthSessionToken: (appId: string, categoryId: string) => {
    return getApi<GetAuthSessionTokenApiResponse>({
      url: `/knit/auth-session-token/${appId}/${categoryId}`,
      responseSchema: {},
    });
  },
  createIntegration: ({
    integrationId,
    hrmsProvider,
  }: {
    integrationId: string;
    hrmsProvider: string;
  }) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/knit/hrms-integration',
      responseSchema: SuccessResponseSchema,
      requestData: {
        integration_id: integrationId,
        hrms_provider: hrmsProvider,
      },
    });
  },
  disableIntegration: (requestData: { hrms_provider: KnitHrmsApp }) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/knit/hrms-integration/disable',
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },
  getHrmsIntegration: (appId: string) => {
    return getApi<GetKnitHrmsIntegrationSchemaContract>({
      url: `/knit/hrms-integration/${appId}`,
      responseSchema: GetKnitHrmsIntegrationSchema,
    });
  },
  updateHrmsIntegration: (requestData: UpdateKnitHrmsIntegrationRequestData) => {
    return patchApi<SuccessResponseSchemaContract, {}>({
      url: 'knit/hrms-integration',
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },
  initiateHrmsSync: (requestData: InitiateHrmsSyncRequestData) => {
    return postApi<SuccessResponseSchemaContract, {}>({
      url: '/knit/hrms-integration/sync',
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },
  getHrmsEmployeeTypes: (appId: string) => {
    return getApi<GetHrmsEmployeeTypesFromKnitContract>({
      url: `/knit/hrms-integration/list-of-employee-types/${appId}`,
      responseSchema: GetHrmsEmployeeTypesFromKnit,
    });
  },
  getHrmsEntityTypes: (appId: string) => {
    return getApi<GetHrmsEntityTypesFromKnitContract>({
      url: `/knit/hrms-integration/entity-types/${appId}`,
      responseSchema: GetHrmsEntityTypesFromKnit,
    });
  },
};

export default knitApis;
