import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { isProduction } from 'utils/getEnvironment';

const sensitiveKeys = [
  'token',
  'code',
  'password',
  'redirect',
  'resetCode',
  'Company_Name',
  'Annual_CTC',
  'Manager_Name',
  'Company_Adress',
  'Employee_Name',
  'HR_Name',
  'csrf-token',
];

export function initSentry() {
  if (isProduction()) {
    Sentry.init({
      dsn: 'https://f98b2a23d501494b8fc2744bc0ff8a52@o515678.ingest.sentry.io/6752425',
      integrations: [new BrowserTracing()],
      release: process.env.COMMIT_ID,
      tracesSampleRate: 0,
      beforeSend: (event, hint) => {
        // scrub sensitive data from URLs before pushing to sentry
        if (event.request?.url) {
          const url = new URL(event.request.url);
          const params = new URLSearchParams(url.search);
          for (const [key, value] of params) {
            if (sensitiveKeys.includes(key)) {
              params.set(key, 'scrubbed');
            }
          }
          url.search = params.toString();
          event.request.url = url.toString();
        }
        return event;
      },
      // commmunity list of common errors to ignore from https://docs.sentry.io/platforms/javascript/configuration/filtering/
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      // commmunity list of common urls to ignore from https://docs.sentry.io/platforms/javascript/configuration/filtering/
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /apis\.google\.com/i,
      ],
    });
  }
}

export function setUserContext(peopleId: number, organizationId: number | undefined) {
  if (isProduction()) {
    Sentry.setUser({ id: peopleId.toString() });
    Sentry.setContext('organization', {
      id: organizationId,
    });
  }
}

export function captureErrorInSentry(error: any) {
  const erroObj = typeof error === 'string' ? new Error(error) : error;
  Sentry.captureException(erroObj);
}
