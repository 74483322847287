import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface PayrollAiAssistantPollSchemaContract {
  conversation_key: string;
  status: string;
  response_type?: string;
  response?: null | {
    user_prompts: string[];
    regime_breakup: null | {
      current_regime: string;
      current_tax_liability: number;
      suggested_regime: string;
      regime_calculation_explanation?: string;
      old_regime: {
        in_hand: number;
        current_deductions: number;
        max_deductions_available: number;
        potential_tax_saving: number;
        total_tax_amount: number;
        [k: string]: unknown;
      };
      new_regime: {
        in_hand: number;
        current_deductions: number;
        max_deductions_available: number;
        potential_tax_saving: number;
        total_tax_amount: number;
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
    tax_saving_breakup: null | {
      suggested_regime: string;
      old_regime_tax_amount: number;
      new_regime_tax_amount: number;
      sections: {
        name: string;
        regime: string;
        savings_possible: number;
        description?: string;
        know_more?: string;
        impact?: string;
        [k: string]: unknown;
      }[];
      old_miscellaneous_advice?: string[];
      new_miscellaneous_advice?: string[];
      new_calculation_explanation?: string;
      old_calculation_explanation?: string;
      [k: string]: unknown;
    };
    error?: null | {
      message: string;
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    conversation_key: { type: 'string' },
    status: { type: 'string' },
    response_type: { type: 'string' },
    response: {
      type: ['null', 'object'],
      properties: {
        user_prompts: { type: 'array', items: { type: 'string' } },
        regime_breakup: {
          type: ['null', 'object'],
          properties: {
            current_regime: { type: ['string'] },
            current_tax_liability: { type: ['integer'] },
            suggested_regime: { type: ['string'] },
            regime_calculation_explanation: { type: ['string'] },
            old_regime: {
              type: ['object'],
              properties: {
                in_hand: { type: ['integer'] },
                current_deductions: { type: ['integer'] },
                max_deductions_available: { type: ['integer'] },
                potential_tax_saving: { type: ['integer'] },
                total_tax_amount: { type: ['integer'] },
              },
              required: [
                'in_hand',
                'current_deductions',
                'max_deductions_available',
                'potential_tax_saving',
                'total_tax_amount',
              ],
            },
            new_regime: {
              type: ['object'],
              properties: {
                in_hand: { type: ['integer'] },
                current_deductions: { type: ['integer'] },
                max_deductions_available: { type: ['integer'] },
                potential_tax_saving: { type: ['integer'] },
                total_tax_amount: { type: ['integer'] },
              },
              required: [
                'in_hand',
                'current_deductions',
                'max_deductions_available',
                'potential_tax_saving',
                'total_tax_amount',
              ],
            },
          },
          required: [
            'current_regime',
            'current_tax_liability',
            'suggested_regime',
            'old_regime',
            'new_regime',
          ],
        },
        tax_saving_breakup: {
          type: ['null', 'object'],
          properties: {
            suggested_regime: { type: 'string' },
            old_regime_tax_amount: { type: 'integer' },
            new_regime_tax_amount: { type: 'integer' },
            sections: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  regime: { type: 'string' },
                  savings_possible: { type: 'integer' },
                  description: { type: 'string' },
                  know_more: { type: 'string' },
                  impact: { type: 'string' },
                },
                required: ['name', 'regime', 'savings_possible'],
              },
            },
            old_miscellaneous_advice: { type: 'array', items: { type: 'string' } },
            new_miscellaneous_advice: { type: 'array', items: { type: 'string' } },
            new_calculation_explanation: { type: ['string'] },
            old_calculation_explanation: { type: ['string'] },
          },
          required: [
            'suggested_regime',
            'old_regime_tax_amount',
            'new_regime_tax_amount',
            'sections',
          ],
        },
        error: {
          type: ['null', 'object'],
          properties: { message: { type: 'string' } },
          required: ['message'],
        },
      },
      required: ['user_prompts', 'regime_breakup', 'tax_saving_breakup'],
    },
  },
  required: ['conversation_key', 'status'],
};
