import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetAddressFromGstinSchemaContract {
  legal_name: string | null;
  primary_address: string | null;
  trade_name: string | null;
  pincode?: string | null;
  state_code?: number | null;
  state_name?: string | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    legal_name: { type: ['string', 'null'] },
    primary_address: { type: ['string', 'null'] },
    trade_name: { type: ['string', 'null'] },
    pincode: { type: ['string', 'null'] },
    state_code: { type: ['number', 'null'] },
    state_name: { type: ['string', 'null'] },
  },
  required: ['legal_name', 'trade_name', 'primary_address'],
};
