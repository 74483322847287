import api from 'api';
import LongPoll, { PollStatuses } from './longPoll';
import { showToastViaEvent, closeToastViaEvent } from './ToastEvents';

function pollForBulkDownload(id: number) {
  sessionStorage.setItem('pollId', String(id));

  const poll = new LongPoll({
    pollFunction: async () => {
      const apiResponse = await api.bulkDownloads.getBulkDownload(id);

      if (apiResponse.status === 'failed') {
        throw 'Your file generation has failed';
      } else if (apiResponse.status === 'success') {
        if (apiResponse.hasFiles) {
          return {
            status: PollStatuses.POLL_STATUS_SUCCESS,
            data: apiResponse,
          };
        } else {
          throw 'No files available to download';
        }
      } else {
        return {
          status: PollStatuses.POLL_STATUS_PROCESSING,
        };
      }
    },
    successCallback: (e) => {
      sessionStorage.removeItem('pollId');
      closeToastViaEvent('bulk-download-' + id);
      showToastViaEvent({
        text: 'We have emailed the documents you requested',
        timeout: 5000,
        type: 'success',
        icon: 'success',
      });
      // Removed the auto downloaded for now, keeping the code for future need
      // api.bulkDownloads.getBulkDownload(id).then((data) => {
      //   if (data.download_url) {
      //     window.location.href = data.download_url;
      //   }
      // });
    },
    failureCallback: (e) => {
      sessionStorage.removeItem('pollId');
      const error = e || 'Your file could not be downloaded. Please try again';
      closeToastViaEvent('bulk-download-' + id);
      showToastViaEvent({
        text: error,
        timeout: 5000,
        type: 'danger',
        icon: 'info',
      });
    },
    tries: 180, // 180 times at an interval of 5000 = 15 minutes
    pollInterval: 10000,
  });

  poll.start();
}

export default pollForBulkDownload;
