import { SplitzExperiment } from 'types';
import { getApi } from 'utils/Api';

export interface Experiments {
  [x: string]: SplitzExperiment;
}

const splitzApis = {
  getExperiments: () => {
    return getApi<Experiments>({
      url: '/splitz/experiments',
      responseSchema: {},
    });
  },
};

export default splitzApis;
