import { put, StrictEffect, takeLatest } from 'redux-saga/effects';
import { getAppErrorDetails } from '../../utils/AppError';
import { setGenericAppError } from '../../reducers/genericError';
import { ActionTypes, INavigateAfter } from '../../reducers/router';

function* navigateAfter(action: INavigateAfter): Generator<StrictEffect, void, never> {
  try {
    console.log('navigateAfter', action);
    yield put(action.payload.action);
    action.payload.history.push(action.payload.route);
  } catch (e) {
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

export default function* () {
  yield takeLatest(ActionTypes.NAVIGATE_AFTER, navigateAfter);
}
