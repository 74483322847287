import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type NotificationApiSchemaContract = {
  title: string;
  desc: string;
  url: string | null;
  id: string;
  type: string;
  date: string;
  readStatus?: boolean;
  isReactEnabled?: boolean;
  [k: string]: unknown;
}[];

export default <JSONSchema4>{
  type: 'array',
  items: {
    type: 'object',
    properties: {
      title: { type: 'string' },
      desc: { type: 'string' },
      url: { type: ['string', 'null'] },
      id: { type: 'string' },
      type: { type: 'string' },
      date: { type: 'string' },
      readStatus: { type: 'boolean' },
      isReactEnabled: { type: 'boolean' },
    },
    required: ['id', 'title', 'date', 'desc', 'type', 'url'],
  },
};
