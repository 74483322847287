import { postApi, getApi } from '../utils/Api';
import { LoanCalculatorRequestSchemaContract } from '../schemas/LoanCalculatorRequestSchema';
import LoanCalculatorResponseSchema, { LoanCalculatorResponseSchemaContract } from '../schemas/LoanCalculatorResponseSchema';
import BasicUserLoanDetailsSchema, { BasicUserLoanDetailsSchemaContract } from '../schemas/BasicUserLoanDetailsSchema';
import { CreateLoanRequestSchemaContract } from '../schemas/CreateLoanRequestSchema';
import LoanDataSchema, { LoanDataSchemaContract } from '../schemas/LoanDataSchema';
import { FetchLoanDataSchemaContract } from '../schemas/FetchLoanDataSchema';
import LoanDataArraySchema, { LoanDataArraySchemaContract } from '../schemas/LoanDataArraySchema';
import LoanRecoveryPreviewResponseSchema, { LoanRecoveryPreviewResponseSchemaContract } from 'schemas/LoanRecoveryPreviewResponseSchema';
import { LoanEditRequestSchemaContract } from '../schemas/LoanEditRequestSchema';
import FullRecoveryAmountSchema, { FullRecoveryAmountSchemaContract } from '../schemas/FullRecoveryAmountSchema';




export default {
    basicUserLoanData: (peopleId: number) => {
        return getApi<BasicUserLoanDetailsSchemaContract>({
            url: `/loans/basicUserData/${peopleId}`,
            responseSchema: BasicUserLoanDetailsSchema
        })
    },
    createLoan: (requestData: CreateLoanRequestSchemaContract) => {
        return postApi<LoanDataSchemaContract, CreateLoanRequestSchemaContract>({
            url: `/loans/create`,
            requestData: requestData,
            responseSchema: LoanDataSchema
        })
    },
    fetchLoanData: (requestData?: FetchLoanDataSchemaContract) => {
        const searchParam = new URLSearchParams();

        if (requestData?.user_id) {
            searchParam.set('user_id', requestData.user_id.toString());
        }

        if (requestData?.status) {
            searchParam.set('status', requestData.status.toString());
        }

        if (requestData?.page) {
            searchParam.set('page', requestData.page.toString());
        }

        if (requestData?.loan_id) {
            searchParam.set('loan_id', requestData.loan_id.toString());
        }

        if (requestData?.limit) {
            searchParam.set('limit', requestData.limit.toString());
        }

        const url = '/loans/fetch?' + searchParam.toString();

        return getApi<LoanDataArraySchemaContract>({
            url: url,
            responseSchema: LoanDataArraySchema
        })
    },
    calculateLoan: (requestData: LoanCalculatorRequestSchemaContract) => {
        return postApi<LoanCalculatorResponseSchemaContract, LoanCalculatorRequestSchemaContract>({
            url: `/loans/calculateLoan`,
            requestData: requestData,
            responseSchema: LoanCalculatorResponseSchema
        })
    },

    recoveryPreview: (loanId: number, action?: string, option?: number, amount?: number, tenure?: number, payroll_month?: string) => {
        const queryParams = new URLSearchParams();

        action && queryParams.append('action', action);
        option && queryParams.append('option', option.toString());
        amount && queryParams.append('amount', amount.toString());
        tenure && queryParams.append('tenure', tenure.toString());
        payroll_month && queryParams.append('payroll_month', payroll_month);

        var url = `loans/${loanId}/recovery-preview?${queryParams.toString()}`;

        return getApi<LoanRecoveryPreviewResponseSchemaContract>({
            url: url,
            responseSchema: LoanRecoveryPreviewResponseSchema,
        })
    },
    editLoan: (requestData: LoanEditRequestSchemaContract, loanId: number) => {
        var url = `loans/${loanId}/edit`;

        return postApi<LoanDataSchemaContract, LoanEditRequestSchemaContract>({
            url: url,
            requestData: requestData,
            responseSchema: LoanDataSchema,
        })
    },
    getFullRecoveryAmountForLoan: (loanId: number) => {
        return getApi<FullRecoveryAmountSchemaContract>({
            url: `/loans/${loanId}/full-recovery`,
            responseSchema: FullRecoveryAmountSchema
        })
    }
}
