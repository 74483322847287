import React, { useEffect } from 'react';
import pollForBulkDownload from 'utils/pollForBulkDownload';

const BulkDownloadPolling = ({ id }: { id: string | null }) => {

  useEffect(() => {
    if (id) {
      pollForBulkDownload(parseInt(id));
    }
  }, [id]);

  return null;
};

export default BulkDownloadPolling;
