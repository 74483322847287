import IntegrationsSchema, { IntegrationsSchemaContract } from 'schemas/IntegrationsSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';

import { getApi, postApi } from 'utils/Api';

type IntegrationRequestContract = {
  suggestion: string;
};
type IntegrationRequest = {
  suggestion: string;
};

export default {
  getIntegrationStatus: () => {
    return getApi<IntegrationsSchemaContract>({
      url: '/integrations',
      responseSchema: IntegrationsSchema,
    });
  },
  addSuggestion: ({ suggestion }: IntegrationRequest) => {
    return postApi<SuccessResponseSchemaContract, IntegrationRequestContract>({
      url: '/integrations',
      responseSchema: SuccessResponseSchema,
      requestData: {
        suggestion: suggestion,
      },
    });
  },
};
