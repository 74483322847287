import React, { useCallback, useEffect, useState } from 'react';
import { ToastsStateType, ShowToastType, ToastFromEventType } from './types';
import ToastItem from './ToastItem';
import { getStaticMediaUrl } from 'utils/Urls';
import Success from '../../../assets/toast/icon--success.svg';
import Info from '../../../assets/toast/icon--info.svg';

import styles from './ToastContainer.module.scss';

const iconMap = {
  success: Success,
  info: Info
};

const ToastContainer = () => {
  const [toasts, setToasts] = useState<ToastsStateType>([]);

  const closeToast = (id: string) => {
    setToasts((prevState) => {
      return prevState.filter((toast) => toast.id !== id);
    });
  };

  const showToast = ({ id, text, type, timeout, icon }: ShowToastType) => {
    const newId = id || Date.now().toString();
    closeToast(newId);

    setToasts((prevState) => {
      return [...prevState, { id: newId, text, timeout, type, icon }];
    });

    return newId;
  };

  const handleReceiveToastFromEvent = useCallback((event: MessageEvent) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    if (event.data && event.data.eventType === 'show-toast') {
      const { id, text, type, timeout, icon }: ToastFromEventType = event.data;
      showToast({ id, text, type, timeout, icon });
    }

    if (event.data && event.data.eventType === 'close-toast') {
      const { id }: ToastFromEventType = event.data;
      id && closeToast(id);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleReceiveToastFromEvent);

    return () => {
      window.removeEventListener('message', handleReceiveToastFromEvent);
    };
  }, []);

  return (
    <>
      {toasts.length > 0 && (
        <div className={styles.ToastParent}>
          {toasts.map((toast) => {
            return (
              <ToastItem key={toast.id} id={toast.id} timeout={toast.timeout} type={toast.type}>
                {toast.icon && (
                  <img
                    height="20px"
                    width="20px"
                    alt=""
                    className='mr-3'
                    src={getStaticMediaUrl(iconMap[toast.icon])}
                  />
                )}
                {toast.text}
              </ToastItem>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ToastContainer;
