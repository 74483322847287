import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetComputedRlopArrearBreakdownContract {
  rlop_arrears_breakdown: {
    [k: string]: {
      name: string;
      type: string;
      amount: number;
      [k: string]: unknown;
    }[];
  } | null;
  is_salary_revision_arrears_modified: boolean;
  salary_revision_breakdown?: {
    [k: string]: {
      name: string;
      type: string;
      amount: number;
      [k: string]: unknown;
    }[];
  } | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    rlop_arrears_breakdown: {
      type: ['object', 'null'],
      additionalProperties: {
        type: 'array',
        items: {
          type: ['object'],
          properties: {
            name: { type: 'string' },
            type: { type: 'string' },
            amount: { type: 'number' },
          },
          required: ['name', 'amount', 'type'],
        },
      },
    },
    is_salary_revision_arrears_modified: { type: 'boolean' },
    salary_revision_breakdown: {
      type: ['object', 'null'],
      additionalProperties: {
        type: 'array',
        items: {
          type: ['object'],
          properties: {
            name: { type: 'string' },
            type: { type: 'string' },
            amount: { type: 'number' },
          },
          required: ['name', 'amount', 'type'],
        },
      },
    },
  },
  required: ['rlop_arrears_breakdown', 'is_salary_revision_arrears_modified'],
};
