import { put, StrictEffect, takeLatest } from 'redux-saga/effects';
import AppSaga from '../../utils/AppSaga';
import { getAppErrorDetails } from '../../utils/AppError';
import { setGenericAppError } from '../../reducers/genericError';
import {
  IFetchInsuranceEstimate,
  insuranceEstimateFetchFailed,
  insuranceEstimationFetchSucceeded,
  insuranceEstimateFetching,
  ActionTypes,
} from '../../reducers/insuranceEstimate';
import GetInsuranceEstimate, {
  GetInsuranceEstimateContract,
} from '../../schemas/GetInsuranceEstimate';

function* fetchInsuranceEstimateSaga(
  action: IFetchInsuranceEstimate,
): Generator<StrictEffect, void, GetInsuranceEstimateContract> {
  try {
    if (action.payload.insuranceId === null) {
      return;
    }
    yield put(insuranceEstimateFetching());

    const insuranceEstimate = yield AppSaga.getApi({
      url: `/insurance/${action.payload.insuranceId}/estimate`,
      responseSchema: GetInsuranceEstimate,
    });
    yield put(insuranceEstimationFetchSucceeded(insuranceEstimate));
  } catch (e) {
    yield put(insuranceEstimateFetchFailed(getAppErrorDetails(e)));
    yield put(setGenericAppError(getAppErrorDetails(e)));
  }
}

export default function* () {
  yield takeLatest(ActionTypes.FETCH_INSURANCE_ESTIMATE, fetchInsuranceEstimateSaga);
}
