import { employeeAdvanceSalaryResponseType } from "components/EmployeeOnboarding/EmployeeAdvanceSalary/types";
import { AllowanceForSalaryModalEmployeeOnboardingAnnualCompensationType, AnnualEmployeeCompensationAllowancePropertiesType, AnnualEmployeeCompensationPerquisitesType, DeductionForSalaryModalEmployeeOnboardingAnnualCompensationType, EmployeeOnboardingAnnualCompensationDataType } from "components/EmployeeOnboarding/EmployeeAnnualCompensation/types";
import { RunPayrollData } from "components/RunPayroll/utils/types";
import { SuccessResponseSchemaContract } from "schemas/SuccessResponseSchema";
import { getApi, postApi } from "utils/Api";

export type EmployeeOnBoardingFetchAnnualCompensationResponse = {
    'data' : EmployeeOnboardingAnnualCompensationDataType
}

export type EmployeeOnBoardingUpdateAnnualCompensationResponse = {
    'success': boolean,
    'errorMessage' : string |  string[]
}

export type SalaryRevisionValidationResponse = {
    annualSalary: number;
    arrears: null | {
        name: string;
        amount: number;
        type: string;
    }[];
    arrearMonth: string;
    salaryModelValidation: string[];
    existingArrearsDistribution?: RunPayrollData['arrearsDistribution']
}

export type EmployeeAnnualyCompensationUpdateRequest = {
    isCustomSalaryStructure: boolean,
    basicSalary: number,
    da: number,
    hra: number,
    specialAllowance: number,
    lta: number,
    npsEmployerContributionLimit: number,
    vpf: number,
    annualSalary:number,
    variablePay:number,
    isEligibleForEmployerESIContribution: boolean,
    employerESIContribution: number,
    isEligibleForEmployerPFContribution: boolean,
    employerPFContribution: number,
    flexiPartnerAllowanceAmount: number,
    allowance : AllowanceForSalaryModalEmployeeOnboardingAnnualCompensationType[],
    deductions : DeductionForSalaryModalEmployeeOnboardingAnnualCompensationType[],
    perquisites : AnnualEmployeeCompensationPerquisitesType | null,
    effectiveFrom: string;
  };

export type EmployeeAdvanceSalaryRequestType = {
    advanceSalaryEMI:number,
    advanceSalary:number,
}
const employeeOnboarding = {
	fetchEmployeeAnnualyCompensation : (employeeId: number) => {
		return getApi<EmployeeOnBoardingFetchAnnualCompensationResponse>({
		url: `/employeeOnboarding/${employeeId}/employeeAnnualCompensation`,
		responseSchema: {},
		});
	},

    updateEmployeeAnnualyCompensation : (employeeId: number,{
        isCustomSalaryStructure,
        basicSalary,
        da,
        hra,
        specialAllowance,
        lta,
        npsEmployerContributionLimit,
        vpf,
        annualSalary,
        variablePay,
        isEligibleForEmployerESIContribution,
        employerESIContribution,
        isEligibleForEmployerPFContribution,
        employerPFContribution,
        flexiPartnerAllowanceAmount,
        allowance,
        deductions,
        perquisites,
        effectiveFrom
      }: EmployeeAnnualyCompensationUpdateRequest) => {
		return postApi<SuccessResponseSchemaContract,{}>({
		    url: `/employeeOnboarding/${employeeId}/employeeAnnualCompensation`,
		    responseSchema: {},
            requestData: {
                isCustomSalaryStructure: isCustomSalaryStructure,
                basicSalary: basicSalary,
                da: da,
                hra: hra,
                specialAllowance: specialAllowance,
                lta: lta,
                npsEmployerContributionLimit: npsEmployerContributionLimit,
                vpf: vpf,
                annualSalary: annualSalary,
                variablePay: variablePay,
                isEligibleForEmployerESIContribution: isEligibleForEmployerESIContribution,
                employerESIContribution: employerESIContribution,
                isEligibleForEmployerPFContribution: isEligibleForEmployerPFContribution,
                employerPFContribution: employerPFContribution,
                flexiPartnerAllowanceAmount: flexiPartnerAllowanceAmount,
                allowance:allowance,
                deductions:deductions,
                perquisites:perquisites,
                effectiveFrom
              },
		});
	},

    validateEmployeeAnnualyCompensation : (employeeId: number,{
        isCustomSalaryStructure,
        basicSalary,
        da,
        hra,
        specialAllowance,
        lta,
        npsEmployerContributionLimit,
        vpf,
        annualSalary,
        variablePay,
        isEligibleForEmployerESIContribution,
        employerESIContribution,
        isEligibleForEmployerPFContribution,
        employerPFContribution,
        flexiPartnerAllowanceAmount,
        allowance,
        deductions,
        effectiveFrom
      }: EmployeeAnnualyCompensationUpdateRequest) => {
		return postApi<SalaryRevisionValidationResponse,{}>({
		    url: `/employeeOnboarding/${employeeId}/employeeAnnualCompensation/validate`,
		    responseSchema: {},
            requestData: {
                isCustomSalaryStructure: isCustomSalaryStructure,
                basicSalary: basicSalary,
                da: da,
                hra: hra,
                specialAllowance: specialAllowance,
                lta: lta,
                npsEmployerContributionLimit: npsEmployerContributionLimit,
                vpf: vpf,
                annualSalary: annualSalary,
                variablePay: variablePay,
                isEligibleForEmployerESIContribution: isEligibleForEmployerESIContribution,
                employerESIContribution: employerESIContribution,
                isEligibleForEmployerPFContribution: isEligibleForEmployerPFContribution,
                employerPFContribution: employerPFContribution,
                flexiPartnerAllowanceAmount: flexiPartnerAllowanceAmount,
                allowance:allowance,
                deductions:deductions,
                effectiveFrom
              },
		});
	},

    fetchAdvanceSalary : (employeeId: number) => {
        return getApi<employeeAdvanceSalaryResponseType>({
            url: `/employeeOnboarding/${employeeId}/fetchAdvanceSalary`,
            responseSchema: {},
        })
    },

    updateAdvanceSalary : (employeeId: number,{
        advanceSalaryEMI,
        advanceSalary
      }: EmployeeAdvanceSalaryRequestType) => {
        return postApi<SuccessResponseSchemaContract,{}>({
            url: `/employeeOnboarding/${employeeId}/updateAdvanceSalary`,
            responseSchema: {},
            requestData: {
                advanceSalaryEMI:advanceSalaryEMI,
                advanceSalary:advanceSalary
              },
        })
    }
}

export default employeeOnboarding;
