import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface SlackOauthMappingContract {
  id: number;
  slack_team_id?: string | null;
  slack_team_name?: string | null;
  organization_id: number;
  state_param: string;
  bot_id?: string | null;
  app_id?: string | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    id: { type: 'integer' },
    slack_team_id: { type: ['string', 'null'] },
    slack_team_name: { type: ['string', 'null'] },
    organization_id: { type: 'integer' },
    state_param: { type: 'string' },
    bot_id: { type: ['string', 'null'] },
    app_id: { type: ['string', 'null'] },
  },
  required: ['id', 'organization_id', 'state_param'],
};
