import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface LoanDataArraySchemaContract {
  current_page: number;
  data: {
    id: number;
    people_id: number;
    organization_id: number;
    people_organization_id?: number;
    transaction_id?: number | null;
    interest_type?: number;
    principal: number;
    interest_rate: number;
    tenure: number;
    perk_rate: number | null;
    total_interest: number | null;
    total_amount: number | null;
    amount_paid: number | null;
    remaining_amount_to_be_paid: number | null;
    duration_completed: number | null;
    remaining_duration: number | null;
    monthly_total_emi?: number | null;
    monthly_interest_emi?: number | null;
    monthly_principal_emi?: number | null;
    name?: string | null;
    employee_id?: string;
    recovery_start_from?: string;
    status: number;
    remarks?: string | null;
    total_perk?: number | null;
    verified_at?: string | null;
    verified_by?: number | null;
    created_by?: number;
    skipped_months: string[] | null;
    is_next_emi_month_finalized: boolean;
    next_emi_month: string;
    [k: string]: unknown;
  }[];
  first_page_url: null | string;
  from: number | null;
  last_page: number | null;
  last_page_url: null | string;
  links: {
    url?: null | string;
    label?: null | string;
    active?: null | boolean;
    [k: string]: unknown;
  }[];
  next_page_url: null | string;
  path: null | string;
  per_page: null | number;
  prev_page_url: null | string;
  to: null | number;
  total: number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    current_page: { type: ['integer'] },
    data: {
      type: ['array'],
      title: '',
      items: {
        type: 'object',
        properties: {
          id: { type: ['integer'] },
          people_id: { type: ['integer'] },
          organization_id: { type: ['integer'] },
          people_organization_id: { type: ['integer'] },
          transaction_id: { type: ['integer', 'null'] },
          interest_type: { type: ['integer'] },
          principal: { type: ['integer'] },
          interest_rate: { type: ['number'] },
          tenure: { type: ['integer'] },
          perk_rate: { type: ['number', 'null'] },
          total_interest: { type: ['number', 'null'] },
          total_amount: { type: ['number', 'null'] },
          amount_paid: { type: ['number', 'null'] },
          remaining_amount_to_be_paid: { type: ['number', 'null'] },
          duration_completed: { type: ['integer', 'null'] },
          remaining_duration: { type: ['integer', 'null'] },
          monthly_total_emi: { type: ['number', 'null'] },
          monthly_interest_emi: { type: ['number', 'null'] },
          monthly_principal_emi: { type: ['number', 'null'] },
          name: { type: ['string', 'null'] },
          employee_id: { type: ['string'] },
          recovery_start_from: { type: ['string'], format: 'date' },
          status: { type: ['integer'] },
          remarks: { type: ['string', 'null'] },
          total_perk: { type: ['number', 'null'] },
          verified_at: { type: ['string', 'null'], format: 'date' },
          verified_by: { type: ['integer', 'null'] },
          created_by: { type: ['integer'] },
          skipped_months: { type: ['array', 'null'], items: { type: ['string'] } },
          is_next_emi_month_finalized: { type: ['boolean'] },
          next_emi_month: { type: ['string'], format: 'date' },
        },
        required: [
          'id',
          'people_id',
          'organization_id',
          'principal',
          'interest_rate',
          'tenure',
          'status',
          'perk_rate',
          'total_interest',
          'total_amount',
          'amount_paid',
          'remaining_amount_to_be_paid',
          'duration_completed',
          'remaining_duration',
          'skipped_months',
          'is_next_emi_month_finalized',
          'next_emi_month',
        ],
      },
    },
    first_page_url: { type: ['null', 'string'] },
    from: { type: ['number', 'null'] },
    last_page: { type: ['number', 'null'] },
    last_page_url: { type: ['null', 'string'] },
    links: {
      type: ['array'],
      title: '',
      items: {
        type: 'object',
        properties: {
          url: { type: ['null', 'string'] },
          label: { type: ['null', 'string'] },
          active: { type: ['null', 'boolean'] },
        },
      },
    },
    next_page_url: { type: ['null', 'string'] },
    path: { type: ['null', 'string'] },
    per_page: { type: ['null', 'number'] },
    prev_page_url: { type: ['null', 'string'] },
    to: { type: ['null', 'integer'] },
    total: { type: ['integer'] },
  },
  required: [
    'current_page',
    'data',
    'first_page_url',
    'from',
    'last_page',
    'last_page_url',
    'links',
    'next_page_url',
    'path',
    'per_page',
    'prev_page_url',
    'to',
    'total',
  ],
};
