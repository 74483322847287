export enum EActionTypes {
  // Logged in user related action types
  FETCH_LOGGED_IN_USER = 'FETCH_LOGGED_IN_USER',
  LOGGED_IN_USER_FETCH_SUCCEDED = 'LOGGED_IN_USER_FETCH_SUCCEDED',
  LOGGED_IN_USER_FETCH_FAILED = 'LOGGED_IN_USER_FETCH_FAILED',

  // Generic error related action types
  SET_GENERIC_ERROR = 'SET_GENERIC_ERROR',
  RESET_GENERIC_ERROR = 'RESET_GENERIC_ERROR',
}
