import { IInsuranceEmployee } from '.';
import { GetOrgEmployeeForInsuranceSchemaContract } from '../../schemas/GetOrgEmployeeForInsuranceSchema';

function getAge(dob: string) {
  if (!/^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/.test(dob)) {
    return -1;
  }
  var ageDifMs = Date.now() - new Date(dob).getTime();
  let negativeMultiplier = 1;
  if (ageDifMs < 0) {
    negativeMultiplier = -1;
    ageDifMs = -1 * ageDifMs;
  }
  var ageDate = new Date(ageDifMs); // ms from epoch
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  return age * negativeMultiplier;
}

export function isValidAge(dob: string) {
  if (!/^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/.test(dob)) {
    return false;
  }
  const age = getAge(dob);
  if (age < 0) {
    return false;
  }
  return age >= 0 && age <= 80;
}

export function isValidKidAge(dob: string) {
  if (!/^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/.test(dob)) {
    return false;
  }
  const age = getAge(dob);
  if (age < 0) {
    return false;
  }
  return age >= 0 && age <= 25;
}

export function isValidHireDate(hireDate: string) {
  if (!/^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/.test(hireDate)) {
    return false;
  }
  var employmentTimeDifMs = Date.now() - new Date(hireDate).getTime();
  if (employmentTimeDifMs < 0) {
    return false;
  }
  var employmentTimeDate = new Date(employmentTimeDifMs); // ms from epoch
  const employmentTime = Math.abs(employmentTimeDate.getUTCFullYear() - 1970);
  return employmentTime >= 0 && employmentTime <= 70;
}

export function isValidPhoneNumber(phoneNumber: string) {
  let regExp = /^(\+[0-9][0-9]-?)?[6-9][0-9]{9}$/;
  let phone = phoneNumber.match(regExp);
  if (phone) {
    return true;
  }
  return false;
}

export function areDependentFieldsAvailable({
  name,
  dateOfBirth,
  gender,
  relation,
}: Partial<GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]>) {
  return (
    name &&
    name.length > 0 &&
    dateOfBirth &&
    dateOfBirth.length > 0 &&
    relation &&
    relation.length > 0 &&
    gender &&
    gender.length
  );
}

export function areEmployeeFieldsAvailable({
  name,
  dob,
  gender,
  phoneNumber,
  isAdmin,
  hireDate,
}: Partial<GetOrgEmployeeForInsuranceSchemaContract & { isAdmin: boolean }>) {
  return (
    name &&
    name.length > 0 &&
    dob &&
    dob.length > 0 &&
    gender &&
    gender.length &&
    phoneNumber &&
    phoneNumber.length &&
    ((isAdmin && hireDate && hireDate.length > 0) || !isAdmin)
  );
}

export function validateEmployeeFields(
  details: Partial<GetOrgEmployeeForInsuranceSchemaContract & { isAdmin: boolean }>,
) {
  if (!areEmployeeFieldsAvailable(details)) {
    return 'Please fill all details';
  }
  if (!details.phoneNumber || !isValidPhoneNumber(details.phoneNumber)) {
    return 'Invalid phone number';
  }

  if (!details.dob || !isValidAge(details.dob)) {
    return 'Invalid age. It should be between 0 to 80 years';
  }

  if (details.isAdmin && (!details.hireDate || !isValidHireDate(details.hireDate))) {
    return 'Invalid hire date';
  }

  return null;
}

export function validateDependentFields(
  details: Partial<GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]>,
) {
  if (!areDependentFieldsAvailable(details) || !details.relation) {
    return 'Please fill all details';
  }

  if (
    ['son', 'daughter'].includes(details.relation) &&
    !(details.dateOfBirth && isValidKidAge(details.dateOfBirth))
  ) {
    return 'Cannot cover for this age. Kid should be between 0 to 25 years';
  }

  if (
    !['son', 'daughter'].includes(details.relation) &&
    !(details.dateOfBirth && isValidAge(details.dateOfBirth))
  ) {
    return 'Cannot cover for this age. It should be between 0 to 80 years';
  }

  return null;
}

export function validateOrderOfAges(
  employeeDetails: Partial<GetOrgEmployeeForInsuranceSchemaContract>,
) {
  const dependentsDetails = employeeDetails.dependents;
  if (
    !employeeDetails.dob ||
    !isValidAge(employeeDetails.dob) ||
    !dependentsDetails ||
    dependentsDetails.some(
      (dependentDetails) =>
        !dependentDetails.dateOfBirth || !isValidAge(dependentDetails.dateOfBirth),
    )
  ) {
    return;
  }
  const employeeAge = getAge(employeeDetails.dob);
  const spousesAge = dependentsDetails
    .filter((dependentDetails) => dependentDetails.relation === 'spouse')
    .map((dependentDetails) =>
      dependentDetails.dateOfBirth ? getAge(dependentDetails.dateOfBirth) : 0,
    );
  const kidsAge = dependentsDetails
    .filter(
      (dependentDetails) =>
        dependentDetails.relation && ['son', 'daughter'].includes(dependentDetails.relation),
    )
    .map((dependentDetails) =>
      dependentDetails.dateOfBirth ? getAge(dependentDetails.dateOfBirth) : 0,
    );
  const parentsAge = dependentsDetails
    .filter(
      (dependentDetails) =>
        dependentDetails.relation &&
        ['father', 'mother', 'fatherInLaw', 'motherInLaw'].includes(dependentDetails.relation),
    )
    .map((dependentDetails) =>
      dependentDetails.dateOfBirth ? getAge(dependentDetails.dateOfBirth) : 0,
    );

  if (employeeAge < 18) {
    return 'Employee age should be greater than or equal to 18';
  }

  if (Math.max(...kidsAge) >= employeeAge) {
    return 'Kid(s) age must be less than employee age.';
  }

  if (employeeAge >= Math.min(...parentsAge)) {
    return 'Employee age must be less than parent(s) age.';
  }

  if (Math.max(...kidsAge) >= Math.min(...spousesAge)) {
    return 'Kid(s) age must be less than spouse age.';
  }

  if (Math.max(...spousesAge) >= Math.min(...parentsAge)) {
    return 'Spouse age must be less than parent(s) age.';
  }

  return null;
}

export function areAllBasicEmployeeAndDependentFieldsCorrect(
  insuranceEmployee: IInsuranceEmployee,
  isAdmin: boolean,
) {
  const currentInsuranceEmployeeData = insuranceEmployee.data;

  if (!currentInsuranceEmployeeData) {
    return false;
  }

  if (currentInsuranceEmployeeData.detailsConfirmedAt === null) {
    return false;
  }

  if (validateEmployeeFields({ ...currentInsuranceEmployeeData, isAdmin })) {
    return false;
  }

  for (const dependent of currentInsuranceEmployeeData.dependents) {
    if (validateDependentFields(dependent)) {
      return false;
    }
  }

  if (insuranceEmployee.data && validateOrderOfAges(insuranceEmployee.data)) {
    return false;
  }

  return true;
}
