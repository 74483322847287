import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetDetailsByPincodeSchemaContract {
  state?: string | null;
  city?: string | null;
  stateCode?: number | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    state: { type: ['string', 'null'] },
    city: { type: ['string', 'null'] },
    stateCode: { type: ['number', 'null'] },
  },
};
