import React, { useEffect, useState } from 'react';

import { getStaticMediaUrl } from 'utils/Urls';
import Checked from 'assets/checkbox-checked.png';
import DisabledCheck from 'assets/checkbox-checked-grey.png';
import DisabledUnchecked from 'assets/checkbox-unchecked-grey.png';
import Intermediate from 'assets/checkbox-intermediate.png';

import styles from './index.module.scss';

const Checkbox = ({
  id,
  label,
  className,
  checked,
  disabled,
  isIntermediate,
  labelClassName,
  ref,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  id?: string;
  label?: React.ReactNode;
  className?: string;
  isIntermediate?: boolean;
  labelClassName?: string;
  ref?: React.Ref<any>
}) => {

  return (
    <label className={styles['checkbox-wrapper']}>
      <div className={`${styles['checkbox-main']} ${className ? className : ''}`}>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        {isIntermediate ? (
          <img className={styles['intermediate']} src={getStaticMediaUrl(Intermediate)} alt="" />
        ) : checked ? (
          <img
            className={styles['checked']}
            src={getStaticMediaUrl(disabled ? DisabledCheck : Checked)}
            alt=""
          />
        ) : disabled ? (
          <img
            className={styles['disabled']}
            src={getStaticMediaUrl(DisabledUnchecked)}
            alt=""
          />
        ) : null}
      </div>
      {!!label && <span className={`${styles['checkbox-label']} ${labelClassName ? labelClassName : ""}`}>{label}</span>}
    </label>
  );
};

export default Checkbox;
