import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { markNotificationsRead } from '../../../../../reducers/notifications';
import { SimpleListLoader } from '../../../../../components/ui/Loaders';
import { NotificationItem } from '../NotificationItem';
import useReduxSelector from '../../../../../utils/useReduxSelector';

export function NotificationsList({
  handleCloseNotification,
}: {
  handleCloseNotification: EventListener;
}) {
  const dispatch = useDispatch();
  const notifications = useReduxSelector((state) => state.notification);

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseNotification);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleCloseNotification);
    };
  }, []);

  // unmounting of component -> drawer closed
  useEffect(() => {
    return () => {
      let ids: string[] = [];

      Object.keys(notifications.data).forEach((month) => {
        notifications.data[month].forEach((individual) => {
          if (individual.readStatus === false) {
            // individual.readStatus = true;
            ids.push(individual.id);
          }
        });
      });

      ids.length > 0 && dispatch(markNotificationsRead(ids));

      // change read status in state variable
      // make api call
      // invalidate api
    };
  }, []);

  if (notifications.isFetching) {
    return (
      <div>
        <SimpleListLoader isLoading={true}>Fetching notifications</SimpleListLoader>
      </div>
    );
  }

  if (Object.keys(notifications.data).length === 0) {
    return <div className="shadow-lg text-white-o-80 h-128 flex justify-center items-center">No notifications</div>;
  }

  return (
    <ul className="text-white divide-y divide-opacity-10 mt-0 divide-gray-600">
      {notifications.data &&
        Object.keys(notifications.data).map((month) => (
          <React.Fragment key={month.toLocaleUpperCase()}>
            <li className="w-full p-2 text-gray-500 px-10 py-3 text-lg tracking-wider ">
              {month.toLocaleUpperCase()}
            </li>
            {notifications.data[month].map((individual) => (
              <li key={individual.id} className="w-full p-0 flex cursor-text">
                <NotificationItem data={individual} />
              </li>
            ))}
          </React.Fragment>
        ))}
    </ul>
  );
}
