import React, { useEffect, useRef, useState } from 'react';
import pluralize from 'pluralize';

import { getStaticMediaUrl } from 'utils/Urls';
import arrowIcon from 'assets/icon--down-arrow.svg';
import CheckBox from 'components/ui/Checkbox';
import { replaceHTMLSpecialChars } from 'utils/Strings';

import styles from './index.module.scss';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';

export interface MultiSelectOption {
  key: string;
  value: string;
}
export interface MultiSelectProps {
  id: string;
  options: MultiSelectOption[];
  name: string;
  value?: string[];
  onChange: (keys: string[]) => void;
  className?: string;
  disabled?: boolean;
  disableChipsDisplay?: boolean;
  disableAllOption?: boolean;
}

const getSelectedOptions = (options: MultiSelectOption[], seletedKeys?: string[]) => {
  return seletedKeys ? options?.filter((option) => seletedKeys.includes(option.key)) : [];
};

const MultiSelect = ({
  id,
  options,
  name,
  value,
  onChange,
  className,
  disabled,
  disableChipsDisplay,
  disableAllOption,
}: MultiSelectProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [selected, setSelected] = useState<MultiSelectOption[]>(() =>
    getSelectedOptions(options, value),
  );

  const ref = useRef<HTMLDivElement>(null);
  const chipContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        if (isOpened) {
          setSelected(() => getSelectedOptions(options, value));
          setIsOpened(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpened]);

  useEffect(() => {
    setSelected(() => getSelectedOptions(options, value));
  }, [JSON.stringify(value?.sort())]);

  const handleToggle = () => {
    setIsOpened((prev) => !prev);
  };

  const onApply = () => {
    onChange(selected.map((option) => option.key));
    setIsOpened(false);
  };

  const toggleAll = () => {
    const isSelected = selected.length !== options.length;
    setSelected(isSelected ? [...options] : []);
  };

  const isSelected = (option: MultiSelectOption) => {
    return !!selected.find((value) => value.key == option.key);
  };

  const toggleOption = (option: MultiSelectOption) => {
    const isAvailable = isSelected(option);
    if (isAvailable) {
      setSelected(selected.filter((value) => value.key !== option.key));
    } else {
      setSelected([...selected, { ...option }]);
    }
  };

  useEffect(() => {
    const container = chipContainerRef?.current as HTMLDivElement;
    if (container) {
      container.scrollLeft = container.scrollWidth;
    }
  }, [selected]);

  const onChipClose = (closedOption: MultiSelectOption) => {
    onChange(
      selected
        .filter((option) => option.key !== closedOption.key)
        .map((option) => option.key)
    );
  };

  const isSameSelection =
    value &&
    JSON.stringify(value.sort()) === JSON.stringify(selected.map((option) => option.key).sort());
  
  const isOptionsAvailable = options?.length>0;

  return (
    <div
      id={id}
      className={`relative w-full text-left ${className ?? ''} ${
        disabled ? ' opacity-75 pointer-events-none	cursor-not-allowed ' : ''
      }`}
      ref={ref}>
      <div
        className={`border-solid border-grey rounded ${styles['multi-select-container']} Blade_FormInputStyles`}
        onClick={handleToggle}
        tabIndex={-1}>
        {selected?.length ? (
          !disableChipsDisplay ? (
            <div
              id="chip-container"
              ref={chipContainerRef}
              className={`${styles['multi-select-chip-container']} scroll-smooth`}>
              {selected.map((option) => (
                <MultiSelectChip key={option.key} option={option} onClose={onChipClose} />
              ))}
            </div>
          ) : (
            <span className={styles['multi-select-all-placeholder']}>
              {selected?.length === options.length
                ? `All ${pluralize(name)}`
                : `${selected?.length} ${pluralize(name, selected?.length)}`}
            </span>
          )
        ) : (
          <span className={styles['multi-select-all-placeholder']}>{name}</span>
        )}
        <img
          className={`inline-block transition duration-300 ${
            isOpened ? 'transform rotate-180 ' : ''
          }`}
          src={getStaticMediaUrl(arrowIcon)}
        />
      </div>
      <div
        className={`${isOpened ? 'flex' : 'hidden'} absolute w-full z-10 ${
          styles['multi-select-options']
        }`}>
        <ul className={styles['option-container']}>
          {!disableAllOption && (
            <li className={styles['option']}>
              <label htmlFor={`multi-select-${id}-all`} className={styles['option-value']}>
                {`All ${pluralize(name, options.length)}`}
              </label>
              <CheckBox
                id={`multi-select-${id}-all`}
                className="mr-4"
                checked={selected.length == options.length}
                onChange={toggleAll}
              />
            </li>
          )}
          {options?.length
            ? options.map((option) => {
                const optionId = `multi-select-option-${id}-${option.key}`;
                return (
                  <li key={option.key} className={styles['option']}>
                    <label htmlFor={optionId} className={styles['option-value']}>
                      {replaceHTMLSpecialChars(option.value)}
                    </label>
                    <CheckBox
                      id={optionId}
                      className="mr-4"
                      checked={isSelected(option)}
                      onChange={() => toggleOption(option)}
                    />
                  </li>
                );
              })
            : null}
        </ul>
        <div
          onClick={onApply}>
          <StandardSecondaryButton disabled={isSameSelection} className={'!w-full h-[36px] !m-0'}>
            {isOptionsAvailable ? 'Apply' : 'No options'}
          </StandardSecondaryButton>
        </div>
      </div>
    </div>
  );
};

const MultiSelectChip = ({
  option,
  onClose,
}: {
  option: MultiSelectOption;
  onClose: (option: MultiSelectOption) => void;
}) => {
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClose(option);
  };

  return (
    <span
      className={`${styles['multi-select-chip']} flex align-center w-max cursor-pointer transition duration-300 ease`}>
      {replaceHTMLSpecialChars(option.value)}
      <div
        className={`bg-transparent hover focus:outline-none ${styles['multi-select-chip-close']}`}
        onClick={onClick}>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="times"
          className="w-3 ml-3"
          role="img"
          viewBox="0 0 352 512">
          <path
            fill="rgba(255, 255, 255, 0.38)"
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
        </svg>
      </div>
    </span>
  );
};

export default MultiSelect;
