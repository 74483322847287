import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface EmployeeTaxReportSchemaContract {
  name: string;
  email: string;
  pan: string | null;
  title: string | null;
  payrollMonth: string;
  orgName: string;
  employeeId: string;
  doj: string | null;
  isOldTaxRegime: boolean;
  earnings: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      /**
       * This interface was referenced by `undefined`'s JSON-Schema definition
       * via the `patternProperty` ".*".
       */
      [k: string]: {
        name: string;
        label: string;
        amount: number;
        [k: string]: unknown;
      };
    };
  };
  earningsTotal: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: number;
  };
  grossIncome: {
    [k: string]: unknown;
  };
  exemptions: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      /**
       * This interface was referenced by `undefined`'s JSON-Schema definition
       * via the `patternProperty` ".*".
       */
      [k: string]: {
        name: string;
        label: string;
        amount: number;
        [k: string]: unknown;
      };
    };
  };
  exemptionsTotal: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: number;
  };
  totalSec10Exemptions: number;
  deductions: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      /**
       * This interface was referenced by `undefined`'s JSON-Schema definition
       * via the `patternProperty` ".*".
       */
      [k: string]: {
        name: string;
        label: string;
        amount: number;
        [k: string]: unknown;
      };
    };
  };
  deductionsTotal: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: number;
  };
  taxDeductions: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      label: string;
      amount: number;
      maxLimit: number;
      declared: number;
      proofAmount: number;
      [k: string]: unknown;
    };
  };
  taxDeductionsTotal: number;
  flexiWallets: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      /**
       * This interface was referenced by `undefined`'s JSON-Schema definition
       * via the `patternProperty` ".*".
       */
      [k: string]: {
        name: string;
        label: string;
        amount: number;
        [k: string]: unknown;
      };
    };
  };
  flexiWalletsTotal: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: number;
  };
  perquisites: {
    [k: string]: unknown;
  } | null;
  standardDeduction: number;
  totalTax: number;
  payrollMonthsThisYear: number;
  taxPayable: number;
  tdsPerMonth?: number;
  totalPerquisite: number;
  loanPerquisite: number;
  nonOpfinPayrollDetails: {
    [k: string]: unknown;
  };
  grossAfterSec10: number;
  grossAfterSec10_PT_SD: number;
  totalNonOpfinSalary: number;
  grossE2_PPE: number;
  grossAfterExemptions: number;
  taxableIncome: number;
  totalTaxPaid: number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    name: { type: ['string'] },
    email: { type: ['string'] },
    pan: { type: ['string', 'null'] },
    title: { type: ['string', 'null'] },
    payrollMonth: { type: ['string'] },
    orgName: { type: ['string'] },
    employeeId: { type: ['string'] },
    doj: { type: ['string', 'null'] },
    isOldTaxRegime: { type: ['boolean'] },
    earnings: {
      type: ['object'],
      patternProperties: {
        '.*': {
          type: ['object'],
          patternProperties: {
            '.*': {
              type: 'object',
              properties: {
                name: { type: 'string' },
                label: { type: 'string' },
                amount: { type: 'number' },
              },
              required: ['name', 'label', 'amount'],
            },
          },
        },
      },
    },
    earningsTotal: { type: ['object'], patternProperties: { '.*': { type: 'number' } } },
    grossIncome: { type: ['object'] },
    exemptions: {
      type: ['object'],
      patternProperties: {
        '.*': {
          type: ['object'],
          patternProperties: {
            '.*': {
              type: 'object',
              properties: {
                name: { type: 'string' },
                label: { type: 'string' },
                amount: { type: 'number' },
              },
              required: ['name', 'label', 'amount'],
            },
          },
        },
      },
    },
    exemptionsTotal: { type: ['object'], patternProperties: { '.*': { type: 'number' } } },
    totalSec10Exemptions: { type: ['number'] },
    deductions: {
      type: ['object'],
      patternProperties: {
        '.*': {
          type: ['object'],
          patternProperties: {
            '.*': {
              type: 'object',
              properties: {
                name: { type: 'string' },
                label: { type: 'string' },
                amount: { type: 'number' },
              },
              required: ['name', 'label', 'amount'],
            },
          },
        },
      },
    },
    deductionsTotal: { type: ['object'], patternProperties: { '.*': { type: 'number' } } },
    taxDeductions: {
      type: ['object'],
      patternProperties: {
        '.*': {
          type: 'object',
          properties: {
            label: { type: 'string' },
            amount: { type: 'number' },
            maxLimit: { type: 'number' },
            declared: { type: 'number' },
            proofAmount: { type: 'number' },
          },
          required: ['label', 'amount', 'maxLimit', 'declared', 'proofAmount'],
        },
      },
    },
    taxDeductionsTotal: { type: ['number'] },
    flexiWallets: {
      type: ['object'],
      patternProperties: {
        '.*': {
          type: ['object'],
          patternProperties: {
            '.*': {
              type: 'object',
              properties: {
                name: { type: 'string' },
                label: { type: 'string' },
                amount: { type: 'number' },
              },
              required: ['name', 'label', 'amount'],
            },
          },
        },
      },
    },
    flexiWalletsTotal: { type: ['object'], patternProperties: { '.*': { type: 'number' } } },
    perquisites: { type: ['object', 'null'] },
    standardDeduction: { type: ['number'] },
    totalTax: { type: ['number'] },
    payrollMonthsThisYear: { type: ['number'] },
    taxPayable: { type: ['number'] },
    tdsPerMonth: { type: ['number'] },
    totalPerquisite: { type: ['number'] },
    loanPerquisite: { type: ['number'] },
    nonOpfinPayrollDetails: { type: ['object'] },
    grossAfterSec10: { type: ['number'] },
    grossAfterSec10_PT_SD: { type: ['number'] },
    totalNonOpfinSalary: { type: ['number'] },
    grossE2_PPE: { type: ['number'] },
    grossAfterExemptions: { type: ['number'] },
    taxableIncome: { type: ['number'] },
    totalTaxPaid: { type: ['number'] },
  },
  required: [
    'name',
    'email',
    'pan',
    'title',
    'payrollMonth',
    'orgName',
    'employeeId',
    'doj',
    'isOldTaxRegime',
    'earnings',
    'earningsTotal',
    'grossIncome',
    'exemptions',
    'exemptionsTotal',
    'totalSec10Exemptions',
    'deductions',
    'deductionsTotal',
    'taxDeductions',
    'taxDeductionsTotal',
    'flexiWallets',
    'flexiWalletsTotal',
    'perquisites',
    'standardDeduction',
    'totalTax',
    'payrollMonthsThisYear',
    'taxPayable',
    'totalPerquisite',
    'loanPerquisite',
    'nonOpfinPayrollDetails',
    'grossAfterSec10',
    'grossAfterSec10_PT_SD',
    'totalNonOpfinSalary',
    'grossE2_PPE',
    'grossAfterExemptions',
    'taxableIncome',
    'totalTaxPaid',
  ],
};
