import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type CamsSettingsSchemaContract = {
  id: number;
  device_id: string;
  organization_id: number;
  auth_token: string;
  api_version: number;
  name?: string | null;
  attendance_timestamp?: string | null;
  [k: string]: unknown;
}[];

export default <JSONSchema4>{
  type: ['array'],
  title: '',
  items: {
    type: 'object',
    properties: {
      id: { type: 'integer' },
      device_id: { type: ['string'] },
      organization_id: { type: 'integer' },
      auth_token: { type: ['string'] },
      api_version: { type: 'integer' },
      name: { type: ['string', 'null'] },
      attendance_timestamp: { type: ['string', 'null'] },
    },
    required: ['id', 'device_id', 'organization_id', 'auth_token', 'api_version'],
  },
};
