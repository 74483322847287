import { useHistory } from 'react-router-dom';
import { GetOrgEmployeesForInsuranceSchemaContract } from '../../schemas/GetOrgEmployeesForInsuranceSchema';
import { IAppErrorDetails } from '../../utils/AppError';
import { isEqual } from '../../utils/Comparator';
import shallowCompareObjects from '../../utils/shallowCompareObjects';

export interface IInsuranceEmployees {
  hasError: boolean;
  data: GetOrgEmployeesForInsuranceSchemaContract;
  errorData: IAppErrorDetails | null;
  isFetching: boolean;
  fetchedFor: InsuranceEmployeesFetchOptions;
  isNeverFetched: boolean;
  fetchingFresh: boolean;
  fetchingSilently: boolean;
}
export type InsuranceEmployeeObject = GetOrgEmployeesForInsuranceSchemaContract['items'][number];
export interface InsuranceEmployeesFetchOptions {
  search?: string | null;
  includes?: InsuranceEmployeeObject['status'][] | null;
  limit?: number;
  offset?: number;
  insuranceId?: number | null;
  organizationId: number | null;
  fetchFresh?: boolean;
}

export enum ActionTypes {
  INSURANCE_EMPLOYEES_FETCH_SUCCEEDED = 'INSURANCE_EMPLOYEES_FETCH_SUCCEEDED',
  INSURANCE_EMPLOYEES_FETCH_FAILED = 'INSURANCE_EMPLOYEES_FETCH_FAILED',
  FETCH_INSURANCE_EMPLOYEES = 'FETCH_INSURANCE_EMPLOYEES',
  FETCH_NEXT_INSURANCE_EMPLOYEES = 'FETCH_NEXT_INSURANCE_EMPLOYEES',
  INSURANCE_EMPLOYEES_FETCHING = 'INSURANCE_EMPLOYEES_FETCHING',
}

const initialState: IInsuranceEmployees = {
  hasError: false,
  data: {
    total: 0,
    items: [],
  },
  fetchedFor: {
    search: null,
    includes: null,
    limit: 10,
    offset: 0,
    insuranceId: null,
    organizationId: null,
  },
  isNeverFetched: true,
  errorData: null,
  isFetching: false,
  fetchingFresh: false,
  fetchingSilently: false,
};

type Actions =
  | IInsuranceEmployeesFetchSucceeded
  | IInsuranceEmployeesFetchFailed
  | IInsuranceEmployeesFetching;

export function insuranceEmployees(state = initialState, action: Actions): IInsuranceEmployees {
  switch (action.type) {
    case ActionTypes.INSURANCE_EMPLOYEES_FETCHING:
      const fetchingSilently =
        state.isFetching === false && shallowCompareObjects(state.fetchedFor, action.payload);
      return {
        ...state,
        fetchedFor: action.payload,
        isFetching: true,
        isNeverFetched: false,
        fetchingFresh: action.payload.fetchFresh === false ? false : true,
        fetchingSilently,
      };
    case ActionTypes.INSURANCE_EMPLOYEES_FETCH_SUCCEEDED:
      if (!action.payload.isFresh) {
        return {
          ...state,
          data: {
            total: action.payload.fetchedData.total,
            items: [...state.data.items, ...action.payload.fetchedData.items],
          },
          hasError: false,
          errorData: null,
          isFetching: false,
          isNeverFetched: false,
          fetchingSilently: false,
        };
      } else {
        return {
          ...state,
          data: {
            total: action.payload.fetchedData.total,
            items: action.payload.fetchedData.items,
          },
          hasError: false,
          errorData: null,
          isFetching: false,
          isNeverFetched: false,
          fetchingSilently: false,
        };
      }
    case ActionTypes.INSURANCE_EMPLOYEES_FETCH_FAILED:
      return {
        ...state,
        hasError: true,
        errorData: action.payload,
        isFetching: false,
        isNeverFetched: false,
        fetchingSilently: false,
      };
    default:
      return state;
  }
}

export interface IInsuranceEmployeesFetchSucceeded {
  type: typeof ActionTypes.INSURANCE_EMPLOYEES_FETCH_SUCCEEDED;
  payload: {
    isFresh: boolean;
    fetchedData: GetOrgEmployeesForInsuranceSchemaContract;
  };
}

export const insuranceEmployeesFetchSucceeded = (
  fetchedData: GetOrgEmployeesForInsuranceSchemaContract,
  isFresh: boolean,
): IInsuranceEmployeesFetchSucceeded => ({
  type: ActionTypes.INSURANCE_EMPLOYEES_FETCH_SUCCEEDED,
  payload: {
    isFresh,
    fetchedData,
  },
});

export interface IInsuranceEmployeesFetchFailed {
  type: typeof ActionTypes.INSURANCE_EMPLOYEES_FETCH_FAILED;
  payload: IAppErrorDetails;
}

export const insuranceEmployeesFetchFailed = (
  payload: IAppErrorDetails,
): IInsuranceEmployeesFetchFailed => ({
  type: ActionTypes.INSURANCE_EMPLOYEES_FETCH_FAILED,
  payload,
});

/** Mark insurance in fetching state */

export interface IFetchInsuranceEmployees {
  type: typeof ActionTypes.FETCH_INSURANCE_EMPLOYEES;
  payload: InsuranceEmployeesFetchOptions;
}

export const fetchInsuranceEmployees = (
  fetchOptions: InsuranceEmployeesFetchOptions,
): IFetchInsuranceEmployees => ({
  type: ActionTypes.FETCH_INSURANCE_EMPLOYEES,
  payload: fetchOptions,
});

export interface IFetchNextInsuranceEmployees {
  type: typeof ActionTypes.FETCH_NEXT_INSURANCE_EMPLOYEES;
}

export const fetchNextInsuranceEmployees = (): IFetchNextInsuranceEmployees => ({
  type: ActionTypes.FETCH_NEXT_INSURANCE_EMPLOYEES,
});

export interface IInsuranceEmployeesFetching {
  type: typeof ActionTypes.INSURANCE_EMPLOYEES_FETCHING;
  payload: InsuranceEmployeesFetchOptions;
}

export const insuranceEmployeesFetching = (
  fetchOptions: InsuranceEmployeesFetchOptions,
): IInsuranceEmployeesFetching => ({
  type: ActionTypes.INSURANCE_EMPLOYEES_FETCHING,
  payload: fetchOptions,
});
