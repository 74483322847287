
import SuccessResponseSchema, { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import { getApi, postApi } from 'utils/Api';

export interface MappingData {
  paymentType: string;
  bankName: string;
  isActive: boolean;
  priority: number;
}

export interface UpdatePaymentBankMappingData {
  payment: string;
  bank: string;
  isActive: boolean;
  priority: number;
}

export interface UpdatePaymentBankMappingAPIData {
  data: UpdatePaymentBankMappingData[];
} 

export interface UpdateProcessingCountAPIData {
  processing_count: number;
} 

export interface PaymentBankMappingData {
  payment_mapping: MappingData[]; 
  banks: {
    bank: string;
    name: string;
  }[];
  payments: string[];
  processing_count: number;
  pending_task_count: {
    [key: string]: number
  }
}

export default {
  getPaymentBankMapping: () => {
    return getApi<PaymentBankMappingData>({
      url: `/admin/payment-bank-mapping`,
      responseSchema: {},
    });
  },

  updatePaymentBankMapping: (updateData: UpdatePaymentBankMappingData[]) => {
    return postApi<
      SuccessResponseSchemaContract,
      UpdatePaymentBankMappingAPIData
    >({
      url: '/admin/update-payment-bank-mapping',
      responseSchema: SuccessResponseSchema,
      requestData: {
        data: updateData
      },
    });
  },

  updateProcessingCount: (count: number) => {
    return postApi<
      SuccessResponseSchemaContract,
      UpdateProcessingCountAPIData
    >({
      url: '/admin/update-compliance-processing-count',
      responseSchema: SuccessResponseSchema,
      requestData: {
        processing_count: count
      },
    });
  },
};
