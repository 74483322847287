import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type SidebarApiSchemaContract = {
  isEnabled: boolean;
  isPro: boolean;
  url: string;
  name: string;
  isAdmin: boolean;
  shouldOpenInNewTab: boolean;
  key: string;
  imageSvg: string;
  imageSvgOnHover: string;
  isReactEnabled: boolean;
  childElements?:
    | {
        isEnabled: boolean;
        isPro: boolean;
        url: string;
        name: string;
        isAdmin: boolean;
        shouldOpenInNewTab: boolean;
        key: string;
        imageSvg: string;
        imageSvgOnHover: string;
        isReactEnabled: boolean;
        [k: string]: unknown;
      }[]
    | null;
  [k: string]: unknown;
}[];

export default <JSONSchema4>{
  type: 'array',
  items: {
    type: 'object',
    properties: {
      isEnabled: { type: 'boolean' },
      isPro: { type: 'boolean' },
      url: { type: 'string' },
      name: { type: 'string' },
      isAdmin: { type: 'boolean' },
      shouldOpenInNewTab: { type: 'boolean' },
      key: { type: 'string' },
      imageSvg: { type: 'string' },
      imageSvgOnHover: { type: 'string' },
      isReactEnabled: { type: 'boolean' },
      childElements: {
        type: ['array', 'null'],
        items: {
          type: 'object',
          properties: {
            isEnabled: { type: 'boolean' },
            isPro: { type: 'boolean' },
            url: { type: 'string' },
            name: { type: 'string' },
            isAdmin: { type: 'boolean' },
            shouldOpenInNewTab: { type: 'boolean' },
            key: { type: 'string' },
            imageSvg: { type: 'string' },
            imageSvgOnHover: { type: 'string' },
            isReactEnabled: { type: 'boolean' },
          },
          required: [
            'isEnabled',
            'isPro',
            'url',
            'name',
            'isAdmin',
            'shouldOpenInNewTab',
            'key',
            'imageSvg',
            'imageSvgOnHover',
            'isReactEnabled',
          ],
        },
      },
    },
    required: [
      'isEnabled',
      'isPro',
      'url',
      'name',
      'isAdmin',
      'shouldOpenInNewTab',
      'key',
      'imageSvg',
      'imageSvgOnHover',
      'isReactEnabled',
    ],
  },
};
