import React from 'react';

function Overlay({ closeModal }: { closeModal: Function }) {
  return (
    <div
      className="modal-overlay absolute w-auto h-auto bg-modal-background"
      onClick={() => closeModal()}
    ></div>
  );
}

function GlobalModalCloseOption({ closeModal }: { closeModal: Function }) {
  return (
    <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
      <svg
        className="fill-current text-white"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
      </svg>
      <span className="text-sm" onClick={() => closeModal()}>
        (Esc)
      </span>
    </div>
  );
}

function ModalHeading({
  heading = '',
  closeModal,
  disableClose
}: {
  heading: string;
  closeModal: Function;
  disableClose?: boolean;
}) {
  return (
    <div className="flex justify-between items-center pb-3">
      <p className="text-5xl font-bold font-heading">{heading}</p>
      {!disableClose && 
        <div className="modal-close cursor-pointer z-50" onClick={() => closeModal()}>
          <svg
            className="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      }
    </div>
  );
}

function Footer({ footerChildren }: { footerChildren?: React.ReactNode }) {
  return <div className="flex justify-end pt-2">{footerChildren}</div>;
}

function Container({
  heading = '',
  footerChildren,
  contentChildren,
  closeModal,
  className,
  contentClass,
  disableClose
}: {
  className?: string;
  contentClass?: string;
  heading?: string;
  footerChildren?: React.ReactNode;
  contentChildren: React.ReactNode;
  closeModal: Function;
  disableClose?: boolean;
}) {
  return (
    <div
      className={`h-full modal-container bg-modal-background w-11/12 md:max-w-4xl mx-auto rounded shadow-2xl z-50 overflow-y-auto ${
        className ? className : ''
      }`}
    >
      <div className={`modal-content py-4 text-left px-6 ${contentClass ? contentClass : ''}`}>
        <ModalHeading heading={heading} closeModal={closeModal} disableClose={disableClose}/>
        {contentChildren}
        <Footer footerChildren={footerChildren} />
      </div>
    </div>
  );
}

export function Modal({
  showModal,
  heading = '',
  footerChildren,
  children,
  closeModal,
  className,
  contentClass,
  disableClose,
  parentClass
}: {
  className?: string;
  contentClass?: string;
  showModal: boolean;
  heading?: string;
  footerChildren?: React.ReactNode;
  children: React.ReactNode;
  closeModal: Function;
  disableClose?: boolean;
  parentClass?: string
}) {
  if (!showModal) {
    return <></>;
  }

  return (
    <div
      className={`modal fixed w-auto h-screen py-8 top-0 left-0 right-0 bottom-0 z-[100] flex items-center justify-center bg-modal-blur-background ${  parentClass ?   parentClass : ""}`}
    >
      <Overlay closeModal={closeModal} />
      <Container
        heading={heading}
        contentChildren={children}
        contentClass={contentClass}
        footerChildren={footerChildren}
        closeModal={closeModal}
        className={className}
        disableClose={disableClose}
      />
    </div>
  );
}
