
import { getApi, putApi, patchApi, postApi } from 'utils/Api';
import SuccessResponseSchema, { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import NpsSalaryAndTaxEstimateSchema, { NpsSalaryAndTaxEstimateSchemaContract } from 'schemas/NpsSalaryAndTaxEstimateSchema';
import { GetEmployeeNpsDeclarationResponse, GetOrgNpsSettingsResponse, UpdateOrgNpsSettingsPayload } from 'components/Settings/NPS/types';

const apis = {
    getOrgNpsConfig: () => {
        return getApi<GetOrgNpsSettingsResponse>({
            url: `/organization/nps-config`,
            responseSchema: {},
        });
    },

    updateOrgNpsSettings: (settings: Partial<UpdateOrgNpsSettingsPayload>) => {
        return patchApi<SuccessResponseSchemaContract, Partial<UpdateOrgNpsSettingsPayload>>({
            url: `/organization/nps-config`,
            requestData: settings,
            responseSchema: {},
        });
    },

    getEmployeeNpsDeclarationConfig: (userOrg: number) => {
        return getApi<GetEmployeeNpsDeclarationResponse>({
            url: `/user-org/${userOrg}/nps-details`,
            responseSchema: {},
        });
    },

    updateEmployeeNpsDeclaration: (userOrg: number, declarationData: Partial<GetEmployeeNpsDeclarationResponse>) => {
        return patchApi<SuccessResponseSchemaContract, Partial<GetEmployeeNpsDeclarationResponse>>({
            url: `/user-org/${userOrg}/nps-details`,
            requestData: declarationData,
            responseSchema: SuccessResponseSchema,
        });
    },

    getTaxAndSalaryEstimates: (requestData: { userOrg: number,  npsEmployeeDeclarationPercentage: number | null} ) => {
        return postApi<NpsSalaryAndTaxEstimateSchemaContract,{ npsEmployeeDeclarationPercentage : number | null }>({
            url: `/user-org/${requestData.userOrg}/nps-estimates`,
            requestData: {
                npsEmployeeDeclarationPercentage: requestData.npsEmployeeDeclarationPercentage
            },
            responseSchema: NpsSalaryAndTaxEstimateSchema,
        });
    },

}

export default apis;