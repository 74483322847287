import { call } from 'redux-saga/effects';
import { IGetCallApiParams, INonGetCallApiParams } from '../interfaces/IApi';
import { TSagaApiCallbackWithSuccess } from '../types';
import * as Api from './Api';

function getApi<SuccessResponseContract>(params: IGetCallApiParams) {
  let callback: TSagaApiCallbackWithSuccess<SuccessResponseContract> = Api.getApi;
  return call(callback, params);
}

function putApi<SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
) {
  let callback: TSagaApiCallbackWithSuccess<SuccessResponseContract> = Api.putApi;
  return call(callback, params);
}

function patchApi<SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
) {
  let callback: TSagaApiCallbackWithSuccess<SuccessResponseContract> = Api.patchApi;
  return call(callback, params);
}

function postApi<SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
) {
  let callback: TSagaApiCallbackWithSuccess<SuccessResponseContract> = Api.postApi;
  return call(callback, params);
}

function delApi<SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
) {
  let callback: TSagaApiCallbackWithSuccess<SuccessResponseContract> = Api.deleteApi;
  return call(callback, params);
}

export default { getApi, putApi, postApi, delApi, patchApi };
