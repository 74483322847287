import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetOrgEmployeeForInsuranceSchemaContract {
  id: number;
  peopleId: number;
  gender: 'm' | 'f' | 'o' | null;
  dob: string | null;
  hireDate: string | null;
  phoneNumber: string | null;
  employeeId: string | null;
  isSyncedWithVendor: boolean;
  detailsConfirmedAt: string | null;
  lastInvitationSentAt: string | null;
  vendorDetails: {
    memberId?: string | null;
    [k: string]: unknown;
  } | null;
  startDate: string | null;
  endDate: string | null;
  status:
    | 'PURCHASE_PENDING'
    | 'NOT_ELIGIBLE'
    | 'HAS_MISSING_DATA'
    | 'PURCHASE_IN_PROGRESS'
    | 'PURCHASED'
    | 'HAS_NO_INSURANCE'
    | 'DETAILS_DO_NOT_SATISFY'
    | 'SKIPPED';
  vendorCurrentInsuranceDetails: {
    healthId?: string;
    [k: string]: unknown;
  } | null;
  dependents: {
    id: number;
    name: string | null;
    relation: 'father' | 'mother' | 'daughter' | 'son' | 'spouse' | 'fatherInLaw' | 'motherInLaw';
    dateOfBirth: string;
    gender: 'male' | 'female';
    isEligible: boolean;
    isPurchasePending: boolean;
    startDate?: string | null;
    endDate?: string | null;
    isCurrentlyCovered?: boolean;
    [k: string]: unknown;
  }[];
  planDetails: {
    insuranceId?: number;
    planId?: number;
    sumInsured?: number;
    withChildrenAndSpouse?: boolean;
    withParentsOrInLaws?: boolean;
    [k: string]: unknown;
  } | null;
  insuranceId: number | null;
  name: string | null;
  email: string;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  description: '',
  type: 'object',
  properties: {
    id: { type: 'number' },
    peopleId: { type: 'number' },
    gender: { type: ['null', 'string'], enum: ['m', 'f', 'o', null] },
    dob: { type: ['string', 'null'] },
    hireDate: { type: ['string', 'null'] },
    phoneNumber: { type: ['string', 'null'] },
    employeeId: { type: ['string', 'null'] },
    isSyncedWithVendor: { type: ['boolean'] },
    detailsConfirmedAt: { type: ['string', 'null'] },
    lastInvitationSentAt: { type: ['string', 'null'] },
    vendorDetails: {
      type: ['object', 'null'],
      required: [],
      properties: { memberId: { type: ['string', 'null'], minLength: 1 } },
    },
    startDate: { type: ['string', 'null'] },
    endDate: { type: ['string', 'null'] },
    status: {
      type: 'string',
      minLength: 1,
      enum: [
        'PURCHASE_PENDING',
        'NOT_ELIGIBLE',
        'HAS_MISSING_DATA',
        'PURCHASE_IN_PROGRESS',
        'PURCHASED',
        'HAS_NO_INSURANCE',
        'DETAILS_DO_NOT_SATISFY',
        'SKIPPED',
      ],
    },
    vendorCurrentInsuranceDetails: {
      type: ['object', 'null'],
      properties: { healthId: { type: 'string', minLength: 1 } },
    },
    dependents: {
      type: 'array',
      uniqueItems: true,
      minItems: 0,
      items: {
        type: 'object',
        properties: {
          id: { type: 'number' },
          name: { type: ['string', 'null'] },
          relation: {
            type: 'string',
            minLength: 1,
            enum: ['father', 'mother', 'daughter', 'son', 'spouse', 'fatherInLaw', 'motherInLaw'],
          },
          dateOfBirth: { type: 'string' },
          gender: { type: 'string', enum: ['male', 'female'] },
          isEligible: { type: 'boolean' },
          isPurchasePending: { type: 'boolean' },
          startDate: { type: ['string', 'null'] },
          endDate: { type: ['string', 'null'] },
          isCurrentlyCovered: { type: 'boolean' },
        },
        required: [
          'id',
          'name',
          'relation',
          'dateOfBirth',
          'gender',
          'isEligible',
          'isPurchasePending',
        ],
      },
    },
    planDetails: {
      type: ['object', 'null'],
      properties: {
        insuranceId: { type: 'number' },
        planId: { type: 'number' },
        sumInsured: { type: 'number' },
        withChildrenAndSpouse: { type: 'boolean' },
        withParentsOrInLaws: { type: 'boolean' },
      },
    },
    insuranceId: { type: ['number', 'null'] },
    name: { type: ['string', 'null'] },
    email: { type: 'string' },
  },
  required: [
    'id',
    'peopleId',
    'dob',
    'hireDate',
    'gender',
    'employeeId',
    'phoneNumber',
    'detailsConfirmedAt',
    'lastInvitationSentAt',
    'vendorDetails',
    'isSyncedWithVendor',
    'startDate',
    'endDate',
    'status',
    'vendorCurrentInsuranceDetails',
    'planDetails',
    'dependents',
    'insuranceId',
    'name',
    'email',
  ],
};
