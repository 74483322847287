import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import { getApi, postApi } from 'utils/Api';

export interface GetApiAccessDetailsResponse {
  apiKey: string;
  apiPermissions: {
    [key: string]: {
      label: string;
      disabled: boolean;
      value: boolean;
    };
  };
}

export type TwoFaOtp = {
  two_factor_auth_otp: string;
};

export type UpdateApiAccessDetailsRequestPayload = {
  permissions: {
    [key: string]: boolean;
  };
};

export type UpdateApiAccessSetPermissionsPayload = {
  [x: string]: string;
};

const apis = {
  getApiAccessDetails: () => {
    return getApi<GetApiAccessDetailsResponse>({
      url: '/api-access',
      responseSchema: {},
    });
  },
  setKey: () => {
    return postApi<SuccessResponseSchemaContract, unknown>({
      url: '/api-access/reset-api-key',
      responseSchema: SuccessResponseSchema,
      requestData: {},
    });
  },
  setKeyWithOtp: (requestData: TwoFaOtp) => {
    return postApi<SuccessResponseSchemaContract, unknown>({
      url: '/api-access/reset-api-key-with-otp',
      responseSchema: SuccessResponseSchema,
      requestData: requestData,
    });
  },
  disableKey: () => {
    return postApi<SuccessResponseSchemaContract, unknown>({
      url: '/api-access/disable-api-key',
      responseSchema: SuccessResponseSchema,
      requestData: {},
    });
  },
  disableKeyWithOtp: (requestData: TwoFaOtp) => {
    return postApi<SuccessResponseSchemaContract, unknown>({
      url: '/api-access/disable-api-key-with-otp',
      responseSchema: SuccessResponseSchema,
      requestData: requestData,
    });
  },
  updatePermissions: (requestData: UpdateApiAccessSetPermissionsPayload) => {
    return postApi<SuccessResponseSchemaContract, UpdateApiAccessSetPermissionsPayload>({
      url: '/api-access/set-api-permissions',
      responseSchema: SuccessResponseSchema,
      requestData: requestData,
    });
  },
  updatePermissionsWithOtp: (requestData: UpdateApiAccessSetPermissionsPayload) => {
    return postApi<SuccessResponseSchemaContract, UpdateApiAccessSetPermissionsPayload>({
      url: '/api-access/set-api-permissions-with-otp',
      responseSchema: SuccessResponseSchema,
      requestData: requestData,
    });
  },
};
export default apis;
