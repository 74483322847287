import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface BulkDownloadResponseSchemaContract {
  type: string;
  organization_id: number;
  people_id: number;
  filename: string;
  updated_at: string;
  created_at: string;
  id: number;
  hasFiles: boolean;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    type: { type: 'string' },
    organization_id: { type: 'number' },
    people_id: { type: 'number' },
    filename: { type: 'string' },
    updated_at: { type: ['string'], format: 'date-time' },
    created_at: { type: ['string'], format: 'date-time' },
    id: { type: 'number' },
    hasFiles: { type: 'boolean' },
  },
  required: [
    'type',
    'organization_id',
    'people_id',
    'filename',
    'updated_at',
    'created_at',
    'id',
    'hasFiles',
  ],
};
