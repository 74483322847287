import { postApi, getApi } from 'utils/Api';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import FreshTeamsSettingsSchema, { FreshTeamsSettingsContract } from 'schemas/FreshTeamsSettings';

type ActivationRequest = {
  domain: string;
  key: string;
};

type DeactivationRequest = {};

type SyncRequest = {};

export default {
  fetchActivationStatus: () => {
    return getApi<FreshTeamsSettingsContract>({
      url: '/freshteam-settings/show',
      responseSchema: FreshTeamsSettingsSchema,
    });
  },
  disable: () => {
    return postApi<SuccessResponseSchemaContract, DeactivationRequest>({
      url: '/freshteam-settings/disable',
      responseSchema: SuccessResponseSchema,
      requestData: {},
    });
  },
  activate: (domain: string, key: string) => {
    return postApi<SuccessResponseSchemaContract, ActivationRequest>({
      url: '/freshteam-settings/activate',
      responseSchema: SuccessResponseSchema,
      requestData: {
        domain,
        key,
      },
    });
  },
  sync: () => {
    return postApi<SuccessResponseSchemaContract, SyncRequest>({
      url: '/freshteam-settings/sync',
      responseSchema: SuccessResponseSchema,
      requestData: {},
    });
  },
};
