import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface NpsSalaryAndTaxEstimateSchemaContract {
  estimatedTaxSavings: number | null;
  monthlyInHand: number | null;
  taxableIncome: number | null;
  estimatedTotalTax: number | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    estimatedTaxSavings: { type: ['number', 'null'] },
    monthlyInHand: { type: ['number', 'null'] },
    taxableIncome: { type: ['number', 'null'] },
    estimatedTotalTax: { type: ['number', 'null'] },
  },
  required: ['estimatedTaxSavings', 'monthlyInHand', 'taxableIncome', 'estimatedTotalTax'],
};
