import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetWorkflowRequestDetailsContract {
  workflow_requests_data: {
    id: number;
    people_id: number | null;
    organization_id: number;
    type: number;
    old_data: unknown;
    new_data: unknown;
    created_at: number;
    status: number;
    updated_at: number;
    updated_by: number | null;
    deleted_at: number | null;
    pending_on_level: number | null;
    source: unknown;
    for_people_id: number | null;
    payload?: unknown;
    [k: string]: unknown;
  };
  workflow_data: {
    id: number;
    organization_id: number;
    action_type: number;
    approvers: {
      levels: {
        level: number;
        levelId: string;
        peopleIds: number[];
        minApprovals: number;
        [k: string]: unknown;
      }[];
      [k: string]: unknown;
    };
    status: number;
    version: number;
    created_by: number;
    deleted_by: number | null;
    created_at: number;
    updated_at: number;
    deleted_at: number | null;
    [k: string]: unknown;
  };
  activity_log: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      id: number;
      organization_id: number;
      changes_staging_id: number;
      workflow_id: number;
      level: number;
      activity: string;
      by_people_id: number;
      updated_at: number;
      remarks?: string | null;
      [k: string]: unknown;
    }[];
  };
  people_metadata: {
    /**
     * This interface was referenced by `undefined`'s JSON-Schema definition
     * via the `patternProperty` ".*".
     */
    [k: string]: {
      people_id: number | null;
      employee_type: string;
      employee_id: string | null;
      name: string;
      id_prefix: string | null;
      [k: string]: unknown;
    };
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    workflow_requests_data: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        people_id: { type: ['number', 'null'] },
        organization_id: { type: 'number' },
        type: { type: 'number' },
        old_data: {},
        new_data: {},
        created_at: { type: 'number' },
        status: { type: 'number' },
        updated_at: { type: 'number' },
        updated_by: { type: ['number', 'null'] },
        deleted_at: { type: ['number', 'null'] },
        pending_on_level: { type: ['number', 'null'] },
        source: {},
        for_people_id: { type: ['number', 'null'] },
        payload: {},
      },
      required: [
        'id',
        'people_id',
        'organization_id',
        'type',
        'old_data',
        'new_data',
        'created_at',
        'status',
        'updated_at',
        'updated_by',
        'deleted_at',
        'pending_on_level',
        'source',
        'for_people_id',
      ],
    },
    workflow_data: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        organization_id: { type: 'number' },
        action_type: { type: 'number' },
        approvers: {
          type: 'object',
          properties: {
            levels: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  level: { type: 'number' },
                  levelId: { type: 'string' },
                  peopleIds: { type: 'array', items: { type: 'number' } },
                  minApprovals: { type: 'number' },
                },
                required: ['level', 'levelId', 'peopleIds', 'minApprovals'],
              },
            },
          },
          required: ['levels'],
        },
        status: { type: 'number' },
        version: { type: 'number' },
        created_by: { type: 'number' },
        deleted_by: { type: ['number', 'null'] },
        created_at: { type: 'number' },
        updated_at: { type: 'number' },
        deleted_at: { type: ['number', 'null'] },
      },
      required: [
        'id',
        'organization_id',
        'action_type',
        'approvers',
        'status',
        'version',
        'created_by',
        'deleted_by',
        'created_at',
        'updated_at',
        'deleted_at',
      ],
    },
    activity_log: {
      type: 'object',
      patternProperties: {
        '.*': {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: { type: 'number' },
              organization_id: { type: 'number' },
              changes_staging_id: { type: 'number' },
              workflow_id: { type: 'number' },
              level: { type: 'number' },
              activity: { type: 'string' },
              by_people_id: { type: 'number' },
              updated_at: { type: 'number' },
              remarks: { type: ['string', 'null'] },
            },
            required: [
              'id',
              'organization_id',
              'changes_staging_id',
              'workflow_id',
              'level',
              'activity',
              'by_people_id',
              'updated_at',
            ],
          },
        },
      },
    },
    people_metadata: {
      type: 'object',
      patternProperties: {
        '.*': {
          type: 'object',
          properties: {
            people_id: { type: ['number', 'null'] },
            employee_type: { type: 'string' },
            employee_id: { type: ['string', 'null'] },
            name: { type: 'string' },
            id_prefix: { type: ['string', 'null'] },
          },
          required: ['people_id', 'employee_type', 'employee_id', 'name', 'id_prefix'],
        },
      },
    },
  },
  required: ['workflow_requests_data', 'workflow_data', 'activity_log', 'people_metadata'],
};
