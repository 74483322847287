import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetNetpayOnHoldListItemSchemaContract {
  employee_id: string;
  employee_name: string | null;
  employee_email: string | null;
  net_pay_amount?: number;
  net_pay_breakup: {
    amount: number;
    month: string | null;
    status?: number | null;
    [k: string]: unknown;
  }[];
  fnf_payroll_processed: boolean | null;
  fnf_payroll_released?: boolean | null;
  settlement_amount: number | null;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    employee_id: { type: 'string' },
    employee_name: { type: ['string', 'null'] },
    employee_email: { type: ['string', 'null'] },
    net_pay_amount: { type: 'number' },
    net_pay_breakup: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          amount: { type: 'number' },
          month: { type: ['string', 'null'] },
          status: { type: ['number', 'null'] },
        },
        required: ['amount', 'month'],
      },
    },
    fnf_payroll_processed: { type: ['boolean', 'null'] },
    fnf_payroll_released: { type: ['boolean', 'null'] },
    settlement_amount: { type: ['number', 'null'] },
  },
  required: [
    'employee_id',
    'employee_name',
    'employee_email',
    'net_pay_breakup',
    'net_pay_breakup',
    'fnf_payroll_processed',
    'settlement_amount',
  ],
};
