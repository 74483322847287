import { getUrlWithoutQuery } from 'utils/Urls';
import { getEventQueue, clearQueue, addEventToQueue } from 'utils/EventCache';
import queueName from 'constants/eventCaches';
import api from 'api';


export const initSegment = () => {
    var analytics = (window.analytics = window.analytics || getEventQueue(queueName.SEGMENT_EVENT_QUEUE));

    var currentQueue = getEventQueue(queueName.SEGMENT_EVENT_QUEUE);
    currentQueue.forEach(function(event) {
        if (event[0] === 'track') {
            addEventToAnalyticsDB({
                eventName: event[1],
                eventType: 'interaction',
                source: 'segment'
            });
        }
    });
    clearQueue(queueName.SEGMENT_EVENT_QUEUE);
    if (!analytics.initialize) {
        if (analytics.invoked) {
            window.console && console.error && console.error('Segment snippet included twice.');
        } else {
            analytics.invoked = !0;
            analytics.methods = ['identify', 'track', 'page', 'addSourceMiddleware'];
            analytics.factory = function(e) {
                return function() {
                    var t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                };
            };
            for (var e = 0; e < analytics.methods.length; e++) {
                var key = analytics.methods[e];
                analytics[key] = analytics.factory(key);
            }
            analytics.load = function(key, e) {
                var t = document.createElement('script');
                t.type = 'text/javascript';
                t.async = !0;
                t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                var n = document.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(t, n);
                analytics._loadOptions = e;
            };
            analytics._writeKey = window.SEGMENT_WRITE_KEY;
            analytics.SNIPPET_VERSION = '4.15.3';
            analytics.load(window.SEGMENT_WRITE_KEY);
        }
    }
};

function addEventToAnalyticsDB({eventName, eventType, source, properties}) {
    api.analytics.push({
        eventName,
        eventType: eventType || 'interaction',
        source: source || 'segment',
        properties: properties || {}
    });
}

function getCleanReferrerAndUrl() {
    var referrer = getUrlWithoutQuery(document.referrer);
    var url = getUrlWithoutQuery(document.location.href);

    return { referrer, url };
}

export function trackSegment(event, options = {}) {
    const { referrer, url } = getCleanReferrerAndUrl();
    var payload = [
        event,
        {},
        {
            context: {
                page: { url: url, search: '', referrer: referrer },
            },
        },
    ];

    if (options.queue) {
        addEventToQueue(['track', ...payload], queueName.SEGMENT_EVENT_QUEUE);
    } else {
        window.analytics && window.analytics.track && window.analytics.track(...payload);
        addEventToAnalyticsDB({
            eventName: event,
            eventType: 'interaction',
            source: 'segment',
            ...options
        });
    }
}

export function trackSegmentPage({ category = null, page = null, referrer = '' } = {}) {
    //Page Tracking is currently disabled, view https://razorpay.slack.com/archives/C012V3GFF6J/p1652264688408389
    // const cleanedReferrer = getUrlWithoutQuery(referrer);
    // const { url } = getCleanReferrerAndUrl();
    // window.analytics &&
    //   window.analytics.page &&
    //   window.analytics.page(
    //     category,
    //     page,
    //     { url: url, search: '', referrer: cleanedReferrer },
    //     {
    //       context: {
    //         page: { url: url, search: '', referrer: cleanedReferrer },
    //       },
    //     },
    //   );
}

export function identifySegment({userId, orgId, email='', name='', phone='', employees='', title='', source='' }) {
    const { referrer, url } = getCleanReferrerAndUrl();
    window.analytics &&
        window.analytics.identify &&
        window.analytics.identify(
            orgId, {userId, orgId, email, name, phone, employees, title, source }, {
                context: {
                    page: { url: url, search: '', referrer: referrer },
                },
                properties: {},
            },
        );
}