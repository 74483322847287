import { TErrorType } from '../../types';
import { IAppErrorDetails } from '../../utils/AppError';

export interface IGenericErrorState {
  errors: IAppErrorDetails[];
}

export enum ActionTypes {
  SET_GENERIC_ERROR = 'SET_GENERIC_ERROR',
  RESET_GENERIC_ERROR = 'RESET_GENERIC_ERROR',
}

const initialState: IGenericErrorState = {
  errors: [],
};

export function genericError(
  state = initialState,
  action: ISetGenericErrorAction | IResetGenericErrorAction,
): IGenericErrorState {
  switch (action.type) {
    case ActionTypes.SET_GENERIC_ERROR:
      return { ...state, errors: [...state.errors, action.payload] };
    case ActionTypes.RESET_GENERIC_ERROR:
      return { ...state, errors: [] };
    default:
      return state;
  }
}

/** Set generic error */

export interface ISetGenericErrorAction {
  type: typeof ActionTypes.SET_GENERIC_ERROR;
  payload: IAppErrorDetails;
}

export const setGenericAppError = (payload: IAppErrorDetails): ISetGenericErrorAction => ({
  type: ActionTypes.SET_GENERIC_ERROR,
  payload,
});

/** Reset generic error */

export interface IResetGenericErrorAction {
  type: typeof ActionTypes.RESET_GENERIC_ERROR;
}

export const resetGenericAppError = (): IResetGenericErrorAction => ({
  type: ActionTypes.RESET_GENERIC_ERROR,
});
